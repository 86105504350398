import { APIService } from "../api/apiService";
import EncryptionCertificate from "../interfaces/EncryptionCertificate";

export class GenericService extends APIService {
  getPendingUpdateComputers() {
    return this.request("/Admin/ForceUpdate", "GET");
  }

  forceUpdatePendingComputers() {
    return this.request("/Admin/ForceUpdate", "POST");
  }

  /**
   * Retrieves encryption certificates by making a request to the specified
   * endpoint for encryption certificate management.
   *
   * @return {Promise<Object>} A promise that resolves to the response object containing the encryption certificates.
   */
  getEncryptionCertificates(): Promise<EncryptionCertificate[]> {
    return this.request("/Admin/EncryptionCertificate", "GET");
  }

  /**
   * Creates an encryption certificate by sending a POST request to the /Admin/EncryptionCertificate endpoint.
   *
   * @param {string} name - The name of the encryption certificate.
   * @return {Promise<any>} A promise that resolves to the response of the request.
   * @param name
   */
  createEncryptionCertificate(name: string): Promise<EncryptionCertificate> {
    return this.request("/Admin/EncryptionCertificate", "POST", { name });
  }
}
