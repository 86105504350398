import { SortDescriptor } from "@react-types/shared";
import qs from "qs";
import React, {
  Key,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDateFormatter } from "react-aria";
import { useTranslation } from "react-i18next";
import type { Selection } from "react-stately";
import { useOverlayTriggerState } from "react-stately";

import ComputerGroupDetail from "../components/computer/ComputerGroupDetail";
import { TableAction } from "../components/TableActionsBar";
import { useAuth } from "../contexts/AuthContext";
import { useDialogContext } from "../contexts/DialogContext";
import { useNavigationDirty } from "../contexts/NavigationDirtyContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { usePendingComputerUpdate } from "../contexts/PendingComputerUpdateContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import ComputerGroup from "../interfaces/ComputerGroup";
import { ComputerGroupService } from "../services/computerGroupService";
import { InfoService } from "../services/infoService";
import { transformObjectArraysToArrayOfIds } from "../utils/utils";

import { useNotifications } from "./useNotifications";

const computerGroupService = new ComputerGroupService();
const infoService = new InfoService();

interface FilterState {
  search?: string;
  count: number;
  offset: number;
  sort?: SortDescriptor;
}

function useComputerGroupsTab() {
  const {
    openRightPanel,
    isRightPanelOpen,
    setRightPanelContent,
    closeRightPanel,
  } = useContext(RightPanelContext) as RightPanelContextProps;
  const [computerGroups, setComputerGroups] = useState<ComputerGroup[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([]));
  const [selectedComputerGroup, setSelectedComputerGroup] = useState<
    ComputerGroup | undefined
  >(undefined); // Used for display the name of the item in the dialogs ( Only one )
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingAgentReleases, setIsLoadingAgentReleases] =
    useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<FilterState>({
    count: 15,
    offset: 0,
    search: undefined,
  });
  const [isAgentAutoUpdateEnabled, setIsAgentAutoUpdateEnabled] =
    useState<boolean>(false);
  const [activeComputerGroup, setActiveComputerGroup] = useState<Key | null>(
    null,
  );
  const [activeComputerGroupsForDeletion, setActiveComputerGroupsForDeletion] =
    useState<Key[]>([]);
  const [agentReleases, setAgentReleases] = useState<
    {
      isLatest: boolean;
      name: string;
    }[]
  >([]);
  const [newAgentVersionSelected, setNewAgentVersionSelected] = useState<
    string | undefined
  >(undefined);
  const dialog = useDialogContext();
  const { t } = useTranslation();
  const { confirmDirtyNavigation } = useNavigationDirty();
  const { createNotification } = useNotifications();
  const { resetPendingUpdatesTimeout } = usePendingComputerUpdate();
  const { isCurrentUserReadOnly } = useAuth();
  const deleteModalState = useOverlayTriggerState({});
  const computerGroupAgentUpdatesModalState = useOverlayTriggerState({});

  const dateFormatter = useDateFormatter({
    dateStyle: "medium",
    timeStyle: "short",
  });

  /**
   * Fetches computer groups from the server.
   * @function getComputerGroups
   * @async
   * @returns {void}
   */
  const getComputerGroups = useCallback(async () => {
    setIsLoading(true);
    const transformedFilters = transformObjectArraysToArrayOfIds(filters);
    if (transformedFilters.sort !== undefined) {
      transformedFilters.sortCol = filters.sort?.column;
      transformedFilters.sortDir = filters.sort?.direction;
      delete transformedFilters.sort;
    } else {
      transformedFilters.sortCol = "createdOn";
      transformedFilters.sortDir = "descending";
    }

    computerGroupService
      .getComputerGroups(qs.stringify(filters))
      .then((res) => {
        const filteredResponse = res.data.filter(
          (group: ComputerGroup) => group.id >= 0,
        );
        setComputerGroups(filteredResponse);
        setTotal(res.total);
        setIsAgentAutoUpdateEnabled(res.agentAutoUpdate);
      })
      .catch(() => {
        createNotification(
          t("errors.genericFetchError", {
            resource: t("common.computersGroups"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [createNotification, filters, t]);

  /**
   * Handles the opening of the right panel.
   * @async
   * @param {number} [computerGroupId] - The user ID.
   */
  const handleOpenRightPanel = async (computerGroupId?: number) => {
    const confirm = await confirmDirtyNavigation();
    if (!confirm) {
      return;
    }
    openRightPanel();
    setRightPanelContent(
      <ComputerGroupDetail
        key={Math.random()}
        id={computerGroupId}
        onClose={() => {
          closeRightPanel();
        }}
        onSaved={() => {
          getComputerGroups().then(closeRightPanel);
        }}
      />,
    );
    if (!computerGroupId) {
      setActiveComputerGroup(null);
    }
  };

  /**
   * Deletes a computer group with the specified ID.
   */
  const handleComputerGroupDelete = () => {
    setIsLoading(true);
    computerGroupService
      .deleteComputerGroup(
        qs.stringify({
          id: activeComputerGroupsForDeletion.includes("all")
            ? [-1000]
            : activeComputerGroupsForDeletion,
        }),
      )
      .then(() => {
        setSelectedKeys(new Set([]));
        closeRightPanel();
        getComputerGroups().then(() => {
          deleteModalState.close();
          resetPendingUpdatesTimeout();
        });
      })
      .catch(() => {
        createNotification(
          t("errors.genericDeleteError", {
            resource: t("common.computersGroups"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Sets the selected computer using a key.
   *
   * @param {Key} key - The key to search for the selected computer.
   * @returns {void}
   */
  const findComputerGroupByKey = (key: Key): ComputerGroup | undefined => {
    return computerGroups.find(
      (computerGroup) => computerGroup.id === Number(key),
    );
  };

  /**
   * Updates the agent version for a specified list of computer IDs.
   *
   * @function
   * @param {number[]} ids - An array of computer identifiers for which the agent version needs to be updated.
   * @param {string} version - The target version of the agent to be updated to.
   * @description
   * This function triggers a service call to update the agent version for the provided computer IDs.
   * It manages the loading state while the operation is in progress and handles the success and error cases accordingly.
   *
   * When the update operation is successful, it retrieves the updated list of computers and resets the pending updates timeout.
   * If the operation fails, it creates a notification to inform the user about the error.
   *
   * The loading state is toggled off after the operation is complete, regardless of its success or failure.
   */
  const handeUpdateAgentVersion = (ids: number[], version: string) => {
    setIsLoadingAgentReleases(true);
    computerGroupService
      .updateAgentVersion(ids, version)
      .then(() => {
        computerGroupAgentUpdatesModalState.close();
        setSelectedKeys(new Set([]));
        getComputerGroups().then(() => {
          resetPendingUpdatesTimeout();
        });
      })
      .catch(() => {
        createNotification(
          t("errors.genericUpdateError", {
            resource: t("common.version"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoadingAgentReleases(false);
      });
  };

  /**
   * Function to fetch and update the list of agent versions/releases.
   * Utilizes a callback to handle the asynchronous retrieval of agent release data
   * from an external service and updates the application state accordingly.
   *
   * Dependencies:
   * - Fetches data using `infoService.getAgentReleases()`.
   * - Updates the agent versions with `setAgentReleases`.
   * - Handles loading state with `setIsLoadingAgentReleases`.
   * - Creates error notifications using `createNotification`.
   * - Utilizes translation function `t` for localized messaging.
   *
   * Logic:
   * - Marks the loading state as active.
   * - Fetches both the latest and previous agent releases.
   * - Prepares the list of releases where the latest release is flagged as `isLatest: true`.
   * - Updates the state with the constructed release list.
   * - On failure, triggers a generic error notification.
   * - Finally, marks the loading state as inactive.
   *
   * Dependency array: [createNotification, t]
   */
  const getAgentVersions = useCallback(() => {
    setIsLoadingAgentReleases(true);
    infoService
      .getAgentReleases()
      .then((res) => {
        const releases = res.previous.map((release) => ({
          isLatest: false,
          name: release,
        }));
        releases.unshift({ isLatest: true, name: res.latest });
        setAgentReleases(releases);
        setNewAgentVersionSelected(res.latest);
      })
      .catch(() => {
        createNotification(
          t("errors.genericFetchError", {
            resource: t("common.agentUpdate"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoadingAgentReleases(false);
      });
  }, [createNotification, t]);

  /**
   * A memoized variable that represents the first computer group selected when exactly one computer group is selected.
   * If multiple groups are selected or "all" is selected, the variable will be undefined.
   *
   * The value is derived based on the currently selected keys and the list of available computer groups.
   * It matches the first computer group whose `id` corresponds to the single selected key.
   *
   * Dependencies:
   * - `selectedKeys`: Contains the selected computer group IDs or the value "all".
   * - `computerGroups`: An array of available computer groups with their details.
   */
  const firstMultipleSelectedComputerGroup = useMemo(() => {
    if (selectedKeys !== "all" && selectedKeys.size === 1) {
      return computerGroups.find(
        (computerGroup) =>
          computerGroup.id === Number(selectedKeys.values().next().value),
      );
    } else {
      return undefined;
    }
  }, [selectedKeys, computerGroups]);

  /**
   * Variable: tableActions
   * Description: Defines an array of table actions based on the selected keys.
   *
   * @type {TableAction[]}
   *
   * @return {TableAction[]} Array of table actions
   *
   * @param {Function} closeRightPanel - Function to close the right panel
   * @param {Object} dialog - Dialog object used for displaying messages
   * @param {Function} getComputerGroups - Function to fetch computer groups
   * @param {Function} openRightPanel - Function to open the right panel
   * @param {Set|String} selectedKeys - Set of selected keys or "all" to select all keys
   * @param {Function} setRightPanelContent - Function to set the content of the right panel
   * @param {Function} t - Translation function
   */
  const tableActions: TableAction[] = useMemo(() => {
    if (isCurrentUserReadOnly) {
      return [];
    }

    const baseAction: TableAction = {
      icon: "AddIcon",
      label: t("common.addComputerGroup"),
      onClick: () => {
        handleOpenRightPanel().then(() => {});
      },
    };

    const additionalActions: TableAction[] = [
      {
        icon: "DeleteIcon",
        label: t("common.delete"),
        onClick: () => {
          deleteModalState.open();
          setActiveComputerGroupsForDeletion(
            selectedKeys === "all" ? ["all"] : Array.from(selectedKeys),
          );
        },
      },
    ];

    if (!isAgentAutoUpdateEnabled) {
      additionalActions.push({
        icon: "ComputersMenuIcon",
        label: t("common.agentUpdate"),
        onClick: () => {
          setNewAgentVersionSelected(undefined);
          setSelectedComputerGroup(undefined);
          getAgentVersions();
          computerGroupAgentUpdatesModalState.open();
        },
      });
    }

    if (selectedKeys === "all" || selectedKeys.size > 0) {
      return additionalActions;
    } else {
      return [baseAction];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closeRightPanel,
    dialog,
    getComputerGroups,
    openRightPanel,
    selectedKeys,
    setRightPanelContent,
    t,
  ]);

  /**
   * Executes action based on the given key.
   *
   * @param {number} id - The id of the item.
   * @param {Key} key - The action key.
   */
  const onMenuAction = (id: number, key: Key) => {
    const foundComputerGroup = findComputerGroupByKey(id);
    setSelectedComputerGroup(foundComputerGroup);

    switch (key) {
      case "edit":
        handleOpenRightPanel(Number(id)).then(() => {});
        break;
      case "agentUpdate":
        computerGroupAgentUpdatesModalState.open();
        getAgentVersions();
        break;
      case "delete":
        deleteModalState.open();
        setActiveComputerGroupsForDeletion([id]);
        break;
      default:
        break;
    }
  };

  /**
   * Executes an action when a row is clicked.
   *
   * @param {Key} key - The key of the row being clicked.
   * @returns {void}
   */
  const onRowAction = (key: Key): void => {
    handleOpenRightPanel(Number(key)).then(() => {
      setActiveComputerGroup(key);
    });
  };

  /**
   * A memoized computation that determines the first computer selected for deletion
   * if only a single computer is selected from the list of active computers marked for deletion.
   *
   * The variable is computed using `useMemo` to optimize performance by recalculating
   * only when the `activeComputersForDeletion` or `computers` arrays change.
   * If exactly one computer is present in the `activeComputersForDeletion` array,
   * it attempts to find the corresponding computer in the `computers` array
   * by matching the `id` of the computer.
   * If no computers or multiple computers are selected for deletion, the value will be `undefined`.
   *
   * Dependencies:
   * - `activeComputersForDeletion`: An array representing the IDs of active computers selected for deletion.
   * - `computers`: An array of all available computer objects.
   */
  const firstMultipleSelectedComputerGroupForDeletion = useMemo(() => {
    if (activeComputerGroupsForDeletion.length === 1) {
      return computerGroups.find(
        (computerGroup) =>
          computerGroup.id === Number(activeComputerGroupsForDeletion[0]),
      );
    } else {
      return undefined;
    }
  }, [activeComputerGroupsForDeletion, computerGroups]);

  /**
   * A memoized boolean variable that determines whether multiple keys are selected.
   *
   * The value will evaluate to true if either all keys are selected
   * (represented by the string "all") or if the `selectedKeys` set contains one
   * or more entries. It updates only when the `selectedKeys` dependency changes.
   *
   * This is useful for tracking selection states in a component or system where
   * multiple selections can occur.
   *
   * Dependencies:
   * - selectedKeys: A value that represents the current selection of keys,
   *   either as the string "all" or a Set of key values.
   */
  const areMultipleKeysSelected = useMemo(() => {
    return selectedKeys === "all" || selectedKeys.size > 0;
  }, [selectedKeys]);

  useEffect(() => {
    getComputerGroups().then(() => {});
  }, [getComputerGroups]);

  useEffect(() => {
    if (!isRightPanelOpen) {
      setActiveComputerGroup(null);
    }
  }, [isRightPanelOpen]);

  useEffect(() => {
    if (selectedKeys instanceof Set && selectedKeys.size > 0) {
      closeRightPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys]);

  return {
    activeComputerGroup,
    activeComputerGroupsForDeletion,
    agentReleases,
    areMultipleKeysSelected,
    computerGroupAgentUpdatesModalState,
    computerGroups,
    dateFormatter,
    deleteModalState,
    filters,
    firstMultipleSelectedComputerGroup,
    firstMultipleSelectedComputerGroupForDeletion,
    handeUpdateAgentVersion,
    handleComputerGroupDelete,
    handleOpenRightPanel,
    isAgentAutoUpdateEnabled,
    isCurrentUserReadOnly,
    isLoading,
    isLoadingAgentReleases,
    newAgentVersionSelected,
    onMenuAction,
    onRowAction,
    selectedComputerGroup,
    selectedKeys,
    setFilters,
    setNewAgentVersionSelected,
    setSelectedKeys,
    t,
    tableActions,
    total,
  };
}

export default useComputerGroupsTab;
