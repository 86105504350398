import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Item, useOverlayTriggerState } from "react-stately";

import { useNavigationDirty } from "../../../contexts/NavigationDirtyContext";
import { NotificationTypes } from "../../../contexts/NotificationContext";
import { useNotifications } from "../../../hooks/useNotifications";
import useServerData from "../../../hooks/useServerData";
import EncryptionCertificate from "../../../interfaces/EncryptionCertificate";
import { GenericService } from "../../../services/genericService";
import { specialFolders } from "../../../utils/specialFolders";
import { normalizePath } from "../../../utils/utils";
import Button from "../../Button";
import Checkbox from "../../Checkbox";
import { ComboBox } from "../../ComboBox";
import { Icon } from "../../Icon";
import SecurityPolicyPermissionsFormLayout from "../../layouts/SecurityPolicyPermissionsFormLayout";
import { Modal } from "../../Modal";
import { MultiselectComboboxOption } from "../../MultiSelectComboBox";
import TextField from "../../TextField";
import { SecurityPolicyInputComboBoxStringTuple } from "../fields/SecurityPolicyInputComboBoxStringTuple";
import { SecurityPolicyInputMultiSelect } from "../fields/SecurityPolicyInputMultiselect";
import { SecurityPolicyInputStringList } from "../fields/SecurityPolicyInputStringList";
import { SecurityPolicyInputStringTuple } from "../fields/SecurityPolicyInputStringTuple";

const genericService = new GenericService();

interface Props {
  id?: number;
  onChange: (payload: { [key: string]: any }) => void;
  value: { [key: string]: any };
  isLoading: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  isInvalid: (isInvalid: boolean) => void;
}

interface FormState {
  isMonitorSpecificFolder: boolean;
  folders: { item1: string; item2: string }[];
  customFolders: string[];
  exceptFolders: { item1: string; item2: string }[];
  exceptCustomFolders: string[];
  exceptInclude: { item1: string; item2: string }[];
  exceptExclude: { item1: string; item2: string }[];
  customCertificates: { item1: string; item2: string }[];
  exceptFoldersWithEncryptSpecificFolders: { item1: string; item2: string }[];
  encryptionCertificate: string | null;
  extensions: MultiselectComboboxOption[];
  customExtensions: string[];
  certificates: MultiselectComboboxOption[];
  hashes: { item1: string; item2: string }[];
}

function SystemEncryptionPolicyPermissionsForm(props: Props) {
  const { settings } = useServerData();
  const { t } = useTranslation();
  const { createNotification } = useNotifications();
  const { setDirty } = useNavigationDirty();

  const [form, setForm] = useState<FormState>({
    certificates: [],
    customCertificates: [],
    customExtensions: [],
    customFolders: [],
    encryptionCertificate: null,
    exceptCustomFolders: [],
    exceptExclude: [],
    exceptFolders: [],
    exceptFoldersWithEncryptSpecificFolders: [],
    exceptInclude: [],
    extensions: [],
    folders: [],
    hashes: [],
    isMonitorSpecificFolder: false,
  });
  const [disabledFields, setDisabledFields] = useState<string[]>([]);
  const [
    exceptFoldersWithEncryptSpecificFoldersOptions,
    setExceptFoldersWithEncryptSpecificFoldersOptions,
  ] = useState<
    {
      id: string;
      value: string;
    }[]
  >([]);
  const [isLoadingEncryptionCertificates, setIsLoadingEncryptionCertificates] =
    useState(false);
  const [newEncryptionCertificateName, setNewEncryptionCertificateName] =
    useState("");
  const newEncryptionCertificateNameRef = React.useRef<HTMLInputElement>(null);
  const [encryptionCertificates, setEncryptionCertificates] = useState<
    EncryptionCertificate[]
  >([]);
  const encryptionCertificateModalState = useOverlayTriggerState({});

  /**
   * Handles the creation of a new encryption certificate.
   * This function verifies preconditions such as read-only state, disabled state,
   * loading status, and validity of the new encryption certificate name prior to execution.
   * If any of these conditions are met, the function will immediately return without performing any actions.
   *
   * When valid, it sets the loading state, invokes the service to create a new encryption certificate,
   * and manages subsequent processes including response logging, error handling,
   * fetching updated encryption certificate data, and closing the related modal state.
   *
   * Preconditions:
   * - `props.isReadOnly` should be false.
   * - `props.isDisabled` should be false.
   * - `isLoadingEncryptionCertificates` should be false.
   * - `newEncryptionCertificateName` should not be empty after trimming.
   *
   * Postconditions:
   * - Updates the state to reflect the loading status during operation.
   * - Invokes the service to create the certificate and fetch updated data on success.
   * - Logs errors if the service request fails.
   * - Ensures the modal state is closed and the loading state is cleared when the process is complete.
   */
  const handleCreateNewEncryptionCertificate = () => {
    if (
      props.isReadOnly ||
      props.isDisabled ||
      isLoadingEncryptionCertificates ||
      newEncryptionCertificateName.trim() === ""
    ) {
      return;
    }

    setIsLoadingEncryptionCertificates(true);

    genericService
      .createEncryptionCertificate(newEncryptionCertificateName)
      .then((response) => {
        // Push the new certificate to the list of certificates
        setEncryptionCertificates((prevState) => [...prevState, response]);

        // Set the new certificate as the selected certificate
        setForm((prevState) => ({
          ...prevState,
          encryptionCertificate: response.id.toString(),
        }));

        // Clear the new certificate name
        setNewEncryptionCertificateName("");
      })
      .catch((error) => {
        if (error?.response?.errorCode === 4) {
          createNotification(
            t("errors.encryptionCertificateAlreadyExists"),
            NotificationTypes.WARNING,
          );
        } else {
          createNotification(
            t("errors.genericCreateError", {
              resource: t("common.encryptionCertificate"),
            }),
            NotificationTypes.DANGER,
          );
        }
      })
      .finally(() => {
        setIsLoadingEncryptionCertificates(false);
        encryptionCertificateModalState.close();
      });
  };

  /**
   * Constructs a payload object based on the provided form data and encryption certificates.
   *
   * This function processes and combines various input data, including extensions, folders,
   * exceptions, certificates, and encryption settings, to produce a unified payload.
   *
   * @returns {Object} An object representing the constructed payload with the following properties:
   * - `certificates` {string[]} Array of certificate IDs as strings.
   * - `customCertificates` {Object} Mapping of custom certificate keys to values.
   * - `encryptionCertificate` {Object|null} The encryption certificate object selected by ID, or `null` if not found.
   * - `exceptExclude` {string[]} Array of folder paths included in the "exceptExclude" exceptions.
   * - `exceptFolders` {string[]} Array of folder paths excluded based on the monitor-specific settings.
   * - `exceptInclude` {string[]} Array of folder paths included in the "exceptInclude" exceptions.
   * - `extensions` {string[]} Array of all selected or custom extension strings.
   * - `folders` {string[]} Array of normalized folder paths, including both special and custom folders.
   * - `hashes` {Object} Mapping of hash keys to corresponding values.
   */
  const buildPayload = (form: FormState) => {
    // Extract extensions: combine those selected from the multiselect and those custom
    const allExtensions: string[] = [
      ...form.extensions.map((ext) => ext.id.toString()),
      ...form.customExtensions, // supponendo che siano già delle stringhe
    ];

    // Build the folders array:
    // - Special folders are composed of { item1, item2 } and normalized
    // - Custom folders are normalized directly
    const allFolders: string[] = [
      ...form.folders.map((folder) =>
        normalizePath(`${folder.item1}\\${folder.item2}`),
      ),
      ...form.customFolders.map((folder) => normalizePath(folder)),
    ];

    // Manage folder exceptions:
    // If the specific monitor is not active, use exceptFolders and exceptCustomFolders
    // Otherwise use the appropriate array exceptFoldersWithEncryptSpecificFolders
    let allExceptFolders: string[] = [];
    if (!form.isMonitorSpecificFolder) {
      allExceptFolders = [
        ...form.exceptFolders.map((folder) =>
          normalizePath(`${folder.item1}\\${folder.item2}`),
        ),
        ...form.exceptCustomFolders.map((folder) => normalizePath(folder)),
      ];
    } else {
      allExceptFolders = form.exceptFoldersWithEncryptSpecificFolders.map(
        (folder) => normalizePath(`${folder.item1}\\${folder.item2}`),
      );
    }

    // Build the certificates array by mapping each object to its id as a string
    const certificates = form.certificates.map((cert) => cert.id.toString());

    // Build the exceptInclude and exceptExclude exceptions as arrays of strings
    const exceptInclude = form.exceptInclude.map(
      (item) => `${item.item1}\\${item.item2}`,
    );
    const exceptExclude = form.exceptExclude.map(
      (item) => `${item.item1}\\${item.item2}`,
    );

    // Build the customCertificates and hashes as objects
    const customCertificates = form.customCertificates.reduce(
      (acc, cur) => {
        acc[cur.item1] = cur.item2;
        return acc;
      },
      {} as { [key: string]: string },
    );

    const hashes = form.hashes.reduce(
      (acc, cur) => {
        acc[cur.item1] = cur.item2;
        return acc;
      },
      {} as { [key: string]: string },
    );

    // Find the encryption certificate object based on the selected id
    const encryptionCertificate = encryptionCertificates.find(
      (certificate) => certificate.id.toString() === form.encryptionCertificate,
    );

    return {
      certificates: certificates,
      customCertificates: customCertificates,
      encryptionCertificate: encryptionCertificate || null,
      exceptExclude: exceptExclude,
      exceptFolders: allExceptFolders,
      exceptInclude: exceptInclude,
      extensions: allExtensions,
      folders: allFolders,
      hashes: hashes,
    };
  };

  /**
   * Updates the value of a specific field in the form state.
   *
   * @param {keyof FormState} fieldId - The identifier of the form field to update.
   * @param {*} newValue - The new value to assign to the specified form field.
   */
  const handleFormValueChange = (fieldId: keyof FormState, newValue: any) => {
    setForm((prevForm) => {
      // 1) Crea una copia aggiornata dello stato.
      let updatedForm = {
        ...prevForm,
        [fieldId]: newValue,
      }

      setDirty(true)

      // 2) Se stai cambiando la checkbox "isMonitorSpecificFolder":
      if (fieldId === 'isMonitorSpecificFolder') {
        if (!newValue) {
          updatedForm = {
            ...updatedForm,
            customFolders: [],
            exceptCustomFolders: [],
            exceptFolders: [],
            exceptFoldersWithEncryptSpecificFolders: [],
            folders: [],
          }
          setDisabledFields(['folders', 'customFolders'])
        } else {
          updatedForm = {
            ...updatedForm,
            customFolders: [],
            exceptCustomFolders: [],
            exceptFolders: [],
            exceptFoldersWithEncryptSpecificFolders: [],
            folders: [],
          }
          setDisabledFields((prev) =>
            prev.filter((item) => item !== 'folders' && item !== 'customFolders')
          )
        }
      }

      // 3) Costruisci il payload a partire dal nuovo stato...
      const payload = buildPayload(updatedForm)

      // 4) ...e invoca subito onChange con il nuovo payload.
      props.onChange(payload)

      // 5) Restituisci lo stato aggiornato per setForm().
      return updatedForm
    })
  }

  /**
   * Updates the form state based on the provided folder and exception folder values by normalizing
   * and processing their paths. It identifies special folders and separates them from custom folders.
   *
   * The function reconstructs `folders` and `exceptFolders` as objects containing the folder's starting part
   * and the remaining parts of the path, while custom folders are stored as strings.
   *
   * The resulting state includes:
   * - `customFolders`: Array of custom folder paths.
   * - `exceptCustomFolders`: Array of custom except folder paths.
   * - `folders`: Array of objects representing special folder paths split into key parts.
   * - `exceptFolders`: Array of objects representing special except folder paths split into key parts.
   * - `isMonitorSpecificFolder`: Boolean indicating if any folders are present in the input.
   *
   * The special folders are determined based on a pre-defined `specialFolders` list, which matches
   * the starting part of a folder path (case insensitive) to identify folders as special.
   */
  const setFormFromValue = () => {
    // Create an array of all extensions known by file types
    let fileTypesArray: string[] = [];
    for (const key in settings?.fileTypes) {
      fileTypesArray = [...fileTypesArray, ...settings.fileTypes[key]];
    }
    // Inside props.value, extensions are provided as an array of strings.
    // CustomExtensions are those that are NOT in fileTypesArray,
    // while the "normal" ones are mapped to objects { id, value
    const originalExtensions: string[] = props?.value?.extensions || [];
    const recreatedCustomExtensions = originalExtensions.filter(
      (ext) => !fileTypesArray.includes(ext),
    );
    const recreatedExtensions = originalExtensions
      .filter((ext) => fileTypesArray.includes(ext))
      .map((ext) => ({ id: ext, value: ext }));

    // Build the form state from the provided value
    const recreatedFolders: { item1: string; item2: string }[] = [];
    const recreatedCustomFolders: string[] = [];
    (props?.value?.folders || []).forEach((folder: string) => {
      const normalizedFolder = normalizePath(folder);
      const parts = normalizedFolder.split("\\");
      const firstPart = parts[0];
      const restPart = parts.slice(1).join("\\");
      const specialFolder = specialFolders.find(
        (sf) => sf.id.toLowerCase() === firstPart.toLowerCase(),
      );
      if (specialFolder) {
        recreatedFolders.push({ item1: firstPart, item2: restPart });
      } else {
        recreatedCustomFolders.push(normalizedFolder);
      }
    });
    // Check if the monitorSpecificFolder is active
    const monitorSpecificFolder =
      props?.value?.folders && props?.value?.folders.length > 0;

    // Manage exceptFolders exceptions
    const recreatedExceptFolders: { item1: string; item2: string }[] = [];
    const recreatedExceptCustomFolders: string[] = [];
    const recreatedExceptFoldersWithMonitorSpecificFolders: {
      item1: string;
      item2: string;
    }[] = [];

    if (!monitorSpecificFolder) {
      // If monitorSpecificFolder is not active, combine the monitored folders
      (props?.value?.exceptFolders || []).forEach((folder: string) => {
        const parts = folder.split("\\");
        const firstPart = parts[0];
        const restPart = parts.slice(1).join("\\");
        const specialFolder = specialFolders.find((sf) => sf.id === firstPart);
        if (specialFolder) {
          recreatedExceptFolders.push({ item1: firstPart, item2: restPart });
        } else {
          recreatedExceptCustomFolders.push(folder);
        }
      });
    } else {
      // If monitorSpecificFolder is active, combine the monitored folders
      const allMonitoredFolders = [
        ...recreatedFolders.map((folder) =>
          normalizePath(
            folder.item1 + (folder.item2 ? "\\" + folder.item2 : ""),
          ),
        ),
        ...recreatedCustomFolders.map((folder) => normalizePath(folder)),
      ];
      (props?.value?.exceptFolders || []).forEach((exceptFolder: string) => {
        const normalizedExceptFolder = normalizePath(exceptFolder);
        const matchingFolder = allMonitoredFolders.find((monFolder) =>
          normalizedExceptFolder
            .toLowerCase()
            .startsWith(monFolder.toLowerCase()),
        );
        if (matchingFolder) {
          let item2 = normalizedExceptFolder.substring(matchingFolder.length);
          if (item2.startsWith("\\")) {
            item2 = item2.substring(1);
          }
          recreatedExceptFoldersWithMonitorSpecificFolders.push({
            item1: matchingFolder,
            item2,
          });
        } else {
          recreatedExceptFoldersWithMonitorSpecificFolders.push({
            item1: normalizedExceptFolder,
            item2: "",
          });
        }
      });
    }

    // Manage exceptInclude and exceptExclude exceptions
    const recreatedExceptInclude: { item1: string; item2: string }[] = [];
    (props?.value?.exceptInclude || []).forEach((folder: string) => {
      const parts = folder.split("\\");
      const firstPart = parts[0];
      const restPart = parts.slice(1).join("\\");
      recreatedExceptInclude.push({ item1: firstPart, item2: restPart });
    });
    const recreatedExceptExclude: { item1: string; item2: string }[] = [];
    (props?.value?.exceptExclude || []).forEach((folder: string) => {
      const parts = folder.split("\\");
      const firstPart = parts[0];
      const restPart = parts.slice(1).join("\\");
      recreatedExceptExclude.push({ item1: firstPart, item2: restPart });
    });

    // Rebuild the certificates array by mapping each id to an object { id, value }
    const recreatedCertificates: MultiselectComboboxOption[] = (
      props?.value?.certificates || []
    ).map((identifier: any) => ({
      id: identifier,
      value: settings?.certifiedApplications?.[identifier]?.name || "",
    }));

    // Manage customCertificates and hashes
    // If these fields are not already arrays, we convert them from object to array of {item1, item2}
    const recreateObjectField = (
      field: any,
    ): { item1: string; item2: string }[] => {
      if (!field) return [];
      if (Array.isArray(field)) {
        return field;
      } else {
        return Object.entries(field).map(([item1, item2]) => ({
          item1: String(item1),
          item2: String(item2),
        }));
      }
    };
    const recreatedCustomCertificates = recreateObjectField(
      props?.value?.customCertificates,
    );
    const recreatedHashes = recreateObjectField(props?.value?.hashes);

    const encryptionCertificateValue = props?.value?.encryptionCertificate;
    let encryptionCertificateId = null;

    if (typeof encryptionCertificateValue === "object" && encryptionCertificateValue?.id) {
      encryptionCertificateId = encryptionCertificateValue.id.toString();
    } else if (typeof encryptionCertificateValue === "string" || typeof encryptionCertificateValue === "number") {
      encryptionCertificateId = encryptionCertificateValue.toString();
    }

    setForm({
      certificates: recreatedCertificates,
      customCertificates: recreatedCustomCertificates,
      customExtensions: recreatedCustomExtensions,
      customFolders: recreatedCustomFolders,
      encryptionCertificate: encryptionCertificateId,
      exceptCustomFolders: !monitorSpecificFolder
        ? recreatedExceptCustomFolders
        : [],
      exceptExclude: recreatedExceptExclude,
      exceptFolders: !monitorSpecificFolder ? recreatedExceptFolders : [],
      exceptFoldersWithEncryptSpecificFolders: monitorSpecificFolder
        ? recreatedExceptFoldersWithMonitorSpecificFolders
        : [],
      exceptInclude: recreatedExceptInclude,
      extensions: recreatedExtensions,
      folders: recreatedFolders,
      hashes: recreatedHashes,
      isMonitorSpecificFolder: monitorSpecificFolder,
    });
  };

  /**
   * Retrieves encryption certificates from the server using the generic service.
   *
   * The function calls the `getEncryptionCertificates` method of the generic service
   * to fetch encryption certificates. The response received from the server is logged
   * to the console and subsequently stored via the `setEncryptionCertificates` function.
   *
   * This function does not take any parameters and does not return a value.
   */
  const getEncryptionCertificates = () => {
    setIsLoadingEncryptionCertificates(true);
    genericService
      .getEncryptionCertificates()
      .then((response) => {
        setEncryptionCertificates(response);
        setFormFromValue();
      })
      .catch(() => {
        createNotification(
          t("errors.genericFetchError", {
            resource: t("common.encryptionCertificates"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoadingEncryptionCertificates(false);
      });
  };

  /**
   * A memoized variable that provides a list of options derived from certified applications.
   * Each option in the list includes an id and a value representing a certified application.
   * The options are generated by mapping through the values of `settings.certifiedApplications`.
   * The memoized computation relies on the changes to `settings.certifiedApplications`.
   */
  const certifiedApplicationsOptions = useMemo(() => {
    if (!settings?.certifiedApplications) {
      return [];
    }
    return Object.values(settings.certifiedApplications).map(
      (certifiedApplication: any) => ({
        id: certifiedApplication.id,
        value: certifiedApplication.name,
      }),
    );
  }, [settings?.certifiedApplications]);
  /**
   * exceptIncludeOptions is a memoized variable that filters and returns a list of certificates
   * excluding those already present in the `exceptInclude` field.
   * It uses the ids of the certificates from `exceptInclude` to determine which ones to exclude.
   *
   * Dependencies:
   * - This variable updates whenever there is a change in `form.certificates`, `form.exceptExclude`, or `form.exceptInclude`.
   *
   * Mapping Behavior:
   * - The `exceptInclude` array is mapped to retrieve `item1` from each object and converts them into integers
   *   to match with the ids of certificates.
   *
   * Filter Logic:
   * - Filters out the `form.certificates` where the certificate id matches an id present in the `mappedInitialValue` array.
   */
  const exceptIncludeOptions = useMemo(() => {
    // Set options for exceptInclude and exceptExclude fields based on the selected certificates
    let mappedInitialValue: number[] = [];
    mappedInitialValue = form?.exceptExclude?.map((item: { item1: any }) =>
      parseInt(item.item1),
    );
    return form?.certificates?.filter((certifiedApplication: any) => {
      return !mappedInitialValue?.includes(certifiedApplication.id);
    });
  }, [form?.certificates, form?.exceptExclude, form?.exceptInclude]);

  /**
   * A memoized variable that calculates the filtered options for the `exceptExclude` field.
   * It excludes certificates that are already selected in the `exceptExclude` field value.
   *
   * @constant {Array} exceptExcludeOptions
   * @returns {Array} The filtered list of certificates that do not match the `exceptExclude` items.
   *
   * Dependencies:
   * - `form.certificates`: List of all available certificates.
   * - `props.value.exceptExclude`: Selected `exceptExclude` items containing certificate IDs.
   * - `form.exceptExclude`: A field to manage `exceptExclude` options as per the form's state.
   */
  const exceptExcludeOptions = useMemo(() => {
    // Set options for exceptInclude and exceptExclude fields based on the selected certificates
    let mappedInitialValue: number[] = [];
    mappedInitialValue = form?.exceptInclude?.map((item: { item1: any }) =>
      parseInt(item.item1),
    );
    return form?.certificates?.filter((certifiedApplication: any) => {
      return !mappedInitialValue?.includes(certifiedApplication.id);
    });
  }, [form?.certificates, form?.exceptInclude, form?.exceptExclude]);

  /**
   * An array of file type extension options created by processing specified file type categories.
   *
   * This variable is memoized using `useMemo` to improve performance by avoiding recalculation unless the `settings` dependency changes.
   * The available categories are:
   * - "office"
   * - "image"
   * - "multimedia"
   * - "cad"
   * - "executable"
   *
   * Each extension option includes:
   * - An `id` representing the file extension.
   * - A `sectionId` corresponding to the category type.
   * - A `sectionLabel` providing a localized label for the category using the translation function `t`.
   * - A `value` representing the file extension.
   *
   * Dependencies:
   * - `settings`: An object containing file type configuration data.
   */
  const extensionsOptions = useMemo(() => {
    if (!settings || !settings.fileTypes) {
      return [];
    }

    const newOptions: MultiselectComboboxOption[] = [];
    const types: (keyof typeof settings.fileTypes)[] = [
      "office",
      "image",
      "multimedia",
      "cad",
      "executable",
    ];

    types.forEach((type) => {
      const fileTypeArray = settings.fileTypes[type];
      if (Array.isArray(fileTypeArray)) {
        newOptions.push(
          ...fileTypeArray.map((ext: string) => ({
            id: ext,
            sectionId: type as string,
            sectionLabel: t(`fileTypes.${type as string}`),
            value: ext,
          })),
        );
      }
    });

    return newOptions;
  }, [settings, t]);

  /**
   * Errors object generated by validating form input fields.
   * The `errors` variable is initialized using the `useMemo` hook to memoize
   * the validation logic. It contains key-value pairs where keys represent
   * the field names and values represent the respective error messages.
   *
   * Validation example:
   * - Validates `exceptFoldersWithEncryptSpecificFolders` to ensure array elements
   *   have defined values for `item1` and `item2`. If invalid, an error message
   *   is added to indicate the validation failure.
   *
   * Dependencies:
   * - `form`: Object containing the input form fields and their values.
   * - `t`: Localization function used to fetch relevant error messages.
   */
  const errors = useMemo<Partial<Record<keyof FormState, string>>>(() => {
    const e: Partial<Record<keyof FormState, string>> = {};

    // Verifica che sia selezionato l'encryptionCertificate
    if (!form.encryptionCertificate) {
      e.encryptionCertificate = t("validation.required", {
        field: t("common.encryptionCertificate"),
      });
    }

    // Se il monitor specifico è attivo, controlla che per ogni elemento in
    // exceptFoldersWithEncryptSpecificFolders siano presenti item1 e item2
    if (form.isMonitorSpecificFolder) {
      if (
        form.exceptFoldersWithEncryptSpecificFolders?.some(
          (folder) => !folder.item1 || !folder.item2,
        )
      ) {
        e.exceptFoldersWithEncryptSpecificFolders = t(
          "validation.applicationExceptFolderPair",
        );
      }
    }

    // Verifica che sia presente almeno una estensione (tra extensions e customExtensions)
    const hasExtensions = ["extensions", "customExtensions"].some((key) => {
      const arr = form[key as keyof FormState] as unknown as any[];
      return arr && arr.length > 0;
    });
    if (!hasExtensions) {
      e.customExtensions = t("validation.leastOnePredefinedCustomExtension");
    } else if (
      !form.isMonitorSpecificFolder &&
      form.customExtensions?.includes("*")
    ) {
      e.customExtensions = t("validation.leastOneFolderForExtensionsWildcard");
    }

    // Se il monitor specifico è attivo, deve essere presente almeno una cartella
    if (form.isMonitorSpecificFolder) {
      const hasFolders = ["folders", "customFolders"].some((key) => {
        const arr = form[key as keyof FormState] as unknown as any[];
        return arr && arr.length > 0;
      });
      if (!hasFolders) {
        e.customFolders = t("validation.leastOnePredefinedCustomFolder");
      }
    }

    // Se non sono stati selezionati certificati, eppure sono presenti eccezioni,
    // è un’incoerenza
    if (form.certificates.length === 0) {
      if (
        (form.exceptInclude && form.exceptInclude.length > 0) ||
        (form.exceptExclude && form.exceptExclude.length > 0)
      ) {
        e.exceptInclude = t("validation.invalidCertificatesForExceptions");
      }
    }

    // Check if encryptionCertificate is selected
    if (!form.encryptionCertificate) {
      e.encryptionCertificate = t("validation.required", {
        field: t("common.encryptionCertificate"),
      });
    }

    return e;
  }, [form, t]);

  useEffect(() => {
    if (form.isMonitorSpecificFolder) {
      // Create options for exceptFoldersWithEncryptSpecificFolders based on the combination of item1 and item2 of folders
      const exceptFoldersWithEncryptSpecificFolders =
        form?.folders?.map((folder: { item1: string; item2: string }) => {
          // Combine item1 and item2 and normalize the path
          const combinedPath = `${folder.item1}\\${folder.item2}`;
          const normalizedPath = normalizePath(combinedPath);
          return {
            id: normalizedPath,
            value: normalizedPath,
          };
        }) || [];

      // Add customFolders to exceptFoldersWithEncryptSpecificFolders with normalized paths
      form?.customFolders?.forEach((folder: string) => {
        const normalizedFolder = normalizePath(folder);
        exceptFoldersWithEncryptSpecificFolders.push({
          id: normalizedFolder,
          value: normalizedFolder,
        });
      });

      // item1 and item2 are required for exceptFoldersWithEncryptSpecificFolders for each object
      const hasInvalidExceptFoldersWithEncryptSpecificFolders =
        form.exceptFoldersWithEncryptSpecificFolders?.some(
          (folder: { item1: string; item2: string }) =>
            !folder.item1 || !folder.item2,
        );

      if (hasInvalidExceptFoldersWithEncryptSpecificFolders) {
        // Set error
        /*errors.exceptFoldersWithEncryptSpecificFolders = t(
          "validation.applicationExceptFolderPair",
        );*/
      }

      // Update options for exceptFoldersWithEncryptSpecificFolders if they are different
      if (
        JSON.stringify(exceptFoldersWithEncryptSpecificFoldersOptions) !==
        JSON.stringify(exceptFoldersWithEncryptSpecificFolders)
      ) {
        setExceptFoldersWithEncryptSpecificFoldersOptions(
          exceptFoldersWithEncryptSpecificFolders,
        );
      }
    }
    const hasFolders = (
      ["folders", "customFolders"] as (keyof FormState)[]
    ).some((key) => {
      return form[key] && Object.keys(form[key]).length > 0;
    });

    if (!hasFolders) {
      //errors.customFolders = t("validation.leastOnePredefinedCustomFolder");
    }
    // Remove folders and customFolders from disabled fields
    /*setDisabledFields((prevState) => {
      return prevState.filter(
        (item) => item !== "folders" && item !== "customFolders",
      );
    });*/
  }, [form.isMonitorSpecificFolder, form.folders, form.customFolders]);

  /**
  useEffect(() => {
    const payload = buildPayload();
    // If the payload is different from the current value, update the value
    if (JSON.stringify(payload) !== JSON.stringify(props.value)) {
      props.onChange(payload);
    }
  }, [form]);
  */

  useEffect(() => {
    props.isInvalid(Object.keys(errors).length > 0);
  }, [errors, props]);

  useEffect(() => {
    if (!settings) return;
    getEncryptionCertificates();
    setFormFromValue();
  }, [settings]);

  return (
    <>
      <SecurityPolicyPermissionsFormLayout>
        <SecurityPolicyPermissionsFormLayout.Section>
          {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
          <Checkbox
            isSelected={form.isMonitorSpecificFolder}
            onChange={(e) =>
              handleFormValueChange("isMonitorSpecificFolder", e)
            }
          >
            Encrypt only in specific folders
          </Checkbox>
          <SecurityPolicyInputComboBoxStringTuple
            id="folders"
            invalidFields={[]}
            isLoading={props.isLoading}
            isReadOnly={props.isReadOnly}
            isDisabled={disabledFields.includes("folders")}
            onChange={(e) => handleFormValueChange("folders", e)}
            value={form.folders}
            firstFieldPlaceholder="Special Folder"
            secondFieldPlaceholder="Original File Name"
            isInvalid={!!errors?.customFolders}
            options={specialFolders}
          />
          <SecurityPolicyInputStringList
            isLoading={props.isLoading}
            isReadOnly={props.isReadOnly}
            onChange={(e) => handleFormValueChange("customFolders", e)}
            id="customFolders"
            label="Custom Folders"
            value={form.customFolders}
            isDisabled={disabledFields.includes("customFolders")}
            invalidFields={[]}
          />
          {errors?.customFolders && (
            <SecurityPolicyPermissionsFormLayout.Error>
              {errors.customFolders}
            </SecurityPolicyPermissionsFormLayout.Error>
          )}

          <SecurityPolicyPermissionsFormLayout.Description>
            Exception folders
          </SecurityPolicyPermissionsFormLayout.Description>

          {!form.isMonitorSpecificFolder ? (
            <>
              <SecurityPolicyInputComboBoxStringTuple
                id="exceptFolders"
                invalidFields={[]}
                isLoading={false}
                isReadOnly={false}
                isDisabled={disabledFields.includes("exceptFolders")}
                onChange={(e) => handleFormValueChange("exceptFolders", e)}
                value={form.exceptFolders}
                label="Absolute Path"
                firstFieldPlaceholder="Except folder"
                secondFieldPlaceholder="Except folder"
                title="Except folder must be a valid path"
                options={specialFolders}
              />
              <SecurityPolicyInputStringList
                isLoading={false}
                isReadOnly={false}
                onChange={(e) =>
                  handleFormValueChange("exceptCustomFolders", e)
                }
                id="exceptCustomFolders"
                label="Except Custom Folders"
                value={form.exceptCustomFolders}
                isDisabled={disabledFields.includes("exceptCustomFolders")}
                invalidFields={[]}
              />
            </>
          ) : (
            <SecurityPolicyInputComboBoxStringTuple
              id="exceptFoldersWithEncryptSpecificFolders"
              invalidFields={[]}
              isLoading={props.isLoading}
              isReadOnly={props.isReadOnly}
              isDisabled={props.isDisabled}
              onChange={(e) =>
                handleFormValueChange(
                  "exceptFoldersWithEncryptSpecificFolders",
                  e,
                )
              }
              value={form.exceptFoldersWithEncryptSpecificFolders}
              label="Absolute Path"
              firstFieldPlaceholder="Application"
              secondFieldPlaceholder="Except folder"
              title="Except folder must be a valid path"
              options={exceptFoldersWithEncryptSpecificFoldersOptions}
            />
          )}
          <SecurityPolicyPermissionsFormLayout.Description>
            Type of file you want to encrypt
          </SecurityPolicyPermissionsFormLayout.Description>
          <SecurityPolicyInputMultiSelect
            id="extensions"
            placeholder="File types"
            options={extensionsOptions}
            isLoading={props.isLoading}
            isReadOnly={props.isReadOnly}
            isDisabled={props.isDisabled}
            onChange={(e) => handleFormValueChange("extensions", e)}
            value={form.extensions}
          />
          <SecurityPolicyInputStringList
            isLoading={props.isLoading}
            isReadOnly={props.isReadOnly}
            onChange={(e) => handleFormValueChange("customExtensions", e)}
            id="customExtensions"
            label="Custom file extensions"
            value={form.customExtensions}
            isDisabled={props.isDisabled}
            invalidFields={[]}
          />
          {errors?.customExtensions && (
            <SecurityPolicyPermissionsFormLayout.Error>
              {errors.customExtensions}
            </SecurityPolicyPermissionsFormLayout.Error>
          )}
          <SecurityPolicyPermissionsFormLayout.Description>
            Applications authorized
          </SecurityPolicyPermissionsFormLayout.Description>
          <SecurityPolicyInputMultiSelect
            id="certificates"
            placeholder="Apps Certified"
            options={certifiedApplicationsOptions}
            isLoading={props.isLoading}
            isReadOnly={props.isReadOnly}
            isDisabled={props.isDisabled}
            onChange={(e) => handleFormValueChange("certificates", e)}
            value={form.certificates}
          />
          <SecurityPolicyPermissionsFormLayout.Description>
            Exceptions ( None except these )
          </SecurityPolicyPermissionsFormLayout.Description>
          <SecurityPolicyInputComboBoxStringTuple
            id="exceptInclude"
            invalidFields={[]}
            isLoading={props.isLoading}
            isReadOnly={props.isReadOnly}
            isDisabled={props.isDisabled}
            onChange={(e) => handleFormValueChange("exceptInclude", e)}
            value={form.exceptInclude}
            label="Except Include"
            firstFieldPlaceholder="Application"
            secondFieldPlaceholder="Name"
            options={exceptIncludeOptions}
          />
          <SecurityPolicyPermissionsFormLayout.Description>
            Exceptions ( All except these )
          </SecurityPolicyPermissionsFormLayout.Description>
          <SecurityPolicyInputComboBoxStringTuple
            id="exceptExclude"
            invalidFields={[]}
            isLoading={props.isLoading}
            isReadOnly={props.isReadOnly}
            isDisabled={props.isDisabled}
            onChange={(e) => handleFormValueChange("exceptExclude", e)}
            value={form.exceptExclude}
            label="Except Exclude"
            firstFieldPlaceholder="Application"
            secondFieldPlaceholder="Name"
            options={exceptExcludeOptions}
          />
          <SecurityPolicyPermissionsFormLayout.Description>
            Custom certificates
          </SecurityPolicyPermissionsFormLayout.Description>
          <SecurityPolicyInputStringTuple
            id="customCertificates"
            isLoading={props.isLoading}
            isReadOnly={props.isReadOnly}
            isDisabled={props.isDisabled}
            onChange={(e) => handleFormValueChange("customCertificates", e)}
            value={form.customCertificates}
            label="Custom Certificates"
            firstFieldPlaceholder="Name"
            secondFieldPlaceholder="Thumbprint"
          />
          <SecurityPolicyPermissionsFormLayout.Description>
            Hashed applications authorized
          </SecurityPolicyPermissionsFormLayout.Description>
          <SecurityPolicyInputStringTuple
            id="hashes"
            isLoading={props.isLoading}
            isReadOnly={props.isReadOnly}
            isDisabled={props.isDisabled}
            onChange={(e) => handleFormValueChange("hashes", e)}
            value={form.hashes}
            label="File Hash"
            firstFieldPlaceholder="Name"
            secondFieldPlaceholder="Hash"
          />
          <SecurityPolicyPermissionsFormLayout.Description>
            Select the Encryption Certificate
          </SecurityPolicyPermissionsFormLayout.Description>
          <div className="flex flex-row gap-2">
            <ComboBox
              isReadOnly={props.isReadOnly}
              isDisabled={props.isDisabled || !!props?.id}
              isLoading={props.isLoading || isLoadingEncryptionCertificates}
              id="encryptionCertificate"
              selectedKey={form.encryptionCertificate}
              aria-label={"Encryption Certificate"}
              label={"Encryption Certificate"}
              isInvalid={!!errors.encryptionCertificate}
              errorMessage={errors.encryptionCertificate}
              required={true}
              onSelectionChange={(value) => {
                handleFormValueChange("encryptionCertificate", value);
              }}
            >
              {encryptionCertificates.map((certificate) => (
                <Item key={certificate.id} aria-label={certificate.name}>
                  {certificate.name}
                </Item>
              ))}
            </ComboBox>
            <div className="mt-2.5">
              <Button
                noPadding
                type="button"
                variant="text"
                isDisabled={
                  props.isReadOnly ||
                  isLoadingEncryptionCertificates ||
                  props.isLoading ||
                  !!props?.id
                }
                onPress={() => {
                  setNewEncryptionCertificateName("");
                  encryptionCertificateModalState.open();
                  setTimeout(() => {
                    newEncryptionCertificateNameRef.current?.focus();
                  }, 100);
                }}
              >
                <Icon name="AddIcon" className="h-6 w-6 text-dark-blue" />
              </Button>
            </div>
          </div>
        </SecurityPolicyPermissionsFormLayout.Section>
      </SecurityPolicyPermissionsFormLayout>
      <Modal state={encryptionCertificateModalState}>
        <div className="flex max-w-lg flex-col gap-4 outline-none">
          <h3 className="text-lg text-dark-blue">New Encryption Certificate</h3>
          <p className="text-lg text-extra-dark-gray">
            <Trans>
              You are about to create a new encryption certificate. Please
              provide the required information
            </Trans>
          </p>
          <TextField
            ref={newEncryptionCertificateNameRef}
            label={t("common.name")}
            placeholder={t("common.name")}
            isDisabled={
              props.isReadOnly ||
              props.isDisabled ||
              isLoadingEncryptionCertificates
            }
            onChange={(e) => {
              setNewEncryptionCertificateName(e);
            }}
            isRequired
            value={newEncryptionCertificateName}
            name="confirmationText"
            type="text"
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === "Enter") {
                handleCreateNewEncryptionCertificate();
              }
            }}
          />
          <div className="flex justify-end space-x-3 pt-16">
            <Button
              variant="text"
              isDisabled={isLoadingEncryptionCertificates}
              onPress={() => {
                encryptionCertificateModalState.close();
              }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              isLoading={isLoadingEncryptionCertificates}
              isDisabled={
                props.isReadOnly ||
                props.isDisabled ||
                newEncryptionCertificateName.trim() === ""
              }
              autoFocus
              variant="contained"
              onPress={handleCreateNewEncryptionCertificate}
            >
              {t("common.confirm")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SystemEncryptionPolicyPermissionsForm;
