import { SortDescriptor } from "@react-types/shared";
import qs from "qs";
import {
  Key,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDateFormatter } from "react-aria";
import { useTranslation } from "react-i18next";
import type { Selection } from "react-stately";
import { useOverlayTriggerState } from "react-stately";

import { TableAction } from "../components/TableActionsBar";
import UserDetail from "../components/user/UserDetail";
import { useAuth } from "../contexts/AuthContext";
import { useDialogContext } from "../contexts/DialogContext";
import { useNavigationDirty } from "../contexts/NavigationDirtyContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { usePendingComputerUpdate } from "../contexts/PendingComputerUpdateContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import User, { UserStatus } from "../interfaces/User";
import { UserService } from "../services/userService";
import { transformObjectArraysToArrayOfIds } from "../utils/utils";

import { useNotifications } from "./useNotifications";

const userService = new UserService();

type UserAction = {
  key: string;
  label: string;
};

interface FilterState {
  count: number;
  offset: number;
  search?: string;
  sort?: SortDescriptor;
}

function useUsersTab() {
  const {
    openRightPanel,
    setRightPanelContent,
    isRightPanelOpen,
    closeRightPanel,
  } = useContext(RightPanelContext) as RightPanelContextProps;
  const [users, setUsers] = useState<User[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([]));
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined); // Used for display the name of the item in the dialogs ( Only one )
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<FilterState>({
    count: 15,
    offset: 0,
    search: undefined,
  });
  const [activeUser, setActiveUser] = useState<Key | null>(null);
  const [activeUsersForDeletion, setActiveUsersForDeletion] = useState<Key[]>(
    [],
  );
  const dialog = useDialogContext();
  const { t } = useTranslation();
  const { confirmDirtyNavigation } = useNavigationDirty();
  const { createNotification } = useNotifications();
  const { resetPendingUpdatesTimeout } = usePendingComputerUpdate();
  const { isCurrentUserReadOnly } = useAuth();
  const deleteModalState = useOverlayTriggerState({});

  const dateFormatter = useDateFormatter({
    dateStyle: "medium",
    timeStyle: "short",
  });
  const { currentUser } = useAuth();
  /**
   * @desc Fetch users
   */
  const getUsers = useCallback(async () => {
    setIsLoading(true);
    const transformedFilters = transformObjectArraysToArrayOfIds(filters);
    if (transformedFilters.sort !== undefined) {
      transformedFilters.sortCol = filters.sort?.column;
      transformedFilters.sortDir = filters.sort?.direction;
      delete transformedFilters.sort;
    } else {
      transformedFilters.sortCol = "createdOn";
      transformedFilters.sortDir = "descending";
    }

    userService
      .getUsers(qs.stringify(filters))
      .then((res) => {
        setUsers(res.data);
        setTotal(res.total);
      })
      .catch(() => {
        createNotification(
          t("errors.genericFetchError", {
            resource: t("common.users"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [createNotification, filters, t]);

  /**
   * Handles the opening of the right panel.
   * @async
   * @param {number} [userId] - The user ID.
   */
  const handleOpenRightPanel = async (userId?: number) => {
    const confirm = await confirmDirtyNavigation();
    if (!confirm) {
      return;
    }
    openRightPanel();
    setRightPanelContent(
      <UserDetail
        key={Math.random()}
        id={userId}
        onCancel={() => {
          closeRightPanel();
        }}
        onSaved={() => {
          getUsers().then(closeRightPanel);
        }}
      />,
    );
    if (!userId) {
      setActiveUser(null);
    }
  };

  /**
   * Handles the deletion of user(s).
   *
   * @returns {void}
   */
  const handleUserDelete = (): void => {
    userService
      .deleteUsers(
        qs.stringify({
          id: activeUsersForDeletion.includes("all")
            ? [-1000]
            : activeUsersForDeletion,
        }),
      )
      .then(() => {
        closeRightPanel();
        getUsers().then(() => {
          resetPendingUpdatesTimeout();
          deleteModalState.close();
          closeRightPanel();
          setSelectedKeys(new Set([]));
          setActiveUsersForDeletion([]);
        });
      })
      .catch(() => {
        createNotification(
          t("errors.genericDeleteError", {
            resource: t("common.user"),
          }),
          NotificationTypes.DANGER,
        );
      });
  };

  const findUserByKey = (key: Key): User | undefined => {
    return users.find((user) => user.id === Number(key));
  };

  /**
   * Represents a list of actions for a table.
   * @typedef {Object} TableAction
   * @property {string} icon - The icon name for the action.
   * @property {string} label - The label for the action.
   * @property {Function} onClick - The callback function to be executed when the action is clicked.
   */
  const tableActions: TableAction[] = useMemo(() => {
    if (isCurrentUserReadOnly) {
      return [];
    }

    const baseAction: TableAction = {
      icon: "AddIcon",
      label: t("common.addUser"),
      onClick: () => {
        handleOpenRightPanel().then(() => {});
      },
    };

    const additionalActions: TableAction[] = [
      {
        icon: "DeactivateIcon",
        label: t("common.suspend"),
        onClick: () => {
          dialog
            .open(
              t("dialog.suspendUser"),
              t("dialog.suspendUserMessage", {
                count:
                  selectedKeys === "all"
                    ? total
                    : Array.from(selectedKeys).length,
                resourceName: selectedUser?.username,
              }),
            )
            .then(() => {
              userService
                .changeStatus(
                  UserStatus.suspended,
                  selectedKeys === "all" ? [-1000] : Array.from(selectedKeys),
                )
                .then(() => {
                  setSelectedKeys(new Set([]));
                  getUsers().then(() => {
                    resetPendingUpdatesTimeout();
                  });
                })
                .catch(() => {
                  createNotification(
                    t("errors.genericUpdateError", {
                      resource: t("common.user"),
                    }),
                    NotificationTypes.DANGER,
                  );
                });
            })
            .catch(() => {});
        },
      },
      {
        icon: "PlayFilledIcon",
        label: t("common.enable"),
        onClick: () => {
          dialog
            .open(
              t("dialog.enableUser"),
              t("dialog.enableUserMessage", {
                count:
                  selectedKeys === "all"
                    ? total
                    : Array.from(selectedKeys).length,
                resourceName: selectedUser?.username,
              }),
            )
            .then(() => {
              userService
                .changeStatus(
                  UserStatus.enabled,
                  selectedKeys === "all" ? [-1000] : Array.from(selectedKeys),
                )
                .then(() => {
                  setSelectedKeys(new Set([]));
                  getUsers().then(() => {
                    resetPendingUpdatesTimeout();
                  });
                })
                .catch(() => {
                  createNotification(
                    t("errors.genericUpdateError", {
                      resource: t("common.user"),
                    }),
                    NotificationTypes.DANGER,
                  );
                });
            })
            .catch(() => {});
        },
      },
      {
        icon: "DeleteIcon",
        label: t("common.delete"),
        onClick: () => {
          deleteModalState.open();
          setActiveUsersForDeletion(
            selectedKeys === "all" ? ["all"] : Array.from(selectedKeys),
          );
        },
      },
    ];

    if (selectedKeys === "all" || selectedKeys.size > 0) {
      return additionalActions;
    } else {
      return [baseAction];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closeRightPanel,
    dialog,
    getUsers,
    openRightPanel,
    selectedKeys,
    setRightPanelContent,
    t,
  ]);

  const onMenuAction = (id: number, key: Key) => {
    const foundUser = findUserByKey(id);
    switch (key) {
      case "edit":
        handleOpenRightPanel(Number(id)).then(() => {});
        break;
      case "suspend":
        dialog
          .open(
            t("dialog.suspendUser"),
            t("dialog.suspendUserMessage", {
              resourceName: foundUser?.username,
            }),
          )
          .then(() => {
            userService
              .changeStatus(UserStatus.suspended, [id])
              .then(() => {
                setSelectedKeys(new Set([]));
                getUsers().then(() => {
                  resetPendingUpdatesTimeout();
                });
              })
              .catch(() => {
                createNotification(
                  t("errors.genericUpdateError", {
                    resource: t("common.user"),
                  }),
                  NotificationTypes.DANGER,
                );
              });
          })
          .catch(() => {});
        break;
      case "enable":
        dialog
          .open(
            t("dialog.enableUser"),
            t("dialog.enableUserMessage", {
              resourceName: foundUser?.username,
            }),
          )
          .then(() => {
            userService.changeStatus(UserStatus.enabled, [id]).then(() => {
              setSelectedKeys(new Set([]));
              getUsers().then(() => {
                resetPendingUpdatesTimeout();
              });
            });
          })
          .catch(() => {
            createNotification(
              t("errors.inviteUserError"),
              NotificationTypes.DANGER,
            );
          });
        break;
      case "invite":
        dialog
          .open(
            t("dialog.inviteUser"),
            t("dialog.inviteUserMessage", {
              resourceName: foundUser?.username,
            }),
          )
          .then(() => {
            userService
              .sendInvitation(id)
              .then(() => {
                createNotification(
                  t("notifications.userInvited"),
                  NotificationTypes.SUCCESS,
                );
                getUsers().then(() => {});
              })
              .catch(() => {
                createNotification(
                  t("errors.genericError", {
                    resource: t("common.user"),
                  }),
                  NotificationTypes.DANGER,
                );
              });
          })
          .catch(() => {});
        break;
      case "delete":
        deleteModalState.open();
        setActiveUsersForDeletion([id]);
        break;
      default:
        break;
    }
  };

  /**
   * Sets the active user based on the provided key and performs several actions:
   * - Opens the right panel
   * - Sets the content of the right panel to display the details of the user
   * - Provides options to cancel and save changes
   * - Refreshes the user list after changes are saved
   *
   * @param {Key} key - The key of the user to perform actions on
   */
  const onRowAction = (key: Key) => {
    handleOpenRightPanel(Number(key)).then(() => {
      setActiveUser(key);
    });
  };

  /**
   * Retrieves the user actions based on the user ID.
   *
   * @param {number} id - The ID of the user.
   * @returns {UserAction[]} - An array of user actions.
   */
  const getUserActions = (id: number): UserAction[] => {
    const defaultUserActions: UserAction[] = [
      { key: "edit", label: t("common.edit") },
      { key: "invite", label: t("common.invite") },
    ];

    // Find user by id
    const foundUser = users.find((user) => user.id === id);
    if (foundUser) {
      switch (foundUser.status) {
        case UserStatus.enabled:
          if (Number(foundUser.id) !== Number(currentUser?.id)) {
            defaultUserActions.push({
              key: "suspend",
              label: t("common.suspend"),
            });
          }
          break;
        case UserStatus.suspended:
          defaultUserActions.push({ key: "enable", label: t("common.enable") });
          break;
        default:
          break;
      }

      if (Number(foundUser.id) !== Number(currentUser?.id)) {
        defaultUserActions.push({ key: "delete", label: t("common.delete") });
      }
    }

    return defaultUserActions;
  };

  useEffect(() => {
    getUsers().then(() => {});
  }, [getUsers]);

  useEffect(() => {
    if (!isRightPanelOpen) {
      setActiveUser(null);
    }
  }, [isRightPanelOpen]);

  useEffect(() => {
    if (selectedKeys instanceof Set && selectedKeys.size > 0) {
      closeRightPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys]);

  useEffect(() => {
    if (activeUsersForDeletion.length === 1) {
      const foundUser = users.find(
        (user) => user.id === Number(activeUsersForDeletion[0]),
      );
      setSelectedUser(foundUser);
      return;
    }

    if (selectedKeys !== "all" && selectedKeys.size === 1) {
      const foundUser = users.find(
        (user) => user.id === Number(selectedKeys.values().next().value),
      );
      setSelectedUser(foundUser);
      return;
    }
  }, [users, activeUsersForDeletion, selectedKeys]);

  return {
    activeUser,
    activeUsersForDeletion,
    currentUser,
    dateFormatter,
    deleteModalState,
    filters,
    getUserActions,
    handleOpenRightPanel,
    handleUserDelete,
    isCurrentUserReadOnly,
    isLoading,
    onMenuAction,
    onRowAction,
    selectedKeys,
    selectedUser,
    setFilters,
    setSelectedKeys,
    t,
    tableActions,
    total,
    users,
  };
}

export default useUsersTab;
