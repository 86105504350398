import clsx from "clsx";
import React from "react";

/**
 * Component that provides a layout for security policy permissions form with specific styling.
 *
 * @param {Object} props - The properties passed to this component.
 * @param {React.ReactNode} props.children - The content or components to be rendered within the layout.
 * @return {JSX.Element} A styled container wrapping the provided children components.
 */
function SecurityPolicyPermissionsFormLayout({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <div className="flex w-full flex-col bg-white">{children}</div>;
}

/**
 * Generates a layout description for a security policy form.
 * Wraps the children nodes within a styled label element.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The content to be rendered within the label container.
 * @return {JSX.Element} The layout description component as a styled label with the specified children.
 */
function SecurityPolicyFormLayoutDescription({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <label className="pt-4 text-extra-dark-gray">{children}</label>;
}

SecurityPolicyFormLayoutDescription.displayName =
  "SecurityPolicyFormLayoutDescription";

/**
 * A functional component that displays an error message in a styled paragraph layout,
 * commonly used for rendering errors in a security policy form.
 *
 * @param {object} props - The properties object.
 * @param {React.ReactNode} props.children - The content or error message to be displayed inside the component.
 * @return {JSX.Element} A JSX element that renders the error message with specific styling.
 */
function SecurityPolicyFormLayoutError({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <p className="p-1 text-xs text-red">{children}</p>;
}

SecurityPolicyFormLayoutError.displayName = "SecurityPolicyFormLayoutError";

function SecurityPolicyFormLayoutSection({
  title,
  subtitle,
  hasDivider,
  children,
}: {
  title?: string;
  subtitle?: string;
  hasDivider?: boolean;
  children: React.ReactNode;
}) {
  return (
    <div
      className={clsx(
        "flex flex-col py-6",
        hasDivider && "border-gray-200 border-b",
      )}
    >
      <div className="flex flex-row items-center justify-between">
        <div>
          {title && <h4 className="py-3 text-lg text-dark-blue">{title}</h4>}
          {subtitle && (
            <p className="text-sm text-extra-dark-gray">{subtitle}</p>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 gap-2">{children}</div>
    </div>
  );
}

SecurityPolicyFormLayoutSection.displayName = "SecurityPolicyFormLayoutSection";

SecurityPolicyPermissionsFormLayout.Description =
  SecurityPolicyFormLayoutDescription;
SecurityPolicyPermissionsFormLayout.Section = SecurityPolicyFormLayoutSection;
SecurityPolicyPermissionsFormLayout.Error = SecurityPolicyFormLayoutError;

export default SecurityPolicyPermissionsFormLayout;
