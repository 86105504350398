import clsx from "clsx";
import React, { useRef } from "react";
import type { AriaTextFieldProps } from "react-aria";
import { useTextField } from "react-aria";

interface TextAreaProps extends AriaTextFieldProps {
  required?: boolean;
  onFocusSelectText?: boolean;
}

function TextArea(props: TextAreaProps) {
  const { label, value, onChange } = props;
  const ref = useRef<HTMLTextAreaElement>(null);
  const { labelProps, inputProps } = useTextField<"textarea">(
    {
      ...props,
      inputElementType: "textarea",
      onChange,
      value,
    } as AriaTextFieldProps<HTMLTextAreaElement>,
    ref,
  );

  /**
   * Causes the specified input element to receive focus.
   *
   * @param {React.MouseEvent<HTMLLabelElement>} event - The mouse event that triggered the function.
   * @returns {void}
   */
  const focusInput = (event: React.MouseEvent<HTMLLabelElement>): void => {
    event.preventDefault();
    ref.current?.focus();
  };

  /**
   * Function to handle focus events on an input element.
   * If `onFocusSelectText` prop is true, selects the text in the input element using a reference
   *
   * @function handleFocus
   * @param {Object} props - The properties object.
   * @param {boolean} props.onFocusSelectText - Whether to select the text on focus or not.
   * @param {React.RefObject} ref - React ref object reference to the input element.
   * @returns {void}
   */
  const handleFocus = (): void => {
    if (props.onFocusSelectText) {
      ref.current?.select();
    }
  };

  return (
    <div
      className={clsx(
        "relative w-full",
        props.isReadOnly && "bg-light-gray",
        (props.isDisabled || props.isReadOnly) &&
          "pointer-events-none opacity-50",
      )}
    >
      <textarea
        className="peer h-full w-full rounded-[6px] border border-gray bg-transparent px-3 pb-3 pt-4 font-sans text-sm font-normal text-extra-dark-gray outline outline-0 transition-all duration-100 ease-in-out placeholder-shown:border focus:outline-0 disabled:pointer-events-none disabled:cursor-not-allowed"
        required={props.required}
        {...(inputProps as Record<string, unknown>)}
        placeholder={" "}
        maxLength={props.maxLength || 250}
        ref={ref}
        onFocus={handleFocus}
      />
      {label && (
        <label
          {...labelProps}
          htmlFor={props.id}
          onClick={focusInput}
          className="peer-focus:text-blue-600 peer-focus:dark:text-blue-500 absolute start-3 top-3 z-10 origin-[0] -translate-y-2.5 scale-75 transform text-sm text-dark-gray duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-2.5 peer-focus:scale-75 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4"
        >
          {label}
        </label>
      )}
    </div>
  );
}

export default TextArea;
