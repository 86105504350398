import { Time } from "@internationalized/date";
import React, { useState } from "react";

import { PolicyField } from "../../../utils/policyFields";
import Button from "../../Button";
import { Icon } from "../../Icon";
import { TimeField } from "../../TimeField";

interface SecurityPolicyInputTimespanTupleProps {
  onChange: (form: { [key: string]: any }) => void;
  field: PolicyField;
  value?: Array<{ item1: string; item2: string }>;
  isLoading: boolean;
  isReadOnly?: boolean;
}

export const SecurityPolicyInputTimespanTuple: React.FC<
  SecurityPolicyInputTimespanTupleProps
> = ({ onChange, value = [], field, isLoading, isReadOnly }) => {
  const [inputTuple, setInputTuple] = useState<{
    item1: string | undefined;
    item2: string | undefined;
  }>({ item1: undefined, item2: undefined });
  const [, setLastModifiedItem] = useState<"item1" | "item2">("item1");

  /**
   * Convert a string representation of time to a Time object.
   *
   * @param {string} timeString - The string representation of time in the format "HH:MM:SS".
   * @return {Time|null} - The Time object representing the given time string, or null if the string is invalid.
   */
  function stringToTime(timeString: string): Time | null {
    if (!timeString || !/^(\d{2}):(\d{2}):(\d{2})$/.test(timeString)) {
      return new Time(0, 0, 0);
    }

    const [hour, minute, second] = timeString.split(":").map(Number);

    if (hour < 0 || hour >= 24) {
      return null;
    }

    if (minute < 0 || minute >= 60) {
      return null;
    }

    if (second < 0 || second >= 60) {
      return null;
    }

    return new Time(hour, minute, second);
  }

  /**
   * Updates the input tuple based on the input event.
   *
   * @param {string} e - The input event value.
   * @param {string} item - The tuple item to be updated. It can be either "item1" or "item2".
   * @returns {void}
   */
  const handleInputChange = (e: string, item: "item1" | "item2"): void => {
    setInputTuple({ ...inputTuple, [item]: e });
    setLastModifiedItem(item);
    const oppositeItem = item === "item1" ? "item2" : "item1";
    if (e.trim() !== "" && inputTuple[oppositeItem] !== "") {
      onChange([...value, { ...inputTuple, [item]: e }]);
      setInputTuple({ item1: undefined, item2: undefined });
    }
  };

  /**
   * Handles adding a tuple to the value and resetting the input tuple.
   *
   * @function
   * @name handleAddTuple
   *
   * @returns {void}
   */
  const handleAddTuple = (): void => {
    onChange([...value, inputTuple]);
    setInputTuple({ item1: undefined, item2: undefined });
  };

  /**
   * Updates the value at a specific index and item with a new value.
   *
   * @param {number} index - The index of the tuple in the value array.
   * @param {"item1" | "item2"} item - The item within the tuple.
   * @param {string} newValue - The new value to set.
   * @returns {void}
   */
  const handleUpdateTuple = (
    index: number,
    item: "item1" | "item2",
    newValue: string,
  ): void => {
    const updatedValue = [...value];
    updatedValue[index][item] = String(newValue);
    onChange(updatedValue);
  };

  const handleRemoveTuple = (index: number) => {
    const updatedValue = value.filter((_, idx) => idx !== index);
    onChange(updatedValue);
  };

  /**
   * Handles key press event for input field.
   * @param {React.KeyboardEvent<HTMLInputElement>} event - The keyboard event object.
   * @returns {void}
   */
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (inputTuple.item1 && inputTuple.item2) {
        handleAddTuple();
      }
    }
  };

  return (
    <div className="flex w-full flex-col gap-2">
      {Array.isArray(value) &&
        value.map((tuple, index) => (
          <div key={index} className="flex flex-row items-center gap-2">
            <TimeField
              isReadOnly={isReadOnly}
              isDisabled={isLoading}
              aria-label={field.firstFieldPlaceholder}
              id={`${field.id}-item1-${index}`}
              label={field.firstFieldPlaceholder}
              value={stringToTime(tuple.item1)}
              onChange={(e) => {
                handleUpdateTuple(
                  index,
                  "item1",
                  e ? new Time(e.hour, e.minute, e.second).toString() : "",
                );
              }}
            />
            <TimeField
              isReadOnly={isReadOnly}
              isDisabled={isLoading}
              id={`${field.id}-item2-${index}`}
              aria-label={field.secondFieldPlaceholder}
              label={field.secondFieldPlaceholder}
              value={stringToTime(tuple.item2)}
              onChange={(e) => {
                handleUpdateTuple(
                  index,
                  "item2",
                  e ? new Time(e.hour, e.minute, e.second).toString() : "",
                );
              }}
            />
            <div>
              <Button
                noPadding
                type="button"
                variant="text"
                aria-label="Remove tuple"
                isDisabled={isLoading || isReadOnly}
                onPress={() => handleRemoveTuple(index)}
              >
                <Icon name="RemoveIcon" className="h-6 w-6 text-red" />
              </Button>
            </div>
          </div>
        ))}

      <div className="flex flex-row items-center gap-2">
        <TimeField
          isReadOnly={isReadOnly}
          onKeyDown={handleKeyPress}
          isDisabled={isLoading}
          aria-label={field.firstFieldPlaceholder}
          id={`${field.id}-item1-new`}
          label={field.firstFieldPlaceholder}
          value={inputTuple?.item1 ? stringToTime(inputTuple.item1) : undefined}
          onChange={(e) => {
            handleInputChange(
              e ? new Time(e.hour, e.minute, e.second).toString() : "",
              "item1",
            );
          }}
        />
        <TimeField
          isReadOnly={isReadOnly}
          onKeyDown={handleKeyPress}
          isDisabled={isLoading}
          id={`${field.id}-item2-new`}
          aria-label={field.secondFieldPlaceholder}
          label={field.secondFieldPlaceholder}
          value={inputTuple?.item2 ? stringToTime(inputTuple.item2) : undefined}
          onChange={(e) =>
            handleInputChange(
              e ? new Time(e.hour, e.minute, e.second).toString() : "",
              "item2",
            )
          }
        />
        <div>
          <Button
            noPadding
            type="button"
            variant="text"
            isDisabled={isLoading || isReadOnly}
            onPress={handleAddTuple}
            aria-label="Add tuple"
          >
            <Icon name="AddIcon" className="h-6 w-6 text-dark-blue" />
          </Button>
        </div>
      </div>
    </div>
  );
};
