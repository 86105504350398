import { useCallback, useEffect, useRef, useState } from "react";

import { InfoService } from "../services/infoService";
import { getStorageItem, storeStorageItem } from "../utils/storage";

interface ServerDataProps {
  forceFetchSettings?: boolean;
}

const useServerData = ({
  forceFetchSettings = false,
}: ServerDataProps = {}) => {
  const infoServiceRef = useRef(new InfoService());
  const [settings, setSettings] = useState<any>(() => {
    return getStorageItem("sessionStorage", "serverSettingsData");
  });

  const [hasFetched, setHasFetched] = useState(false);

  const fetchSettings = useCallback(() => {
    infoServiceRef.current
      .getSettings()
      .then((response) => {
        if (response) {
          setSettings(response);
          storeStorageItem(
            "sessionStorage",
            "serverSettingsData",
            JSON.stringify(response),
          );
          setHasFetched(true);
        }
      })
      .catch((error) => {
        console.error("Error during settings fetch: ", error);
      });
  }, []);

  useEffect(() => {
    if (!hasFetched || forceFetchSettings) {
      fetchSettings();
    }
  }, [fetchSettings, forceFetchSettings, hasFetched]);

  return { settings };
};

export default useServerData;
