import { SortDescriptor } from "@react-types/shared";
import qs from "qs";
import {
  Key,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import type { Selection } from "react-stately";
import { useOverlayTriggerState } from "react-stately";

import SecurityPolicyDetail from "../components/policy/SecurityPolicyDetail";
import { TableAction } from "../components/TableActionsBar";
import { useAuth } from "../contexts/AuthContext";
import { useDialogContext } from "../contexts/DialogContext";
import { useNavigationDirty } from "../contexts/NavigationDirtyContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { usePendingComputerUpdate } from "../contexts/PendingComputerUpdateContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import Computer from "../interfaces/Computer";
import ComputerGroup from "../interfaces/ComputerGroup";
import Policy from "../interfaces/Policy";
import PolicySeverity from "../interfaces/PolicySeverity";
import { SecurityPolicyService } from "../services/securityPolicyService";
import { policies } from "../utils/policies";
import { transformObjectArraysToArrayOfIds } from "../utils/utils";

import { useNotifications } from "./useNotifications";
import useTooltip from "./useTooltip";

const securityPoliceService = new SecurityPolicyService();

type PolicyAction = {
  key: string;
  label: string;
};

interface FilterState {
  computerID?: Computer[];
  groupComputerID?: ComputerGroup[];
  search?: string;
  sort?: SortDescriptor;
  severity?: PolicySeverity[];
}

function usePoliciesTab() {
  const { openRightPanel, setRightPanelContent, closeRightPanel } = useContext(
    RightPanelContext,
  ) as RightPanelContextProps;
  const navigate = useNavigate();
  const location = useLocation();
  const dialog = useDialogContext();
  const { t } = useTranslation();
  const { createNotification } = useNotifications();
  const { confirmDirtyNavigation, setDirty } = useNavigationDirty();
  const { showTooltip, hideTooltip } = useTooltip();
  const { isCurrentUserReadOnly } = useAuth();

  const initialFilters: FilterState = (() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const filters: Partial<FilterState> = {
      computerID: [],
      groupComputerID: [],
      search: undefined,
      severity: [],
    };

    if (queryParams.computerID) {
      filters.computerID = Array.isArray(queryParams.computerID)
        ? queryParams.computerID.map((identifier) => ({
            id: Number(identifier),
          }))
        : [{ id: Number(queryParams.computerID) }];
    }

    if (queryParams.groupComputerID) {
      filters.groupComputerID = Array.isArray(queryParams.groupComputerID)
        ? queryParams.groupComputerID.map((identifier) => ({
            id: Number(identifier),
          }))
        : [{ id: Number(queryParams.groupComputerID) }];
    }

    return filters as FilterState;
  })();

  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([]));
  const [selectedPolicy, setSelectedPolicy] = useState<Policy | undefined>(
    undefined,
  ); // Used for display the name of the item in the dialogs ( Only one )
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<FilterState>(initialFilters);
  const [statePolicies, setStatePolicies] = useState(policies);
  const [error, setError] = useState<string>("");
  const [activePoliciesForDeletion, setActivePoliciesForDeletion] = useState<
    Key[]
  >([]);
  const deleteModalState = useOverlayTriggerState({});
  const { resetPendingUpdatesTimeout } = usePendingComputerUpdate();

  /**
   * @desc Fetch security policies
   */
  const getSecurityPolicies = useCallback(async () => {
    setIsLoading(true);
    const transformedFilters = transformObjectArraysToArrayOfIds(filters);
    securityPoliceService
      .getSecurityPolicies(
        qs.stringify(transformedFilters, {
          filter: (prefix, value) => (value === "" ? undefined : value),
          skipNulls: true,
        }),
      )
      .then((res) => {
        const updatedPolicies = [...statePolicies];

        updatedPolicies.forEach((policy) => {
          let foundElements: any[] = [];

          for (const key in res) {
            if (
              Object.prototype.hasOwnProperty.call(res, key) &&
              Array.isArray(res[key])
            ) {
              foundElements = foundElements.concat(
                res[key].filter((item: any) => item.type === policy.type),
              );
            }
          }

          if (foundElements.length > 0) {
            policy.securityPolicies = foundElements;
          } else {
            policy.securityPolicies = [];
          }
        });

        const updatedClonedPolicies = structuredClone(updatedPolicies);

        setStatePolicies(updatedClonedPolicies);
      })
      .catch((err) => {
        createNotification(
          t("errors.genericFetchError", {
            resource: t("common.policies"),
          }),
          NotificationTypes.DANGER,
        );
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  /**
   * Handles the action of adding a policy.
   * This function opens the right panel and sets the content to a SecurityPolicyDetail component.
   *
   * @function handleAddPolicy
   * @returns {void}
   */
  const handleAddPolicy = async (): Promise<void> => {
    const confirm = await confirmDirtyNavigation();
    if (!confirm) {
      return;
    }
    openRightPanel();
    setRightPanelContent(
      <SecurityPolicyDetail
        key={Math.random()}
        onCancel={() => {
          closeRightPanel();
        }}
        onSaved={() => {
          getSecurityPolicies().then(() => {
            closeRightPanel();
          });
        }}
      />,
    );
  };

  /**
   * Handles the deletion of policies.
   *
   * @async
   * @function handlePolicyDelete
   * @returns {Promise<void>} A promise that resolves when the policies have been deleted.
   */
  const handlePolicyDelete = (): void => {
    securityPoliceService
      .deleteSecurityPolicy(qs.stringify({ id: activePoliciesForDeletion }))
      .then(() => {
        setStatePolicies(policies);
        setSelectedKeys(new Set([]));
        setActivePoliciesForDeletion([]);
        resetPendingUpdatesTimeout();
        deleteModalState.close();
        return getSecurityPolicies();
      })
      .catch(() => {
        createNotification(
          t("errors.genericDeleteError", {
            resource: t("common.policies"),
          }),
          NotificationTypes.DANGER,
        );
      });
  };

  /**
   * Sets the active user based on the provided key and performs several actions:
   * - Opens the right panel
   * - Sets the content of the right panel to display the details of the user
   * - Provides options to cancel and save changes
   * - Refreshes the user list after changes are saved
   *
   * @param {Key} key - The key of the user to perform actions on
   */
  const onRowAction = async (key: Key) => {
    const confirm = await confirmDirtyNavigation();
    if (confirm) {
      setDirty(false);
      navigate(`/policies/${key}${location.search}`);
    }
  };

  const findPolicyByType = (id: string | number): Policy | undefined => {
    return statePolicies.find((p) => p.type === parseInt(id as string));
  };

  /**
   * Filtered policies based on search filters.
   *
   * @type {Policy[]}
   */
  const filteredPolicies: Policy[] = useMemo(() => {
    let terms: string[] = [];

    if (typeof filters.search === "string") {
      terms = filters.search.split(/\s+/).filter(Boolean);
    }

    return statePolicies.filter((policy) => {
      if (policy.securityPolicies.length <= 0) {
        return false;
      }

      if (terms.length) {
        const termMatched = terms.every((term) => {
          return (
            policy.name.toLowerCase().includes(term.toLowerCase()) ||
            policy.description.toLowerCase().includes(term.toLowerCase())
          );
        });

        if (!termMatched) {
          return false;
        }
      }

      return true;
    });
  }, [statePolicies, filters]);

  /**
   * @desc Table actions
   */
  const tableActions: TableAction[] = useMemo(() => {
    if (isCurrentUserReadOnly) {
      return [];
    }
    const baseAction: TableAction = {
      icon: "AddIcon",
      label: t("common.addPolicy"),
      onClick: () => {
        handleAddPolicy().then(() => {});
      },
    };

    /**
     * @desc Additional actions
     */
    const additionalActions: TableAction[] = [
      {
        icon: "DeleteIcon",
        label: t("common.delete"),
        onClick: () => {
          deleteModalState.open();
          const ids: number[] = [];
          if (selectedKeys instanceof Set) {
            selectedKeys.forEach((key) => {
              const policy = findPolicyByType(key);
              const securityPolicyRules = policy?.securityPolicies.map(
                (p) => p.id,
              );
              if (securityPolicyRules) {
                ids.push(...securityPolicyRules);
              }
            });
          } else {
            //Get all policies ids
            statePolicies.forEach((policy) => {
              const securityPolicyRules = policy.securityPolicies.map(
                (p) => p.id,
              );
              if (securityPolicyRules) {
                ids.push(...securityPolicyRules);
              }
            });
          }
          setActivePoliciesForDeletion(ids);
        },
      },
    ];

    if (selectedKeys === "all" || selectedKeys.size > 0) {
      return additionalActions;
    } else {
      return [baseAction];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closeRightPanel,
    dialog,
    getSecurityPolicies,
    openRightPanel,
    selectedKeys,
    setRightPanelContent,
    statePolicies,
  ]);

  /**
   * @desc Menu action
   * @param id
   * @param key
   */
  const onMenuAction = (id: string | number, key: Key) => {
    const foundPolicy = findPolicyByType(id);
    switch (key) {
      case "show":
        onRowAction(id).then(() => {});
        break;
      case "suspendPolicies":
        dialog
          .open(
            t("dialog.suspendPolicy"),
            t("dialog.suspendPolicyMessage", {
              resourceName: foundPolicy?.name,
            }),
          )
          .then(() => {
            const ids: number[] = [];
            const securityPolicyRules = foundPolicy?.securityPolicies.map(
              (p) => p.id,
            );
            if (securityPolicyRules) {
              ids.push(...securityPolicyRules);
            }

            securityPoliceService
              .changeSecurityPolicyStatus(
                qs.stringify({ enable: false, id: ids }),
              )
              .then(() => {
                getSecurityPolicies().then(() => {
                  resetPendingUpdatesTimeout();
                });
              });
          })
          .catch(() => {});
        break;
      case "enablePolicies":
        dialog
          .open(
            t("dialog.enablePolicy"),
            t("dialog.enablePolicyMessage", {
              count:
                selectedKeys === "all"
                  ? filteredPolicies.length
                  : Array.from(selectedKeys).length,
              resourceName: foundPolicy?.name,
            }),
          )
          .then(() => {
            const ids: number[] = [];
            const securityPolicyRules = foundPolicy?.securityPolicies.map(
              (p) => p.id,
            );
            if (securityPolicyRules) {
              ids.push(...securityPolicyRules);
            }

            securityPoliceService
              .changeSecurityPolicyStatus(
                qs.stringify({ enable: true, id: ids }),
              )
              .then(() => {
                getSecurityPolicies().then(() => {
                  resetPendingUpdatesTimeout();
                });
              });
          })
          .catch(() => {});
        break;
      case "delete":
        {
          const ids: number[] = [];
          const policy = statePolicies.find(
            (p) => p.type === parseInt(id as string),
          );
          const securityPolicyRules = policy?.securityPolicies.map((p) => p.id);
          if (securityPolicyRules) {
            ids.push(...securityPolicyRules);
          }

          deleteModalState.open();
          setActivePoliciesForDeletion(ids);
        }
        break;
      default:
        break;
    }
  };

  /**
   * Retrieves the user actions based on the user ID.
   *
   * @param {number} id - The ID of the user.
   * @returns {UserAction[]} - An array of user actions.
   */
  const getPolicyActions = (id: number): PolicyAction[] => {
    const defaultPolicyActions: PolicyAction[] = [
      { key: "show", label: t("common.show") },
    ];

    // Find policy by id
    const foundPolicy = policies.find((policy) => policy.id === id);

    if (foundPolicy?.securityPolicies.some((policy) => policy.enable)) {
      defaultPolicyActions.push({
        key: "suspendPolicies",
        label: t("common.suspendPolicies"),
      });
    }

    if (foundPolicy?.securityPolicies.some((policy) => !policy.enable)) {
      defaultPolicyActions.push({
        key: "enablePolicies",
        label: t("common.enablePolicies"),
      });
    }
    defaultPolicyActions.push({ key: "delete", label: t("common.delete") });
    return defaultPolicyActions;
  };

  /**
   * Reset the advanced filters.
   *
   * If either `filters.computerID` or `filters.groupComputerID` is not empty,
   * this function will reset them to empty arrays.
   *
   * @function handleResetAdvancedFilters
   * @returns {void}
   */
  const handleResetAdvancedFilters = (): void => {
    if (
      (filters?.computerID && filters.computerID.length > 0) ||
      (filters?.groupComputerID && filters.groupComputerID.length > 0)
    ) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        computerID: [],
        groupComputerID: [],
      }));
    }
  };

  /**
   * @desc Get security policies
   */
  useEffect(() => {
    getSecurityPolicies().then(() => {});
  }, [getSecurityPolicies]);

  /**
   * Calculates the count of applied filters.
   *
   * @function
   * @returns {number} The count of applied filters.
   */
  const appliedFiltersCount = useMemo(() => {
    let count = 0;
    Object.entries(filters).forEach(([, value]) => {
      if (Array.isArray(value)) {
        count += value.length;
      }
    });
    return count;
  }, [filters]);

  /**
   * @desc Update the URL with the current filters
   */
  useEffect(() => {
    const cleanedFilters = Object.entries(filters).reduce(
      (acc: { [key: string]: number[] }, [key, value]) => {
        if (
          ["computerID", "groupComputerID"].includes(key) &&
          Array.isArray(value) &&
          value.length !== 0
        ) {
          acc[key] = value.map((item) => item.id);
        }
        return acc;
      },
      {} as { [key: string]: number[] },
    );

    const queryStringifies = qs.stringify(cleanedFilters);

    navigate(`${location.pathname}?${queryStringifies}`, { replace: true });
  }, [filters, navigate, location.pathname]);

  useEffect(() => {
    if (activePoliciesForDeletion.length === 1) {
      const foundPolicy = policies.find((policy) =>
        policy.securityPolicies.some(
          (secPolicy) => secPolicy.id === Number(activePoliciesForDeletion[0]),
        ),
      );
      setSelectedPolicy(foundPolicy);
      return;
    }

    if (selectedKeys !== "all" && selectedKeys.size === 1) {
      const foundPolicy = policies.find((policy) =>
        policy.securityPolicies.some(
          (secPolicy) => secPolicy.id === Number(activePoliciesForDeletion[0]),
        ),
      );
      setSelectedPolicy(foundPolicy);
      return;
    }
  }, [activePoliciesForDeletion, selectedKeys]);
  return {
    activePoliciesForDeletion,
    appliedFiltersCount,
    closeRightPanel,
    deleteModalState,
    error,
    filters,
    getPolicyActions,
    getSecurityPolicies,
    handleAddPolicy,
    handlePolicyDelete,
    handleResetAdvancedFilters,
    hideTooltip,
    isCurrentUserReadOnly,
    isLoading,
    onMenuAction,
    onRowAction,
    openRightPanel,
    policies: filteredPolicies,
    selectedKeys,
    selectedPolicy,
    setFilters,
    setRightPanelContent,
    setSelectedKeys,
    showTooltip,
    t,
    tableActions,
  };
}

export default usePoliciesTab;
