import clsx from "clsx";
import React from "react";
import { Item } from "react-stately";

import { PolicyTypeEnum } from "../../enum/PolicyTypeEnum";
import useSecurityPolicyDetail from "../../hooks/useSecurityPolicyDetail";
import { policies } from "../../utils/policies";
import { Accordion, AccordionItem } from "../Accordion";
import Button from "../Button";
import Checkbox from "../Checkbox";
import { ComboBox } from "../ComboBox";
import { ComputerMultiSelect } from "../common/ComputerMultiSelect";
import { UserMultiSelect } from "../common/UserMultiSelect";
import { Icon } from "../Icon";
import NumberField from "../NumberField";
import TextArea from "../TextArea";
import TextField from "../TextField";

import { SecurityPolicyInputs } from "./fields/SecurityPolicyInputs";
import SecurityPolicyPermissionsForms from "./fields/SecurityPolicyPermissionsForms";

interface PolicyDetailProps {
  id?: number;
  onSaved: () => void;
  onCancel: () => void;
  selectedPolicyTypeEnum?: PolicyTypeEnum;
}

const SecurityPolicyDetail: React.FC<PolicyDetailProps> = (
  props: PolicyDetailProps,
) => {
  const {
    isEditing,
    isLoading,
    onSubmit,
    form,
    policy,
    handlePermissionsEdit,
    handleCancel,
    handleInputChange,
    permissionErrors,
    t,
    permissionDisabledFields,
    isCurrentUserReadOnly,
    formRef,
    formErrors,
    filteredSeverities,
    invalidFields,
    handleInputAction,
    permissionLoadingFields,
    permissionHiddenFields,
    handlePermissionsFormChange,
  } = useSecurityPolicyDetail({
    id: Number(props.id),
    onCancel: props.onCancel,
    onSaved: props.onSaved,
    selectedPolicyTypeEnum: props.selectedPolicyTypeEnum,
  });

  const [
    isSecurityPolicyPermissionsFormInvalid,
    setIsSecurityPolicyPermissionsFormInvalid,
  ] = React.useState(false);
  return (
    <form
      ref={formRef}
      onSubmit={onSubmit}
      className="flex h-full flex-col justify-between space-y-4"
    >
      <div className="flex flex-grow flex-col space-y-4 divide-y divide-medium-gray">
        <div className="flex flex-row items-start justify-start gap-4 px-8 py-4">
          <div className="flex w-full flex-col space-y-4">
            <div className="flex flex-row items-center justify-start gap-4">
              <Icon
                name="PoliciesMenuIcon"
                className="h-8 w-8 text-dark-blue"
              />
              <span className="text-xl font-medium text-dark-blue">
                {isEditing ? t("common.editPolicy") : t("common.addPolicy")}
              </span>
            </div>
            <div className="grid w-full grid-cols-1 gap-3">
              <p className="text-lg text-dark-blue">
                {t("common.typeOfPolicy")}
              </p>

              {isEditing ||
                typeof props.selectedPolicyTypeEnum !== "undefined" ? (
                <div
                  className={clsx(
                    "flex flex-row items-center gap-2 pb-2",
                    !policy && "blur-sm",
                  )}
                >
                  <Icon
                    name={policy ? policy.icon : "PoliciesMenuIcon"}
                    className="h-5 w-5 text-medium-pale-blue"
                  />
                  <p className="font-semibold text-extra-dark-gray">
                    {policy ? policy.name : "N/D"}
                  </p>
                </div>
              ) : (
                <ComboBox
                  isReadOnly={isCurrentUserReadOnly}
                  id="policies"
                  disabledKeys={policies
                    .filter((i) => i.isDisabled)
                    .map((item) => item.type)}
                  selectedKey={String(form.type)}
                  aria-label={t("common.selectTypeOfPolicy")}
                  label={t("common.typeOfPolicy")}
                  onSelectionChange={(value) => {
                    let typeValue: number | null;

                    if (typeof value === "string") {
                      typeValue = parseInt(value, 10);
                    } else {
                      typeValue = value;
                    }

                    // Find policy by value
                    const foundPolicy = policies.find(
                      (item) => item.type === typeValue,
                    );

                    if (foundPolicy && foundPolicy.isDisabled) {
                      return;
                    }

                    handleInputChange("type", typeValue);
                  }}
                >
                  {policies
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => (
                      <Item key={item.type} aria-label={item.name}>
                        <div
                          className={clsx(
                            "flex flex-row items-center justify-start gap-4",
                            item.isDisabled &&
                            "pointer-events-none opacity-50 hover:bg-transparent",
                          )}
                        >
                          <Icon
                            name={item.icon}
                            className="h-6 w-6 text-medium-pale-blue"
                          />
                          <p className="text-extra-dark-gray">{item.name}</p>
                        </div>
                      </Item>
                    ))}
                </ComboBox>
              )}
              <TextField
                name="name"
                type="text"
                label={t("common.name")}
                value={form.name}
                isRequired
                required
                isInvalid={formErrors && !!formErrors.name}
                isReadOnly={isCurrentUserReadOnly}
                isDisabled={isLoading}
                onChange={(e) => handleInputChange("name", e)}
              />
              {formErrors && formErrors.name && form.type !== null && (
                <p className="px-2 text-xs text-red">{formErrors.name}</p>
              )}
              <TextArea
                name="description"
                type="text"
                isReadOnly={isCurrentUserReadOnly}
                label={t("common.description")}
                value={form.description}
                isDisabled={isLoading}
                onChange={(e) => handleInputChange("description", e)}
              />
            </div>
          </div>
        </div>
        <Accordion initialOpenIndices={[0]}>
          <AccordionItem title="Policy settings">
            {typeof policy?.type !== "undefined" ? (
              <div className="grid grid-cols-1 gap-6">
                {policy.description && (
                  <label className="text-extra-dark-gray">
                    {policy.description}
                  </label>
                )}
                <SecurityPolicyPermissionsForms
                  id={props.id}
                  policyType={policy.type}
                  onChange={handlePermissionsFormChange}
                  value={form.permissions}
                  isLoading={isLoading}
                  isInvalid={(isInvalid: boolean) => {
                    setIsSecurityPolicyPermissionsFormInvalid(isInvalid);
                  }}
                />
                {/*<pre>{JSON.stringify(form.permissions, null, 2)}</pre>*/}
                {/*<pre>{JSON.stringify(permissionHiddenFields, null, 2)}</pre>*/}
                {/*Old version of permissions form*/}
                <SecurityPolicyInputs
                  onChange={handlePermissionsEdit}
                  initialValue={form?.permissions}
                  policyType={policy.type}
                  isLoading={isLoading}
                  errors={permissionErrors}
                  disabledFieldIds={permissionDisabledFields}
                  hiddenFieldIds={permissionHiddenFields}
                  loadingFieldIds={permissionLoadingFields}
                  isReadOnly={isCurrentUserReadOnly}
                  invalidFields={invalidFields}
                  onInputAction={handleInputAction}
                />
                <div className="flex flex-col gap-2">
                  <label className="text-lg text-dark-blue">
                    {t("common.levelOfPriority")}
                  </label>
                  <NumberField
                    isReadOnly={isCurrentUserReadOnly}
                    isDisabled={isLoading}
                    id="priority"
                    placeholder="Enter level of priority"
                    label="Priority"
                    value={form.priority || 1}
                    isRequired
                    required
                    onChange={(e) => handleInputChange("priority", e)}
                  />
                </div>
                {policy.hasNotification && (
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                      <label className="text-lg text-dark-blue">
                        {t("common.notification")}
                      </label>
                      <Checkbox
                        isReadOnly={isCurrentUserReadOnly}
                        isDisabled={
                          isLoading ||
                          form?.permissions?.mode?.toString() === "1" ||
                          permissionDisabledFields.includes("notification")
                        }
                        isSelected={form.permissions.notification}
                        onChange={(e) =>
                          handlePermissionsEdit("notification", e)
                        }
                      >
                        <span className="text-extra-dark-gray">
                          {t("common.notifyBreach")}
                        </span>
                      </Checkbox>
                    </div>
                    <ComboBox
                      isReadOnly={isCurrentUserReadOnly}
                      isRequired={form.permissions.notification}
                      id="severity"
                      selectedKey={String(form.severity)}
                      isDisabled={
                        isLoading ||
                        !form.permissions.notification ||
                        form?.permissions?.mode?.toString() === "1"
                      }
                      aria-label={t("common.severity")}
                      label={t("common.severity")}
                      onSelectionChange={(value) => {
                        handleInputChange("severity", Number(value));
                      }}
                    >
                      {filteredSeverities.map((item) => (
                        <Item key={item.id} aria-label={String(item.name)}>
                          <span className={clsx(item.color)}>{item.name}</span>
                        </Item>
                      ))}
                    </ComboBox>
                    {formErrors && formErrors.severity && (
                      <p className="px-2 text-xs text-red">
                        {formErrors.severity}
                      </p>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <span>{t("common.selectTypeOfPolicy")}</span>
            )}
          </AccordionItem>
          {policy?.hasUsers && (
            <AccordionItem
              title={`${t("common.users")} (${form.users.length})`}
            >
              <UserMultiSelect
                id="users"
                isReadOnly={isCurrentUserReadOnly}
                selectedObjects={form.users}
                disabled={isLoading}
                onChange={(e) => {
                  handleInputChange("users", e);
                }}
                onRemove={(e) => {
                  handleInputChange(
                    "users",
                    form.users?.filter((item) => item.id !== e.id),
                  );
                }}
              />
            </AccordionItem>
          )}
          {policy?.hasComputers && (
            <AccordionItem
              title={`${t("common.computers")} (${form.computers.length + form.computerGroups.length
                })`}
            >
              <ComputerMultiSelect
                id="computers"
                disabled={isLoading}
                isReadOnly={isCurrentUserReadOnly}
                selectedComputers={form.computers}
                selectedGroups={form.computerGroups}
                onComputerChange={(e) => {
                  handleInputChange("computers", e);
                }}
                onGroupChange={(e) => {
                  handleInputChange("computerGroups", e);
                }}
                onComputerRemove={(e) => {
                  handleInputChange(
                    "computers",
                    form.computers?.filter((item) => item.id !== e.id),
                  );
                }}
                onGroupRemove={(e) => {
                  handleInputChange(
                    "computerGroups",
                    form.computerGroups?.filter((item) => item.id !== e.id),
                  );
                }}
              />
            </AccordionItem>
          )}
        </Accordion>
      </div>
      <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2">
        <div>
          <Button
            onPress={handleCancel}
            type="button"
            isDisabled={isLoading}
            variant="text"
          >
            {t("common.cancel")}
          </Button>
        </div>
        <div>
          <Button
            type="submit"
            isDisabled={
              !policy ||
              Object.keys(permissionErrors).length > 0 ||
              Object.keys(formErrors).length > 0 ||
              invalidFields.length > 0 ||
              isCurrentUserReadOnly ||
              isSecurityPolicyPermissionsFormInvalid
            }
            isLoading={isLoading}
            variant="contained"
          >
            {t("common.save")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SecurityPolicyDetail;
