import clsx from "clsx";
import React from "react";
import { CSSTransition } from "react-transition-group";

import { Icon } from "./Icon";

export type TreeItem = {
  key: string;
  title: string;
  children?: TreeItem[];
  isLoading?: boolean;
  isOpen: boolean;
  isClickable?: boolean;
  showChildren?: boolean;
  data?: { [key: string]: any };
};

interface TreeNodeProps {
  node: TreeItem;
  onNodeClick: (item: TreeItem) => void;
  isLoading?: boolean;
}

interface TreeProps {
  items: TreeItem[];
  onNodeClick?: (item: TreeItem) => void;
  isLoading?: boolean;
  isKeyLoading?: (key: string) => boolean;
}

const TreeNode: React.FC<TreeNodeProps> = ({
  node,
  onNodeClick,
  isLoading,
}) => {
  const hasChildren = node.children && node.children.length > 0;

  /**
   * Handles the click event.
   * If the node has children and is not in a loading state,
   * it toggles the node by invoking the toggleNode function
   * with the key of the clicked node.
   *
   * @function handleClick
   * @returns {void}
   */
  const handleNodeClick = (): void => {
    if (!isLoading) {
      onNodeClick(node);
    }
  };
  return (
    <div className="ml-4">
      <div
        className={clsx(
          "flex cursor-pointer items-center gap-2 rounded-md py-1 transition-all duration-200 ease-in-out hover:underline",
        )}
        onClick={handleNodeClick}
      >
        {hasChildren &&
          (node.showChildren !== undefined ? node.showChildren : true) && (
            <Icon
              name="ArrowRightIcon"
              className={clsx(
                "h-4 w-4 transition-all duration-200 ease-in-out",
                node.isOpen && "rotate-90",
                (isLoading || node.isLoading) && "animate-pulse opacity-80",
              )}
            />
          )}
        <div
          className={clsx(
            "h-1 w-1 rounded-full",
            node?.data?.isNew ? "bg-dark-blue" : "bg-transparent",
          )}
        />
        <span
          className={clsx(
            "truncate",
            (isLoading || node.isLoading) &&
              "pointer-events-none animate-pulse text-extra-dark-gray opacity-80",
            node.isOpen && "font-bold underline",
            node?.data?.isNew && "font-black",
          )}
        >
          {node.title}
        </span>
        {node?.data?.value?.virusFound > 0 && (
          <div className="flex flex-row items-center gap-1 pr-2">
            <Icon name="BugIcon" className="h-4 w-4 text-dark-red" />
            <div className="flex h-4 min-w-4 items-center justify-center rounded-full bg-dark-red px-1 text-xs text-white no-underline decoration-0">
              {node?.data?.value?.virusFound > 99
                ? "99+"
                : node?.data?.value?.virusFound}
            </div>
          </div>
        )}
      </div>
      {(node.showChildren !== undefined ? node.showChildren : true) && (
        <CSSTransition
          in={node.isOpen}
          unmountOnExit
          timeout={{ enter: 0, exit: 0 }}
          classNames={{
            enter: "opacity-0 -translate-y-2",
            enterDone:
              "opacity-1 translate-y-0 backdrop-blur-sm transition ease-in",
            exit: "opacity-0 -translate-y-2 backdrop-blur-none transition ease-out",
          }}
        >
          <div className="overflow-hidden">
            <div className="pl-4">
              {node.children?.map((child) => (
                <TreeNode
                  key={child.key}
                  node={child}
                  onNodeClick={onNodeClick}
                  isLoading={isLoading}
                />
              ))}
            </div>
          </div>
        </CSSTransition>
      )}
    </div>
  );
};

const Tree: React.FC<TreeProps> = ({ items, onNodeClick, isLoading }) => {
  /**
   * Handles toggling of a node based on its key.
   *
   * @param node
   */
  const handleNodeClick = (node: TreeItem) => {
    if (onNodeClick) {
      onNodeClick(node);
    }
  };

  return (
    <div>
      {isLoading && items.length === 0
        ? Array.from({ length: 10 }, (_, index) => (
            <div
              key={index}
              className="flex w-full animate-pulse items-center justify-between gap-2 px-2 py-2"
            >
              <div className="h-4 w-4 rounded-md bg-medium-gray"></div>
              <div className="h-4 w-full rounded-md bg-medium-gray"></div>
            </div>
          ))
        : items.map((item) => (
            <TreeNode
              key={item.key}
              node={item}
              onNodeClick={handleNodeClick}
              isLoading={item.isLoading || isLoading}
            />
          ))}
    </div>
  );
};

export default Tree;
