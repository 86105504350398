import React from "react";

import { PolicyTypeEnum } from "../../../enum/PolicyTypeEnum";
import SystemEncryptionPolicyPermissionsForm from "../permissions-forms/SystemEncryptionPolicyPermissionsForm";

interface Props {
  id?: number;
  policyType: PolicyTypeEnum;
  onChange: (payload: { [key: string]: any }) => void;
  value: { [key: string]: any };
  isLoading: boolean;
  isReadOnly?: boolean;
  isInvalid: (isInvalid: boolean) => void;
}

function SecurityPolicyPermissionsForm(props: Props) {
  return (
    <>
      {(() => {
        switch (props.policyType) {
          case PolicyTypeEnum.SystemEncryption:
            return (
              <SystemEncryptionPolicyPermissionsForm
                id={props.id}
                onChange={(payload) => props.onChange(payload)}
                value={props.value}
                isLoading={props.isLoading}
                isReadOnly={props.isReadOnly}
                isInvalid={props.isInvalid}
              />
            );

          default:
            return null;
        }
      })()}
    </>
  );
}

export default SecurityPolicyPermissionsForm;
