import React, { useMemo, useState } from "react";

import MultiSelectComboBox, {
  MultiselectComboboxOption,
} from "../../MultiSelectComboBox";

interface SecurityPolicyInputMultiSelectProps {
  id: string;
  onChange: (newValue: any[]) => void;
  value?: MultiselectComboboxOption[];
  isLoading: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  label?: string;
  placeholder?: string;
  options?: MultiselectComboboxOption[];
}

export const SecurityPolicyInputMultiSelect: React.FC<
  SecurityPolicyInputMultiSelectProps
> = ({
  id,
  onChange,
  value = [],
  isLoading,
  isDisabled,
  isReadOnly,
  label,
  placeholder,
  options,
}) => {
  const [query, setQuery] = useState("");

  /**
   * @description Represents the filtered options based on the provided query.
   * @typedef {Array<Object>} filteredOptions
   * @property {string} value - The value of the option.
   * @property {string} label - The label of the option.
   *
   * @param {Object} field - The field object containing options.
   * @param {string} query - The query string to filter options.
   * @returns {filteredOptions} - The filtered options based on the query.
   */
  const filteredOptions = useMemo(() => {
    const regex = new RegExp(query.toLowerCase());
    return (
      options?.filter((option) => regex.test(option?.value?.toLowerCase())) ||
      []
    );
  }, [options, query]);

  return (
    <MultiSelectComboBox
      isReadOnly={isReadOnly}
      id={id}
      label={label}
      useDebounceForSearch={false}
      isAllSelectable
      disabled={isLoading || isDisabled}
      isLoading={isLoading}
      aria-label={label}
      placeholder={placeholder}
      selectedObjects={value || []}
      onSelectionObjectChange={(selectedObjects) => {
        onChange(selectedObjects);
      }}
      items={filteredOptions}
      onSearch={setQuery}
    />
  );
};
