import React, { useEffect, useRef, useState } from "react";

import { PolicyField } from "../../../utils/policyFields";
import Button from "../../Button";
import { Icon } from "../../Icon";
import NumberField from "../../NumberField";

interface SecurityPolicyInputNumberTupleProps {
  onChange: (form: { [key: string]: any }) => void;
  field: PolicyField;
  value?: Array<{ item1: string; item2: string }>;
  isLoading: boolean;
  isReadOnly?: boolean;
}

export const SecurityPolicyInputNumberTuple: React.FC<
  SecurityPolicyInputNumberTupleProps
> = ({ onChange, value = [], field, isLoading, isReadOnly }) => {
  const [inputTuple, setInputTuple] = useState<{
    item1: number;
    item2: number;
  }>({ item1: 0, item2: 0 });

  const [lastModifiedItem, setLastModifiedItem] = useState<"item1" | "item2">(
    "item1",
  );

  const item1Ref = useRef<HTMLInputElement>(null);
  const item2Ref = useRef<HTMLInputElement>(null);
  const newItem1Ref = useRef<HTMLInputElement>(null);

  /**
   * Updates the input tuple based on the input event.
   *
   * @param {string} e - The input event value.
   * @param {string} item - The tuple item to be updated. It can be either "item1" or "item2".
   * @returns {void}
   */
  const handleInputChange = (e: number, item: "item1" | "item2"): void => {
    setInputTuple({ ...inputTuple, [item]: e });
    setLastModifiedItem(item);
    const oppositeItem = item === "item1" ? "item2" : "item1";
    if (e && inputTuple[oppositeItem]) {
      onChange([...value, { ...inputTuple, [item]: e }]);
      setInputTuple({ item1: 0, item2: 0 });
    }
  };

  /**
   * Function to handle adding a tuple to a value array and resetting inputTuple.
   *
   * @function handleAddTuple
   * @returns {void}
   */
  const handleAddTuple = (): void => {
    onChange([...value, inputTuple]);
    setInputTuple({ item1: 0, item2: 0 });
  };

  /**
   * Updates the value of an item in a tuple at a specified index.
   *
   * @param {number} index - The index of the tuple to be updated.
   * @param {("item1" | "item2")} item - The item to be updated in the tuple.
   * @param {number} newValue - The new value to set for the item.
   * @returns {void}
   */
  const handleUpdateTuple = (
    index: number,
    item: "item1" | "item2",
    newValue: number,
  ): void => {
    const updatedValue = [...value];
    updatedValue[index][item] = String(newValue);
    onChange(updatedValue);
  };

  /**
   * Removes a tuple from an array of values.
   *
   * @param {number} index - The index of the tuple to remove.
   * @returns {void}
   */
  const handleRemoveTuple = (index: number): void => {
    const updatedValue = value.filter((_, idx) => idx !== index);
    onChange(updatedValue);
  };

  /**
   * Handles key press event for input field.
   * @param {React.KeyboardEvent<HTMLInputElement>} event - The keyboard event object.
   * @returns {void}
   */
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (inputTuple.item1 && inputTuple.item2) {
        handleAddTuple();
      }
    }
  };

  useEffect(() => {
    if (value.length > 0) {
      if (lastModifiedItem === "item1" && item1Ref.current) {
        item1Ref.current.focus();
      } else if (lastModifiedItem === "item2" && item2Ref.current) {
        item2Ref.current.focus();
      }
    }
  }, [value.length, lastModifiedItem]);

  return (
    <div className="flex w-full flex-col gap-2">
      {Array.isArray(value) &&
        value.map((tuple, index) => (
          <div key={index} className="flex flex-row items-center gap-2">
            <NumberField
              isReadOnly={isReadOnly}
              isDisabled={isLoading}
              aria-label={field.firstFieldPlaceholder}
              id={`${field.id}-item1-${index}`}
              minValue={field.minValue || 0}
              maxValue={field.maxValue || 100}
              placeholder={field.firstFieldPlaceholder}
              label={field.firstFieldPlaceholder}
              value={parseInt(tuple.item1)}
              onChange={(e) => handleUpdateTuple(index, "item1", e)}
              ref={index === value.length - 1 ? item1Ref : null}
              formatOptions={{
                maximumFractionDigits: 0,
                useGrouping: false,
              }}
            />
            <NumberField
              isReadOnly={isReadOnly}
              isDisabled={isLoading}
              id={`${field.id}-item2-${index}`}
              minValue={field.minValue || 0}
              maxValue={field.maxValue || 100}
              aria-label={field.secondFieldPlaceholder}
              placeholder={field.secondFieldPlaceholder}
              label={field.secondFieldPlaceholder}
              value={parseInt(tuple.item2)}
              onChange={(e) => handleUpdateTuple(index, "item2", e)}
              ref={index === value.length - 1 ? item2Ref : null}
              formatOptions={{
                maximumFractionDigits: 0,
                useGrouping: false,
              }}
            />
            <div>
              <Button
                noPadding
                type="button"
                variant="text"
                aria-label="Remove tuple"
                isDisabled={isLoading || isReadOnly}
                onPress={() => handleRemoveTuple(index)}
              >
                <Icon name="RemoveIcon" className="h-6 w-6 text-red" />
              </Button>
            </div>
          </div>
        ))}
      <div className="flex flex-row items-center gap-2">
        <NumberField
          ref={newItem1Ref}
          isDisabled={isLoading}
          onInput={(e) =>
            handleInputChange(parseInt(e.currentTarget.value), "item1")
          }
          isReadOnly={isReadOnly}
          aria-label={field.firstFieldPlaceholder}
          id={`${field.id}-item1-new`}
          placeholder={field.firstFieldPlaceholder}
          label={field.firstFieldPlaceholder}
          value={inputTuple.item1}
          onKeyDown={handleKeyPress}
          minValue={field.minValue || 0}
          maxValue={field.maxValue || 100}
          onChange={(e) => handleInputChange(e, "item1")}
          formatOptions={{
            maximumFractionDigits: 0,
            useGrouping: false,
          }}
        />
        <NumberField
          isDisabled={isLoading}
          id={`${field.id}-item2-new`}
          onInput={(e) =>
            handleInputChange(parseInt(e.currentTarget.value), "item2")
          }
          isReadOnly={isReadOnly}
          aria-label={field.secondFieldPlaceholder}
          placeholder={field.secondFieldPlaceholder}
          label={field.secondFieldPlaceholder}
          value={inputTuple.item2}
          onKeyDown={handleKeyPress}
          minValue={field.minValue || 0}
          maxValue={field.maxValue || 100}
          onChange={(e) => handleInputChange(e, "item2")}
          formatOptions={{
            maximumFractionDigits: 0,
            useGrouping: false,
          }}
        />
        <div>
          <Button
            noPadding
            type="button"
            variant="text"
            isDisabled={
              !inputTuple.item1 || !inputTuple.item2 || isLoading || isReadOnly
            }
            onPress={handleAddTuple}
            aria-label="Add tuple"
          >
            <Icon name="AddIcon" className="h-6 w-6 text-dark-blue" />
          </Button>
        </div>
      </div>
    </div>
  );
};
