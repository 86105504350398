import type { AriaMenuProps, MenuTriggerProps } from "@react-types/menu";
import type { Node } from "@react-types/shared";
import clsx from "clsx";
import React, { RefObject } from "react";
import type { AriaButtonProps } from "react-aria";
import {
  mergeProps,
  useButton,
  useFocusRing,
  useMenu,
  useMenuItem,
  useMenuSection,
  useMenuTrigger,
  useSeparator,
} from "react-aria";
import type { TreeState } from "react-stately";
import { useMenuTriggerState, useTreeState } from "react-stately";

import Popover from "./Popover";

interface MenuProps<T extends object> extends AriaMenuProps<T> {
  onClose: () => void;
}

interface MenuSectionProps<T> {
  section: Node<T>;
  state: TreeState<T>;
  onAction: (key: React.Key) => void;
  onClose: () => void;
}

interface MenuButtonProps<T extends object>
  extends AriaMenuProps<T>,
    MenuTriggerProps {
  label: React.ReactNode;
  isDisabled?: boolean;
  className?: string;
}

interface MenuItemProps<T> {
  item: Node<T>;
  state: TreeState<T>;
  onAction: (key: React.Key) => void;
  onClose: () => void;
}

function MenuItem<T>({ item, state, onAction, onClose }: MenuItemProps<T>) {
  // Get props for the menu item element
  const ref = React.useRef<HTMLLIElement>(null);

  const { menuItemProps } = useMenuItem(
    {
      key: item.key,
      onAction,
      onClose,
    },
    state,
    ref,
  );

  // Handle focus events so we can apply highlighted
  // style to the focused menu item
  const isFocused = state.selectionManager.focusedKey === item.key;
  const focus = isFocused ? `bg-light-gray` : "bg-trasparent";
  const isDisabled = state.disabledKeys.has(item.key);

  return (
    <li
      {...menuItemProps}
      ref={ref}
      className={clsx(
        "relative select-none rounded py-2.5 pl-3 pr-9 text-extra-dark-gray outline-none transition-all duration-200",
        [focus],
        isDisabled
          ? "cursor-default opacity-50"
          : "cursor-pointer hover:bg-light-gray",
      )}
    >
      {item.rendered}
    </li>
  );
}

function MenuSection<T>({
  section,
  state,
  onAction,
  onClose,
}: MenuSectionProps<T>) {
  const { itemProps, groupProps } = useMenuSection({
    "aria-label": section["aria-label"],
    heading: section.rendered,
  });

  const { separatorProps } = useSeparator({
    elementType: "li",
  });

  return (
    <>
      {section.key !== state.collection.getFirstKey() && (
        <li {...separatorProps} className="border-t border-gray px-2" />
      )}
      <li {...itemProps}>
        <ul {...groupProps}>
          {[...section.childNodes].map((node) => (
            <MenuItem
              key={node.key}
              item={node}
              state={state}
              onAction={onAction}
              onClose={onClose}
            />
          ))}
        </ul>
      </li>
    </>
  );
}

function Menu<T extends object>(props: MenuProps<T>) {
  // Create state based on the incoming props
  const state = useTreeState(props);

  // Get props for the menu element
  const ref = React.useRef<HTMLUListElement>(null);
  const { menuProps } = useMenu(props, state, ref);

  return (
    <ul
      {...menuProps}
      ref={ref}
      className={clsx(
        "shadow-xs min-w-[200px] rounded-md border-none outline-none",
      )}
    >
      {[...state.collection].map((item) => (
        <MenuSection
          key={item.key}
          section={item}
          state={state}
          onAction={(key: any) => {
            if (props.onAction) props.onAction(key);
          }}
          onClose={props.onClose}
        />
      ))}
    </ul>
  );
}

const Button = React.forwardRef(
  (props: AriaButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { buttonProps, isPressed } = useButton(
      props,
      ref as RefObject<HTMLButtonElement>,
    );
    const { focusProps } = useFocusRing();

    return (
      <button
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        className={clsx(
          "h-full outline-none transition-all duration-100 ease-in-out",
          isPressed && "opacity-50",
        )}
      >
        {props.children}
      </button>
    );
  },
);

Button.displayName = "MenuButton";

export function MenuButton<T extends object>(props: MenuButtonProps<T>) {
  // Create state based on the incoming props
  const state = useMenuTriggerState(props);

  // Get props for the menu trigger and menu elements
  const ref = React.useRef<HTMLButtonElement>(null);
  const { menuTriggerProps, menuProps } = useMenuTrigger<T>({}, state, ref);
  return (
    <div
      className={clsx(
        "relative flex items-center",
        props.isDisabled && "pointer-events-none opacity-50",
        props.className,
      )}
    >
      <Button isDisabled={props.isDisabled} {...menuTriggerProps} ref={ref}>
        {props.label}
      </Button>
      {state.isOpen && (
        <Popover
          state={state}
          triggerRef={ref}
          placement={window.innerHeight < 768 ? "left" : "bottom end"}
        >
          <Menu
            {...menuProps}
            {...props}
            autoFocus={state.focusStrategy || true}
            onClose={() => state.close()}
          />
        </Popover>
      )}
    </div>
  );
}
