import {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../contexts/AuthContext";
import { useNavigationDirty } from "../contexts/NavigationDirtyContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { usePendingComputerUpdate } from "../contexts/PendingComputerUpdateContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import Computer from "../interfaces/Computer";
import ComputerGroup from "../interfaces/ComputerGroup";
import User from "../interfaces/User";
import { UserGroupService } from "../services/userGroupService";

import { useNotifications } from "./useNotifications";

const userGroupService = new UserGroupService();

interface Props {
  id: string | number;
  onSaved: () => void;
  onCancel: () => void;
}

interface FormState {
  name: string;
  description: string;
  users: User[];
  computers: Computer[];
  computerGroups: ComputerGroup[];
}

function useUserGroupDetail({ id, onSaved, onCancel }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isRightPanelOpen } = useContext(
    RightPanelContext,
  ) as RightPanelContextProps;
  const [form, setForm] = useState<FormState>({
    computerGroups: [],
    computers: [],
    description: "",
    name: "",
    users: [],
  });
  const { createNotification } = useNotifications();
  const { t } = useTranslation();
  const { setDirty, confirmDirtyNavigation } = useNavigationDirty();
  const { isCurrentUserReadOnly } = useAuth();
  const { resetPendingUpdatesTimeout } = usePendingComputerUpdate();

  /**
   * Resets the form by setting the form state to an initial empty state.
   *
   * @function resetForm
   * @returns {void}
   */
  const resetForm = (): void => {
    setForm({
      computerGroups: [],
      computers: [],
      description: "",
      name: "",
      users: [],
    });
  };

  /**
   * Function to handle cancellation of a form.
   * It checks if the form is dirty and prompts a confirmation message before cancelling.
   * If the confirmation is approved, it resets the form and executes the provided onCancel callback.
   *
   * @returns {void}
   */
  const handleCancel = async (): Promise<void> => {
    const confirm = await confirmDirtyNavigation();
    if (confirm) {
      resetForm();
      onCancel();
    }
  };

  /**
   * Updates the form field value, based on the field type.
   * @param {string} field - The name of the field to update.
   * @param {any} value - The new value for the field.
   * @returns {void}
   */
  const handleInputChange = (field: string, value: any): void => {
    switch (field) {
      default:
        setForm((prevForm) => ({ ...prevForm, [field]: value }));
        break;
    }
    setDirty(true);
  };

  /**
   * Handles the form submission.
   *
   * @param {FormEvent} e The form submit event.
   * @returns {void}
   */
  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    setIsLoading(true);
    if (id) {
      await userGroupService
        .updateUserGroup(id, { ...form })
        .then(() => {
          createNotification(
            t("notifications.userGroupUpdated"),
            NotificationTypes.SUCCESS,
          );
          setDirty(false);
          resetPendingUpdatesTimeout();
          onSaved();
        })
        .catch(() => {
          createNotification(
            t("errors.genericUpdateError", {
              resource: t("common.userGroup"),
            }),
            NotificationTypes.DANGER,
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await userGroupService
        .createUserGroup({ ...form })
        .then(() => {
          createNotification(
            t("notifications.userGroupCreated"),
            NotificationTypes.SUCCESS,
          );
          setDirty(false);
          resetPendingUpdatesTimeout();
          resetForm();
          onSaved();
        })
        .catch(() => {
          createNotification(
            t("errors.genericCreateError", {
              resource: t("common.userGroup"),
            }),
            NotificationTypes.DANGER,
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  /**
   * @desc Fetches user data from the API
   */
  const getUserGroup = useCallback(async () => {
    setIsLoading(true);
    try {
      const [userGroupRes] = await Promise.all([
        userGroupService.getUserGroup(id),
      ]);
      setForm({
        computerGroups: userGroupRes.computerGroups,
        computers: userGroupRes.computers,
        description: userGroupRes.description,
        name: userGroupRes.name,
        users: userGroupRes.users,
      });
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  /**
   * @desc Fetches user data from the API when the id changes
   */
  useEffect(() => {
    if (id) {
      getUserGroup().then(() => {});
    } else {
      resetForm();
      setIsLoading(false);
    }
  }, [getUserGroup, id]);

  const isEditing = useMemo(() => {
    return !!id;
  }, [id]);

  useEffect(() => {
    if (!isRightPanelOpen) {
      resetForm();
    }
  }, [isRightPanelOpen]);

  return {
    form,
    handleCancel,
    handleInputChange,
    isCurrentUserReadOnly,
    isEditing,
    isLoading,
    onSubmit,
    setForm,
    t,
  };
}

export default useUserGroupDetail;
