import React from "react";
import { Item } from "react-stately";

import { PolicyField } from "../../../utils/policyFields";
import Button from "../../Button";
import { ComboBox } from "../../ComboBox";

interface Props {
  onChange: (newValue: string | undefined) => void;
  value: string;
  field: PolicyField;
  isLoading: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  onAction: () => void;
}

export const SecurityPolicyInputComboBoxButton: React.FC<Props> = ({
  onChange,
  value = "",
  field,
  isLoading,
  isDisabled,
  isReadOnly,
  onAction,
}) => {
  const options = field?.options || [];
  return (
    <div className="flex flex-row items-center gap-2">
      <ComboBox
        isReadOnly={field.readOnly || isReadOnly}
        isLoading={isLoading}
        isDisabled={isDisabled}
        id={field.id}
        selectedKey={value}
        aria-label={field.label}
        label={field.label}
        onSelectionChange={(value) => {
          onChange(value?.toString());
        }}
      >
        {options.map((option) => (
          <Item key={option.id} aria-label={option.value}>
            {option.value}
          </Item>
        ))}
      </ComboBox>
      <div>
        <Button
          onPress={() => onAction()}
          isDisabled={
            isDisabled ||
            value.toString() === "" ||
            value === undefined ||
            value === null
          }
          isLoading={isLoading}
        >
          {field.actionLabel || "Add"}
        </Button>
      </div>
    </div>
  );
};
