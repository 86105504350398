import { SortDescriptor } from "@react-types/shared";
import qs from "qs";
import {
  Key,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDateFormatter } from "react-aria";
import { useTranslation } from "react-i18next";
import type { Selection } from "react-stately";
import { useOverlayTriggerState } from "react-stately";

import { TableAction } from "../components/TableActionsBar";
import UserGroupDetail from "../components/user/UserGroupDetail";
import { useAuth } from "../contexts/AuthContext";
import { useDialogContext } from "../contexts/DialogContext";
import { useNavigationDirty } from "../contexts/NavigationDirtyContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { usePendingComputerUpdate } from "../contexts/PendingComputerUpdateContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import UserGroup from "../interfaces/UserGroup";
import { UserGroupService } from "../services/userGroupService";
import { transformObjectArraysToArrayOfIds } from "../utils/utils";

import { useNotifications } from "./useNotifications";

const userGroupService = new UserGroupService();

interface FilterState {
  count: number;
  offset: number;
  search?: string;
  sort?: SortDescriptor;
}

function useUserGroupsTab() {
  const {
    openRightPanel,
    isRightPanelOpen,
    setRightPanelContent,
    closeRightPanel,
  } = useContext(RightPanelContext) as RightPanelContextProps;
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([]));
  const [selectedUserGroup, setSelectedUsergroup] = useState<
    UserGroup | undefined
  >(undefined); // Used for display the name of the item in the dialogs ( Only one )
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);
  const dialog = useDialogContext();
  const [filters, setFilters] = useState<FilterState>({
    count: 15,
    offset: 0,
    search: undefined,
  });
  const [activeUserGroup, setActiveUserGroup] = useState<Key | null>(null);
  const [activeUserGroupForDeletion, setActiveUserGroupForDeletion] = useState<
    Key[]
  >([]);

  const { t } = useTranslation();
  const { confirmDirtyNavigation } = useNavigationDirty();
  const { createNotification } = useNotifications();
  const { isCurrentUserReadOnly } = useAuth();
  const deleteModalState = useOverlayTriggerState({});
  const { resetPendingUpdatesTimeout } = usePendingComputerUpdate();

  const dateFormatter = useDateFormatter({
    dateStyle: "medium",
    timeStyle: "short",
  });

  /**
   * Function to get user groups
   *
   * @async
   * @function getUserGroups
   * @returns {Promise<void>} - A promise that resolves when user groups are fetched successfully or rejects with an error
   *
   * @throws {Error} - An error if there is an issue with fetching user groups
   *
   * @param {Object} filters - The filters to be applied for fetching user groups
   *
   * @example
   * getUserGroups().then(() => {
   *   // Handle successful fetching of user groups
   * }).catch((err) => {
   *   // Handle error while fetching user groups
   * });
   */
  const getUserGroups = useCallback(async () => {
    setIsLoading(true);

    const transformedFilters = transformObjectArraysToArrayOfIds(filters);
    if (transformedFilters.sort !== undefined) {
      transformedFilters.sortCol = filters.sort?.column;
      transformedFilters.sortDir = filters.sort?.direction;
      delete transformedFilters.sort;
    } else {
      transformedFilters.sortCol = "createdOn";
      transformedFilters.sortDir = "descending";
    }

    userGroupService
      .getUserGroups(qs.stringify(filters))
      .then((res) => {
        const filteredResponse = res.data.filter(
          (group: UserGroup) => group.id >= 0,
        );
        setUserGroups(filteredResponse);
        setTotal(res.total);
      })
      .catch(() => {
        createNotification(
          t("errors.genericFetchError", {
            resource: t("common.userGroups"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [createNotification, filters, t]);

  /**
   * Handles the opening of the right panel.
   * @async
   * @param {number} [userId] - The user ID.
   */
  const handleOpenRightPanel = async (userId?: number) => {
    const confirm = await confirmDirtyNavigation();
    if (!confirm) {
      return;
    }
    openRightPanel();
    setRightPanelContent(
      <UserGroupDetail
        key={Math.random()}
        id={userId}
        onClose={() => {
          closeRightPanel();
        }}
        onSaved={() => {
          getUserGroups().then(() => {
            closeRightPanel();
          });
        }}
      />,
    );
    if (!userId) {
      setActiveUserGroup(null);
    }
  };

  /**
   * Handles the deletion of userGroup(s).
   *
   * @returns {void}
   */
  const handleUserGroupDelete = (): void => {
    userGroupService
      .deleteUserGroups(
        qs.stringify({
          id: activeUserGroupForDeletion.includes("all")
            ? [-1000]
            : activeUserGroupForDeletion,
        }),
      )
      .then(() => {
        closeRightPanel();
        getUserGroups().then(() => {
          deleteModalState.close();
          closeRightPanel();
          setSelectedKeys(new Set([]));
          setActiveUserGroupForDeletion([]);
          resetPendingUpdatesTimeout();
        });
      })
      .catch(() => {
        createNotification(
          t("errors.genericDeleteError", {
            resource: t("common.userGroup"),
          }),
          NotificationTypes.DANGER,
        );
      });
  };

  /**
   * Sets the selected computer using a key.
   *
   * @param {Key} key - The key to search for the selected computer.
   * @returns {void}
   */
  const findUsergroupByKey = (key: Key): UserGroup | undefined => {
    return userGroups.find((userGroup) => userGroup.id === Number(key));
  };

  /**
   * Represents a list of actions for a table.
   * @typedef {Object} TableAction
   * @property {string} icon - The icon name for the action.
   * @property {string} label - The label for the action.
   * @property {function} onClick - The function to be executed when the action is clicked.
   */
  const tableActions: TableAction[] = useMemo(() => {
    if (isCurrentUserReadOnly) {
      return [];
    }

    const baseAction: TableAction = {
      icon: "AddIcon",
      label: t("common.addUserGroup"),
      onClick: () => {
        handleOpenRightPanel().then(() => {});
      },
    };

    const additionalActions: TableAction[] = [
      {
        icon: "DeleteIcon",
        label: t("common.delete"),
        onClick: () => {
          deleteModalState.open();
          setActiveUserGroupForDeletion(
            selectedKeys === "all" ? ["all"] : Array.from(selectedKeys),
          );
        },
      },
    ];

    if (selectedKeys === "all" || selectedKeys.size > 0) {
      return additionalActions;
    } else {
      return [baseAction];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closeRightPanel,
    dialog,
    getUserGroups,
    openRightPanel,
    selectedKeys,
    setRightPanelContent,
    t,
  ]);

  /**
   * Handle menu actions based on the given key.
   *
   * @param {number} id - The ID of the menu item.
   * @param {Key} key - The key of the menu action.
   * @returns {void}
   */
  const onMenuAction = (id: number, key: Key): void => {
    const foundUserGroup = findUsergroupByKey(id);
    switch (key) {
      case "edit":
        handleOpenRightPanel(Number(id)).then(() => {});
        break;
      case "delete":
        dialog
          .open(
            t("dialog.deleteUserGroup"),
            t("dialog.deleteUserGroupMessage", {
              deleteKeyword: "delete",
              resourceName: foundUserGroup?.name,
            }),
            "ErrorIcon",
            "bg-dark-red",
          )
          .then(() => {
            userGroupService
              .deleteUserGroups(qs.stringify({ id }))
              .then(() => {
                getUserGroups().then(() => {
                  resetPendingUpdatesTimeout();
                });
              })
              .catch(() => {
                createNotification(
                  t("errors.genericDeleteError", {
                    resource: t("common.userGroup"),
                  }),
                  NotificationTypes.DANGER,
                );
              });
          })
          .catch(() => {});
        break;
      default:
        break;
    }
  };

  /**
   * Sets the active user group and opens the right panel with the UserGroupDetail component.
   *
   * @param {Key} key - The key of the user group to set as active.
   * @returns {void}
   */
  const onRowAction = (key: Key): void => {
    handleOpenRightPanel(Number(key)).then(() => {
      setActiveUserGroup(key);
    });
  };

  useEffect(() => {
    getUserGroups().then(() => {});
  }, [getUserGroups]);

  useEffect(() => {
    if (!isRightPanelOpen) {
      setActiveUserGroup(null);
    }
  }, [isRightPanelOpen]);

  useEffect(() => {
    if (selectedKeys instanceof Set && selectedKeys.size > 0) {
      closeRightPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys]);

  useEffect(() => {
    if (activeUserGroupForDeletion.length === 1) {
      const foundUserGroup = userGroups.find(
        (userGroup) => userGroup.id === Number(activeUserGroupForDeletion[0]),
      );
      setSelectedUsergroup(foundUserGroup);
      return;
    }

    if (selectedKeys !== "all" && selectedKeys.size === 1) {
      const foundUserGroup = userGroups.find(
        (userGroup) =>
          userGroup.id === Number(selectedKeys.values().next().value),
      );
      setSelectedUsergroup(foundUserGroup);
      return;
    }
  }, [userGroups, activeUserGroupForDeletion, selectedKeys]);

  return {
    activeUserGroup,
    activeUserGroupForDeletion,
    dateFormatter,
    deleteModalState,
    filters,
    handleOpenRightPanel,
    handleUserGroupDelete,
    isCurrentUserReadOnly,
    isLoading,
    onMenuAction,
    onRowAction,
    selectedKeys,
    selectedUserGroup,
    setFilters,
    setSelectedKeys,
    t,
    tableActions,
    total,
    userGroups,
  };
}

export default useUserGroupsTab;
