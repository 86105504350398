import { SortDescriptor } from "@react-types/shared";
import qs from "qs";
import React, {
  Key,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDateFormatter } from "react-aria";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import type { Selection } from "react-stately";
import { useOverlayTriggerState } from "react-stately";

import SecurityPolicyDetail from "../components/policy/SecurityPolicyDetail";
import { TableAction } from "../components/TableActionsBar";
import { useAuth } from "../contexts/AuthContext";
import { useDialogContext } from "../contexts/DialogContext";
import { useNavigationDirty } from "../contexts/NavigationDirtyContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { usePendingComputerUpdate } from "../contexts/PendingComputerUpdateContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import Computer from "../interfaces/Computer";
import ComputerGroup from "../interfaces/ComputerGroup";
import PolicySeverity from "../interfaces/PolicySeverity";
import SecurityPolicy from "../interfaces/SecurityPolicy";
import { SecurityPolicyService } from "../services/securityPolicyService";
import { transformObjectArraysToArrayOfIds } from "../utils/utils";

import { useNotifications } from "./useNotifications";
import useTooltip from "./useTooltip";

const securityPoliceService = new SecurityPolicyService();

interface Props {
  id: string | undefined;
}

interface FilterState {
  computerID?: Computer[];
  groupComputerID?: ComputerGroup[];
  search?: string;
  sort?: SortDescriptor;
  severity: PolicySeverity[];
}

function usePolicyTab({ id }: Props) {
  const {
    openRightPanel,
    isRightPanelOpen,
    setRightPanelContent,
    closeRightPanel,
  } = useContext(RightPanelContext) as RightPanelContextProps;

  const navigate = useNavigate();
  const location = useLocation();
  const dialog = useDialogContext();
  const { t } = useTranslation();
  const { createNotification } = useNotifications();
  const { confirmDirtyNavigation } = useNavigationDirty();
  const deleteModalState = useOverlayTriggerState({});
  const { showTooltip, hideTooltip } = useTooltip();
  const { isCurrentUserReadOnly } = useAuth();
  const { resetPendingUpdatesTimeout } = usePendingComputerUpdate();

  const initialFilters: FilterState = (() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const filters: Partial<FilterState> = {
      computerID: [],
      groupComputerID: [],
      search: undefined,
      severity: [],
    };

    if (queryParams.computerID) {
      filters.computerID = Array.isArray(queryParams.computerID)
        ? queryParams.computerID.map((identifier) => ({
            id: Number(identifier),
          }))
        : [{ id: Number(queryParams.computerID) }];
    }

    if (queryParams.groupComputerID) {
      filters.groupComputerID = Array.isArray(queryParams.groupComputerID)
        ? queryParams.groupComputerID.map((identifier) => ({
            id: Number(identifier),
          }))
        : [{ id: Number(queryParams.groupComputerID) }];
    }

    return filters as FilterState;
  })();

  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([]));
  const [selectedSecurityPolicy, setSelectedSecurityPolicy] = useState<
    SecurityPolicy | undefined
  >(undefined); // Used for display the name of the item in the dialogs ( Only one )
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<FilterState>(initialFilters);
  const [securityPolicies, setSecurityPolicies] = useState<SecurityPolicy[]>(
    [],
  );
  const [activeSecurityPolicy, setActiveSecurityPolicy] = useState<Key | null>(
    null,
  );
  const [activePoliciesForDeletion, setActivePoliciesForDeletion] = useState<
    Key[]
  >([]);

  const dateFormatter = useDateFormatter({
    dateStyle: "medium",
    timeStyle: "short",
  });

  /**
   * A function that retrieves security policies from a service.
   *
   * @returns {Promise<void>} A promise that resolves when the security policies are fetched and processed.
   * @throws {Error} If an error occurs while fetching or processing the security policies.
   */
  const getSecurityPolicies = useCallback(async () => {
    setIsLoading(true);
    const transformedFilters = transformObjectArraysToArrayOfIds(filters);
    if (transformedFilters.sort !== undefined) {
      transformedFilters.sortCol = filters.sort?.column;
      transformedFilters.sortDir = filters.sort?.direction;
      delete transformedFilters.sort;
    } else {
      transformedFilters.sortCol = "createdOn";
      transformedFilters.sortDir = "descending";
    }

    securityPoliceService
      .getSecurityPolicies(
        qs.stringify({ policyType: id, ...transformedFilters }),
      )
      .then((res) => {
        if (Object.keys(res).length === 0) {
          setSecurityPolicies([]);
        } else {
          for (const key in res) {
            if (
              Object.prototype.hasOwnProperty.call(res, key) &&
              Array.isArray(res[key])
            ) {
              setSecurityPolicies(res[key]);
            }
          }
        }
      })
      .catch(() => {
        createNotification(
          t("errors.genericFetchError", {
            resource: t("common.policy"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [createNotification, filters, id, t]);

  /**
   * Handles the opening of the right panel.
   * @async
   * @param selectedPolicyType
   * @param policyId
   */
  const handleOpenRightPanel = async (
    selectedPolicyType?: number,
    policyId?: number,
  ) => {
    const confirm = await confirmDirtyNavigation();
    if (!confirm) {
      return;
    }
    openRightPanel();
    setRightPanelContent(
      <SecurityPolicyDetail
        key={Math.random()}
        id={policyId}
        selectedPolicyTypeEnum={selectedPolicyType}
        onCancel={() => {
          closeRightPanel();
        }}
        onSaved={() => {
          getSecurityPolicies().then(closeRightPanel);
        }}
      />,
    );
    if (!policyId) {
      setActiveSecurityPolicy(null);
    }
  };

  /**
   * Deletes the security policy identified by the activePoliciesForDeletion from the securityPoliceService.
   * If the deletion fails, a danger notification is created indicating the generic delete error for common policies.
   * After successful deletion, updates the security policies, clears the selected keys, active policies for deletion, and closes the delete modal state.
   *
   * @function
   * @name handlePolicyDelete
   */
  const handlePolicyDelete = () => {
    securityPoliceService
      .deleteSecurityPolicy(qs.stringify({ id: activePoliciesForDeletion }))
      .catch(() => {
        createNotification(
          t("errors.genericDeleteError", {
            resource: t("common.policies"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .then(() => {
        getSecurityPolicies().then(() => {
          setSelectedKeys(new Set([]));
          setActivePoliciesForDeletion([]);
          deleteModalState.close();
          if (isRightPanelOpen) {
            closeRightPanel();
          }
          resetPendingUpdatesTimeout();
        });
      });
  };

  /**
   * @desc Update the sort priority of a security policy
   * @param movedId
   * @param prevId
   * @param nextId
   */
  const updateSecurityPolicySortPriority = async (
    movedId: string | number,
    prevId: string | number | null,
    nextId: string | number | null,
  ) => {
    if (movedId) {
      setIsLoading(true);
      await securityPoliceService
        .updateSecurityPolicySortPriority(movedId, prevId, nextId)
        .then(() => {
          createNotification(
            t("common.securityPolicySortPriorityUpdated"),
            NotificationTypes.SUCCESS,
          );
          resetPendingUpdatesTimeout();
        })
        .catch(() => {
          createNotification(
            t("errors.securityPolicySortPriority"),
            NotificationTypes.DANGER,
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  /**
   * Finds a security policy by key.
   *
   * @param {Key} key - The key to search for.
   * @returns {SecurityPolicy | undefined} The found security policy or undefined if not found.
   */
  const findSecurityPolicyByKey = (key: Key): SecurityPolicy | undefined => {
    return securityPolicies.find(
      (securityPolicy) => securityPolicy.id === Number(key),
    );
  };

  /**
   * Holds an array of table actions for a specific table.
   *
   * @type {TableAction[]}
   */
  const tableActions: TableAction[] = useMemo(() => {
    if (isCurrentUserReadOnly) {
      return [];
    }

    const baseAction: TableAction = {
      icon: "AddIcon",
      label: t("common.configurePolicyForComputer"),
      onClick: () => {
        handleOpenRightPanel(parseInt(id as string)).then(() => {});
      },
    };

    const additionalActions: TableAction[] = [];

    if (selectedKeys === "all" || selectedKeys.size > 1) {
      return additionalActions;
    } else {
      return [baseAction];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closeRightPanel,
    dialog,
    getSecurityPolicies,
    id,
    openRightPanel,
    selectedKeys,
    setRightPanelContent,
  ]);

  /**
   * Performs an action on the menu based on the given identifier and key.
   *
   * @param {string | number} identifier - The identifier for the action.
   * @param {Key} key - The key representing the action.
   * @returns {void}
   */
  const onMenuAction = (identifier: string | number, key: Key): void => {
    const foundSecurityPolicy = findSecurityPolicyByKey(identifier);
    switch (key) {
      case "edit":
        openRightPanel();
        setRightPanelContent(
          <SecurityPolicyDetail
            key={Math.random()}
            onCancel={() => closeRightPanel()}
            onSaved={() => {
              getSecurityPolicies().then(() => {});
            }}
            id={Number(identifier)}
          />,
        );
        break;
      case "suspend":
        dialog
          .open(
            t("dialog.suspendPolicy"),
            t("dialog.suspendPolicyMessage", {
              resourceName: foundSecurityPolicy?.name,
            }),
          )
          .then(() => {
            securityPoliceService
              .changeSecurityPolicyStatus(
                qs.stringify({ enable: false, id: identifier }),
              )
              .catch(() => {
                resetPendingUpdatesTimeout();
              })
              .then(() => {
                getSecurityPolicies().then(() => {});
              });
          })
          .catch(() => {});
        break;
      case "enable":
        dialog
          .open(
            t("dialog.enablePolicy"),
            t("dialog.enablePolicyMessage", {
              resourceName: foundSecurityPolicy?.name,
            }),
          )
          .then(() => {
            securityPoliceService
              .changeSecurityPolicyStatus(
                qs.stringify({ enable: true, id: identifier }),
              )
              .catch(() => {})
              .then(() => {
                resetPendingUpdatesTimeout();
                getSecurityPolicies().then(() => {});
              });
          })
          .catch(() => {});
        break;
      case "delete":
        deleteModalState.open();
        setActivePoliciesForDeletion([identifier]);
        break;
      default:
        break;
    }
  };

  /**
   * Moves a row from one index to another in the "prevPolicies" array and returns the updated array.
   *
   * @param {number} fromIndex - The index of the row to move.
   * @param {number} toIndex - The index where to move the row.
   * @returns {Array} The updated "prevPolicies" array with the moved row.
   */
  const onRowMove = (fromIndex: number, toIndex: number): void => {
    setSecurityPolicies((prevPolicies) => {
      const updatedPolicies = [...prevPolicies];
      const draggedPolicy = updatedPolicies[fromIndex];
      // Remove the dragged policy from its original position
      updatedPolicies.splice(fromIndex, 1);

      // Insert the dragged policy in the new position
      updatedPolicies.splice(toIndex, 0, draggedPolicy);

      return updatedPolicies;
    });
  };

  /**
   * Performs an action when a row is dropped.
   *
   * @param {number} fromIndex - The index of the row being moved.
   * @param {number} toIndex - The index where the row should be placed.
   * @returns {void}
   */
  const onRowDrop = (fromIndex: number, toIndex: number): void => {
    const movedElement = securityPolicies[fromIndex];
    const prevElement = securityPolicies[toIndex - 1];
    const nextElement = securityPolicies[toIndex + 1];
    updateSecurityPolicySortPriority(
      movedElement.id,
      prevElement?.id,
      nextElement?.id,
    ).then(() => {
      getSecurityPolicies().then(() => {});
    });
  };

  /**
   * Executes an action when a row is clicked.
   *
   * @param {Key} key - The key of the clicked row.
   * @returns {void}
   */
  const onRowAction = (key: Key): void => {
    setActiveSecurityPolicy(key);
    openRightPanel();
    if (typeof key === "string") {
      setRightPanelContent(
        <SecurityPolicyDetail
          key={Math.random()}
          id={Number(key)}
          onCancel={() => closeRightPanel()}
          onSaved={() => {
            getSecurityPolicies().then(() => {
              closeRightPanel();
            });
          }}
        />,
      );
    }
  };

  /**
   * Reset the advanced filters.
   *
   * If either `filters.computerID` or `filters.groupComputerID` is not empty,
   * this function will reset them to empty arrays.
   *
   * @function handleResetAdvancedFilters
   * @returns {void}
   */
  const handleResetAdvancedFilters = (): void => {
    if (
      (filters?.computerID && filters.computerID.length > 0) ||
      (filters?.groupComputerID && filters.groupComputerID.length > 0)
    ) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        computerID: [],
        groupComputerID: [],
      }));
    }
  };

  useEffect(() => {
    if (id) {
      getSecurityPolicies().then(() => {});
    }
  }, [getSecurityPolicies, id]);

  useEffect(() => {
    if (!isRightPanelOpen) {
      setActiveSecurityPolicy(null);
    }
  }, [isRightPanelOpen]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramsObj: Record<string, string> = {};
    queryParams.forEach((value, key) => {
      paramsObj[key] = value;
    });

    if (paramsObj.id) {
      onRowAction(paramsObj.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   * Calculates the count of applied filters.
   *
   * @function
   * @returns {number} The count of applied filters.
   */
  const appliedFiltersCount = useMemo(() => {
    let count = 0;
    Object.entries(filters).forEach(([, value]) => {
      if (Array.isArray(value)) {
        count += value.length;
      } else if (typeof value === "string" && value.trim() !== "") {
        count += 1;
      }
    });
    return count;
  }, [filters]);

  /**
   * @desc Update the URL with the current filters
   */
  useEffect(() => {
    const cleanedFilters = Object.entries(filters).reduce(
      (acc: { [key: string]: number[] }, [key, value]) => {
        if (
          ["computerID", "groupComputerID"].includes(key) &&
          Array.isArray(value) &&
          value.length !== 0
        ) {
          acc[key] = value.map((item) => item.id);
        }
        return acc;
      },
      {} as { [key: string]: number[] },
    );

    const queryStringifies = qs.stringify(cleanedFilters);

    navigate(`${location.pathname}?${queryStringifies}`, { replace: true });
  }, [filters, navigate, location.pathname]);

  useEffect(() => {
    if (activePoliciesForDeletion.length === 1) {
      const foundSecurityPolicy = securityPolicies.find(
        (securityPolicy) =>
          securityPolicy.id === Number(activePoliciesForDeletion[0]),
      );
      setSelectedSecurityPolicy(foundSecurityPolicy);
      return;
    }

    if (selectedKeys !== "all" && selectedKeys.size === 1) {
      const foundSecurityPolicy = securityPolicies.find(
        (securityPolicy) =>
          securityPolicy.id === Number(selectedKeys.values().next().value),
      );
      setSelectedSecurityPolicy(foundSecurityPolicy);
      return;
    }
  }, [selectedKeys, activePoliciesForDeletion, securityPolicies]);

  return {
    activePoliciesForDeletion,
    activeSecurityPolicy,
    appliedFiltersCount,
    dateFormatter,
    deleteModalState,
    filters,
    handlePolicyDelete,
    handleResetAdvancedFilters,
    hideTooltip,
    isCurrentUserReadOnly,
    isLoading,
    onMenuAction,
    onRowAction,
    onRowDrop,
    onRowMove,
    securityPolicies,
    selectedSecurityPolicy,
    setFilters,
    showTooltip,
    t,
    tableActions,
  };
}

export default usePolicyTab;
