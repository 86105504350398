import React, { useEffect, useRef, useState } from "react";
import { Item } from "react-stately";

import Button from "../../Button";
import { ComboBox } from "../../ComboBox";
import { Icon } from "../../Icon";
import { MultiselectComboboxOption } from "../../MultiSelectComboBox";
import TextField from "../../TextField";

interface Props {
  id: string;
  onChange: (form: { [key: string]: any }) => void;
  initialForm?: { [key: string]: any };
  value?: Array<{ item1: string; item2: string }>;
  isLoading: boolean;
  isReadOnly?: boolean;
  invalidFields: string[];
  isDisabled?: boolean;
  isRequired?: boolean;
  firstFieldPlaceholder?: string;
  secondFieldPlaceholder?: string;
  type?: string;
  title?: string;
  placeholder?: string;
  label?: string;
  options?: MultiselectComboboxOption[];
  isInvalid?: boolean;
}

export const SecurityPolicyInputComboBoxStringTuple: React.FC<Props> = ({
  id,
  onChange,
  value = [],
  isLoading,
  isDisabled,
  isReadOnly,
  invalidFields,
  isRequired,
  firstFieldPlaceholder = "First Field",
  secondFieldPlaceholder = "Second Field",
  type = "text",
  title,
  placeholder,
  label,
  options,
  isInvalid = false,
}) => {
  const [inputTuple, setInputTuple] = useState<{
    item1: string;
    item2: string;
  }>({ item1: "", item2: "" });
  const [lastModifiedItem, setLastModifiedItem] = useState<"item1" | "item2">(
    "item1",
  );
  const item1Ref = useRef<HTMLInputElement>(null);
  const item2Ref = useRef<HTMLInputElement>(null);
  const newItem1Ref = useRef<HTMLInputElement>(null);

  /**
   * Updates the input tuple based on the input event.
   *
   * @param {string} e - The input event value.
   * @param {string} item - The tuple item to be updated. It can be either "item1" or "item2".
   * @returns {void}
   */
  const handleInputChange = (e: string, item: "item1" | "item2"): void => {
    setInputTuple({ ...inputTuple, [item]: e });
    setLastModifiedItem(item);
    const oppositeItem = item === "item1" ? "item2" : "item1";

    if (isRequired) {
      if (e.trim() !== "" && inputTuple[oppositeItem].trim() !== "") {
        onChange([...value, { ...inputTuple, [item]: e }]);
        setInputTuple({ item1: "", item2: "" });
      }
    } else {
      if (item === "item1" && e.trim() !== "") {
        onChange([...value, { ...inputTuple, [item]: e }]);
        setInputTuple({ item1: "", item2: "" });
      }
    }
  };

  /**
   * Handles the event of adding a tuple.
   *
   * @function handleAddTuple
   *
   * @description This function is used to handle the event of adding a tuple.
   * It updates the value state with a new tuple, and resets the inputTuple state.
   *
   * @returns {void}
   */
  const handleAddTuple = (): void => {
    onChange([...value, inputTuple]);
    setInputTuple({ item1: "", item2: "" });
  };

  /**
   * Removes a tuple from an array at a specified index.
   *
   * @param {number} index - The index of the tuple to be removed.
   * @returns {void}
   */
  const handleRemoveTuple = (index: number): void => {
    const updatedValue = value.filter((_, idx) => idx !== index);
    onChange(updatedValue);
    newItem1Ref.current?.focus();
  };

  /**
   * Updates a specific tuple in the value array.
   *
   * @param {number} index - The index of the tuple to update.
   * @param {"item1" | "item2"} item - The specific item in the tuple to update.
   * @param {string} newValue - The new value to set for the specified item in the tuple.
   * @returns {void}
   */
  const handleUpdateTuple = (
    index: number,
    item: "item1" | "item2",
    newValue: string,
  ): void => {
    const updatedValue = [...value];
    updatedValue[index][item] = newValue;
    if (
      newValue.trim() === "" &&
      updatedValue[index][item === "item1" ? "item2" : "item1"].trim() === ""
    ) {
      handleRemoveTuple(index);
    } else {
      onChange(updatedValue);
    }
  };

  /**
   * Handles key press event for input field
   * @param {React.KeyboardEvent<HTMLInputElement>} event - The keyboard event object.
   * @returns {void}
   */
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (inputTuple.item1 && inputTuple.item2) {
        handleAddTuple();
      }
    }
  };

  useEffect(() => {
    if (value.length > 0) {
      if (lastModifiedItem === "item1" && item1Ref.current) {
        item1Ref.current.focus();
      } else if (lastModifiedItem === "item2" && item2Ref.current) {
        item2Ref.current.focus();
      }
    }
  }, [value.length, lastModifiedItem]);

  return (
    <div className="flex w-full flex-col gap-2">
      {Array.isArray(value) &&
        value.map((tuple, index) => (
          <div key={index} className="flex flex-row items-center gap-2">
            {isReadOnly ? (
              <TextField
                isDisabled={isLoading || isDisabled}
                id={`${id}-item1-${index}`}
                name={`${id}-item1`}
                type={type}
                isReadOnly
                placeholder={placeholder}
                label={label}
                isInvalid={isInvalid}
                value={`${tuple.item1} (${tuple.item2})`}
                onChange={(e) => handleUpdateTuple(index, "item1", e)}
              />
            ) : (
              <>
                {options && (
                  <ComboBox
                    isInvalid={isInvalid}
                    isReadOnly={isReadOnly}
                    isDisabled={isLoading || isDisabled}
                    id={`${id}-item1-${index}`}
                    selectedKey={tuple.item1}
                    aria-label={firstFieldPlaceholder}
                    label={firstFieldPlaceholder}
                    onSelectionChange={(value) => {
                      handleUpdateTuple(
                        index,
                        "item1",
                        value ? value?.toString() : "",
                      );
                    }}
                  >
                    {options.map((option) => (
                      <Item key={option.id} aria-label={option.value}>
                        {option.value}
                      </Item>
                    ))}
                  </ComboBox>
                )}
                <TextField
                  isReadOnly={isReadOnly}
                  isDisabled={isLoading || isDisabled}
                  id={`${id}-item2-${index}`}
                  name={`${id}-item2`}
                  type={type}
                  placeholder={secondFieldPlaceholder}
                  label={secondFieldPlaceholder}
                  value={tuple.item2}
                  title={title}
                  isRequired={isRequired}
                  isInvalid={
                    invalidFields?.includes(`${id}-item2-${index}`) || isInvalid
                  }
                  ref={index === value.length - 1 ? item2Ref : null}
                  validationBehavior="native"
                  onChange={(e) => handleUpdateTuple(index, "item2", e)}
                />
              </>
            )}
            <div>
              <Button
                isDisabled={isLoading || isReadOnly || isDisabled}
                noPadding
                type="button"
                variant="text"
                onPress={() => handleRemoveTuple(index)}
              >
                <Icon name="RemoveIcon" className="h-6 w-6 text-red" />
              </Button>
            </div>
          </div>
        ))}
      <div className="flex flex-row items-center gap-2">
        {options && (
          <ComboBox
            isReadOnly={isReadOnly}
            id={`${id}-item1-new`}
            isDisabled={isLoading || isDisabled}
            selectedKey={inputTuple.item1}
            aria-label={firstFieldPlaceholder}
            label={firstFieldPlaceholder}
            onSelectionChange={(value) => {
              handleInputChange(value?.toString() || "", "item1");
            }}
          >
            {options?.map((option) => (
              <Item key={option.id} aria-label={option.value}>
                {option.value}
              </Item>
            ))}
          </ComboBox>
        )}
        <TextField
          id={`${id}-item2-new`}
          name={`${id}-item2`}
          type={type}
          placeholder={secondFieldPlaceholder}
          label={secondFieldPlaceholder}
          value={inputTuple.item2}
          isDisabled={isLoading || isDisabled}
          onKeyDown={handleKeyPress}
          isReadOnly={isReadOnly}
          title={title}
          onChange={(e) => handleInputChange(e, "item2")}
        />
        <div>
          <Button
            noPadding
            type="button"
            variant="text"
            isDisabled={
              !inputTuple.item1 ||
              !inputTuple.item2 ||
              isLoading ||
              isReadOnly ||
              isDisabled
            }
            onPress={handleAddTuple}
          >
            <Icon name="AddIcon" className="h-6 w-6 text-dark-blue" />
          </Button>
        </div>
      </div>
    </div>
  );
};
