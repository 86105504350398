import {
  CalendarDate,
  CalendarDateTime,
  ZonedDateTime,
} from "@internationalized/date";
import {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../contexts/AuthContext";
import { useNavigationDirty } from "../contexts/NavigationDirtyContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { usePendingComputerUpdate } from "../contexts/PendingComputerUpdateContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import Computer from "../interfaces/Computer";
import ComputerGroup from "../interfaces/ComputerGroup";
import { UserAdminEnum } from "../interfaces/User";
import UserGroup from "../interfaces/UserGroup";
import { UserService } from "../services/userService";

import { useNotifications } from "./useNotifications";

const userService = new UserService();

interface Props {
  id: string | number;
  onSaved: () => void;
  onCancel: () => void;
}

interface FormState {
  username: string;
  name: string;
  surname: string;
  email: string;
  expirationDate?: CalendarDate | ZonedDateTime | CalendarDateTime | null;
  expiry?: string | null;
  computers: Computer[];
  computerGroups: ComputerGroup[];
  userGroups: UserGroup[];
  isAdmin: UserAdminEnum;
}

function useUserDetail({ id, onSaved, onCancel }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isRightPanelOpen } = useContext(
    RightPanelContext,
  ) as RightPanelContextProps;

  const [form, setForm] = useState<FormState>({
    computerGroups: [],
    computers: [],
    email: "",
    expirationDate: null,
    expiry: null,
    isAdmin: UserAdminEnum.user,
    name: "",
    surname: "",
    userGroups: [],
    username: "",
  });
  const [currentRole, setCurrentRole] = useState<UserAdminEnum | undefined>();
  const [showChangeRoleMessage, setShowChangeRoleMessage] =
    useState<boolean>(false);

  const { createNotification } = useNotifications();
  const { t } = useTranslation();
  const { setDirty, confirmDirtyNavigation } = useNavigationDirty();
  const { isCurrentUserReadOnly } = useAuth();
  const { resetPendingUpdatesTimeout } = usePendingComputerUpdate();

  /**
   * Resets the form by setting all form fields to their initial values.
   *
   * @function
   * @name resetForm
   * @returns {void}
   */
  const resetForm = (): void => {
    setForm({
      computerGroups: [],
      computers: [],
      email: "",
      expirationDate: null,
      expiry: null,
      isAdmin: UserAdminEnum.user,
      name: "",
      surname: "",
      userGroups: [],
      username: "",
    });
  };

  /**
   * Function to handle cancellation of a form.
   * It checks if the form is dirty and prompts a confirmation message before cancelling.
   * If the confirmation is approved, it resets the form and executes the provided onCancel callback.
   *
   * @returns {void}
   */
  const handleCancel = async (): Promise<void> => {
    const confirm = await confirmDirtyNavigation();
    if (confirm) {
      resetForm();
      onCancel();
    }
  };

  /**
   * Updates the form field value, based on the field type.
   * @param {string} field - The name of the field to update.
   * @param {any} value - The new value for the field.
   * @returns {void}
   */
  const handleInputChange = (field: string, value: any): void => {
    switch (field) {
      case "expirationDate":
        if (value === null) {
          setForm({
            ...form,
            expirationDate: null,
            expiry: null,
          });
          return;
        } else {
          setForm({
            ...form,
            expirationDate: value,
            expiry: new CalendarDate(
              value.year,
              value.month,
              value.day,
            ).toString(),
          });
        }
        return;
      case "isAdmin":
        setForm((prevForm) => ({ ...prevForm, [field]: value }));
        if (
          currentRole === UserAdminEnum.user &&
          value !== UserAdminEnum.user
        ) {
          setShowChangeRoleMessage(true);
        } else if (
          (currentRole === UserAdminEnum.admin ||
            currentRole === UserAdminEnum.readOnly) &&
          value === UserAdminEnum.user
        ) {
          setShowChangeRoleMessage(true);
        } else {
          setShowChangeRoleMessage(false);
        }
        break;
      default:
        setForm((prevForm) => ({ ...prevForm, [field]: value }));
        break;
    }
    setDirty(true);
  };

  /**
   * @desc Handles the form submission
   * @param e
   */
  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (id) {
      await userService
        .updateUser(id, { ...form })
        .then(() => {
          createNotification(
            t("notifications.userUpdated"),
            NotificationTypes.SUCCESS,
          );
          setDirty(false);
          resetPendingUpdatesTimeout();
          if (showChangeRoleMessage) {
            userService
              .sendInvitation(id)
              .then(() => {
                createNotification(
                  t("notifications.userInvited"),
                  NotificationTypes.SUCCESS,
                );
                onSaved();
              })
              .catch(() => {
                createNotification(
                  t("errors.genericError", {
                    resource: t("common.user"),
                  }),
                  NotificationTypes.DANGER,
                );
              });
          }
        })
        .catch(() => {
          createNotification(
            t("errors.genericUpdateError", {
              resource: t("common.user"),
            }),
            NotificationTypes.DANGER,
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await userService
        .createUser({ ...form })
        .then(() => {
          createNotification(
            t("notifications.userCreated"),
            NotificationTypes.SUCCESS,
          );
          setDirty(false);
          resetPendingUpdatesTimeout();
          resetForm();
          onSaved();
        })
        .catch(() => {
          createNotification(
            t("errors.genericError"),
            NotificationTypes.DANGER,
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  /**
   * @desc Fetches user data from the API
   */
  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const [usersRes, computersRes, userGroupsRes] = await Promise.all([
        userService.getUser(id),
        userService.getUserComputers(id),
        userService.getUserGroups(id),
      ]);

      // Parse expiry string to CalendarDate
      let expirationDate = null;
      if (usersRes.expiry) {
        const regex = /(\d{4})-(\d{2})-(\d{2})T/;
        const matches = regex.exec(usersRes.expiry);

        if (matches) {
          // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars
          const [_, year, month, day] = matches;
          expirationDate = new CalendarDate(
            Number(year),
            Number(month),
            Number(day),
          );
        }
      }

      setCurrentRole(usersRes.isAdmin);
      setForm({
        computerGroups: usersRes.computerGroups,
        computers: computersRes,
        email: usersRes.email,
        expirationDate,
        expiry: usersRes.expiry,
        isAdmin: usersRes.isAdmin,
        name: usersRes.name,
        surname: usersRes.surname,
        userGroups: userGroupsRes,
        username: usersRes.username,
      });
    } catch (error) {
      createNotification(
        t("errors.genericFetchError", {
          resource: t("common.user"),
        }),
        NotificationTypes.DANGER,
      );
    } finally {
      setIsLoading(false);
    }
  }, [createNotification, id, t]);

  /**
   * @desc Fetches user data from the API when the id changes
   */
  useEffect(() => {
    if (id) {
      getUser().then(() => {});
    } else {
      resetForm();
      setIsLoading(false);
    }
  }, [getUser, id]);

  /**
   * Determines if the user is currently in an editing state.
   * @constant
   * @type {boolean}
   * @returns {boolean} - True if the user is editing, false otherwise.
   * @param {string} id - The ID of the current item being edited.
   */
  const isEditing: boolean = useMemo(() => {
    return !!id;
  }, [id]);

  /**
   * Resets the form when the right panel is closed.
   */
  useEffect(() => {
    if (!isRightPanelOpen) {
      resetForm();
    }
  }, [isRightPanelOpen]);

  return {
    form,
    handleCancel,
    handleInputChange,
    isCurrentUserReadOnly,
    isEditing,
    isLoading,
    onSubmit,
    setForm,
    showChangeRoleMessage,
    t,
  };
}

export default useUserDetail;
