import { APIService, RequestBody } from "../api/apiService";

export class SecurityPolicyService extends APIService {
  /**
   * @description Fetches all users
   */
  getSecurityPolicy(id: string | number) {
    return this.request(`/Admin/SecurityPolicy/${id}`, "GET");
  }

  /**
   * @description Fetches all users
   */
  getSecurityPolicies(query?: string | null) {
    return this.request(`/Admin/SecurityPolicy?${query}`, "GET");
  }

  /**
   * @description Updates a security policy
   */
  updateSecurityPolicy(id: string | number, payload: RequestBody) {
    return this.request(`/Admin/SecurityPolicy/${id}`, "PUT", payload);
  }

  /**
   * @description Updates a security policy sort priority
   */
  updateSecurityPolicySortPriority(
    id: string | number,
    prevId: string | number | null,
    nextId: string | number | null,
  ) {
    return this.request(`/Admin/SecurityPolicy/${id}/SortPriority`, "PUT", {
      next: nextId,
      prev: prevId,
    });
    0;
  }

  /**
   * Adds a list of virus checking applications to the specified security policy.
   *
   * @param {string|number} id - The unique identifier for the security policy.
   * @param application
   * @return {Promise} A promise that resolves with the server's response to the request.
   */
  addCheckVirus(id: string | number, application: string): Promise<any> {
    return this.request(`/Admin/SecurityPolicy/${id}/AddCheckVirus`, "PUT", {
      application,
    });
  }

  /**
   * @description Created a new security policy
   */
  createSecurityPolicy(payload: RequestBody) {
    return this.request(`/Admin/SecurityPolicy`, "POST", payload);
  }

  /**
   * @description Delete a security policy
   */
  deleteSecurityPolicy(query: string) {
    return this.request(`/Admin/SecurityPolicy?${query}`, "DELETE");
  }

  /**
   * Changes the status of the security policy.
   * @param {string} query - The query string for the request.
   * @return {Promise} - A promise that resolves to the response of the request.
   */
  changeSecurityPolicyStatus(query: string) {
    return this.request(`/Admin/SecurityPolicy/ChangeStatus?${query}`, "PUT");
  }

  /**
   * Authorizes a security policy.
   * @param id
   * @param query
   * Payload example
   *
   * {
   *   "application": string
   *   "applicationDetail": object
   *   "authorizeCertificate": boolean
   * }
   */
  authorizeSecurityPolicy(id: string | number, payload: RequestBody) {
    return this.request(
      `/Admin/SecurityPolicy/${id}/Authorization`,
      "PUT",
      payload,
    );
  }
}
