import React, { useEffect, useMemo, useState } from "react";
import { Item } from "react-stately";

import { PolicyField } from "../../../utils/policyFields";
import { ComboBox } from "../../ComboBox";
import NumberField from "../../NumberField";

interface SecurityPolicyInputTimespanProps {
  onChange: (value: string | null) => void;
  field: PolicyField;
  value?: string;
  isLoading: boolean;
  isReadOnly?: boolean;
}

export const SecurityPolicyInputTimespan: React.FC<
  SecurityPolicyInputTimespanProps
> = ({ onChange, value = "00:00:00", field, isLoading, isReadOnly }) => {
  const [timespanInterval, setTimespanInterval] = useState(0);
  const [timespanUnit, setTimespanUnit] = useState("m");

  /**
   * @desc Convert a value and a unit to a time format
   * @param amount
   * @param unit
   */
  const convertToTimeFormat = (amount: number, unit: string) => {
    if (!amount) return null;
    let totalSeconds;

    switch (unit) {
      case "m":
        totalSeconds = amount * 60;
        break;
      case "h":
        totalSeconds = amount * 3600;
        break;
      default:
        totalSeconds = 0;
    }

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const calcSeconds = totalSeconds - hours * 3600 - minutes * 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${calcSeconds.toString().padStart(2, "0")}`;
  };

  /**
   * Parses an input value and returns an object with calculated unit and interval.
   *
   * @param {string} inputValue - The input value to be parsed (in the format 'hours:minutes').
   * @returns {object} - An object with calculated unit and interval.
   * @throws {Error} - If the inputValue is not in the correct format.
   *
   */
  const parseInputValue = (inputValue: string) => {
    if (!inputValue) return { calcUnit: "m", interval: 0 };

    // Verifica il formato del valore di input con una espressione regolare
    const isValidFormat = /^(\d{2}):(\d{2}):(\d{2})$/.test(inputValue);
    if (!isValidFormat) {
      return { calcUnit: "m", interval: 0 };
    }

    const parts = inputValue.split(":");

    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);

    if (hours > 0) {
      return { calcUnit: "h", interval: hours };
    } else {
      return { calcUnit: "m", interval: minutes };
    }
  };

  /**
   * Creates a formatted time value based on the given timespan interval and unit.
   *
   * @function useMemo
   * @returns {string} The formatted time value.
   * @param {number} timespanInterval - The interval value for the timespan.
   * @param {string} timespanUnit - The unit of measurement for the timespan interval.
   *
   * @example
   * useMemo(() => {
   *     return convertToTimeFormat(10, 'minutes');
   * }, [10, 'minutes']);
   */
  const formattedValue = useMemo(() => {
    return convertToTimeFormat(timespanInterval, timespanUnit);
  }, [timespanInterval, timespanUnit]);

  /**
   * @desc Set the timespan interval and unit based on the input value
   */
  useEffect(() => {
    if (value) {
      const { interval, calcUnit } = parseInputValue(value);
      setTimespanInterval(interval);
      setTimespanUnit(calcUnit);
    }
  }, [value]);

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="col-span-2">
        <NumberField
          isReadOnly={isReadOnly}
          isDisabled={isLoading}
          aria-label={field.label}
          minValue={1}
          maxValue={99}
          id={field.id}
          placeholder={field.placeholder}
          label={field.label}
          value={timespanInterval}
          isRequired={field.required || false}
          required={field.required || false}
          onChange={(e) => {
            setTimespanInterval(e);
            onChange(convertToTimeFormat(e, timespanUnit));
          }}
          formatOptions={{
            maximumFractionDigits: 0,
            useGrouping: false,
          }}
        />
      </div>
      <ComboBox
        isReadOnly={isReadOnly}
        id="timespan"
        isDisabled={isLoading}
        aria-label="Timespan"
        placeholder="Timespan"
        selectedKey={timespanUnit}
        onSelectionChange={(e) => {
          setTimespanUnit(e as string);
          onChange(convertToTimeFormat(timespanInterval, e as string));
        }}
      >
        <Item key="m">Minutes</Item>
        <Item key="h">Hours</Item>
      </ComboBox>
      {formattedValue && (
        <div className="flex items-center justify-start">
          <span>Hours {formattedValue}</span>
        </div>
      )}
    </div>
  );
};
