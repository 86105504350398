import clsx from "clsx";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Alert from "../components/Alert";
import { PendingComputerUpdateAlert } from "../components/common/PendingComputerUpdateAlert";
import Preloader from "../components/common/Preloader";
import Header from "../components/Header";
import BaseMainLayout from "../components/layouts/BaseMainLayout";
import RoutesSidebar from "../components/layouts/RoutesSidebar";
import SettingsMainLayout from "../components/layouts/SettingsMainLayout";
import RightPanel from "../components/RightPanel";
import { useAuth } from "../contexts/AuthContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import { EventBus, EventBusEvents } from "../utils/EventBus";
import { getStorageItem, storeStorageItem } from "../utils/storage";

function MainLayout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    const savedState = getStorageItem("localStorage", "isSidebarOpen");
    return savedState !== null ? !!savedState : false;
  });
  const [isFirstAccess, setIsFirstAccess] = useState(() => {
    const savedState = getStorageItem("sessionStorage", "isFirstAccess");
    return savedState !== null ? !!savedState : true;
  });
  const location = useLocation();
  const [prevPathname, setPrevPathname] = useState(location.pathname);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const {
    rightPanelContent,
    isRightPanelOpen,
    closeRightPanel,
    hasRightPanelCloseButton,
    isRightPanelCheckDirtyOnClose,
  } = useContext(RightPanelContext) as RightPanelContextProps;

  const { isCurrentUserGuest, isCurrentUserReadOnly, checkToken } = useAuth();
  const { t } = useTranslation();

  /**
   * @description Store the sidebar state in the local storage.
   */
  useEffect(() => {
    storeStorageItem("localStorage", "isSidebarOpen", isSidebarOpen);
  }, [isSidebarOpen]);

  /**
   * @description Close the right panel on location change.
   */
  useEffect(() => {
    if (location.pathname !== prevPathname && isRightPanelOpen) {
      closeRightPanel();
    }

    setPrevPathname(location.pathname);
  }, [location.pathname, isRightPanelOpen, closeRightPanel, prevPathname]);

  /**
   * Determines if the current route matches the settings route.
   *
   * @function
   * @returns {boolean} A boolean value indicating if the current route is the settings route.
   */
  const isSettingsRoute = useMemo(() => {
    return location.pathname.startsWith("/settings");
  }, [location.pathname]);

  useEffect(() => {
    const handler = () => {
      setIsFirstAccess(false);
      storeStorageItem("sessionStorage", "isFirstAccess", false);
    };
    EventBus.on(EventBusEvents.API_REQUEST, handler);
    return () => {
      EventBus.off(EventBusEvents.API_REQUEST, handler);
    };
  }, []);

  useEffect(() => {
    if (!isFirstAccess) {
      checkToken();
    }
  }, [isFirstAccess]);

  return (
    <>
      <Preloader isVisible={isFirstAccess} />
      <div className="flex min-h-full w-[100vw] flex-1 overflow-x-hidden">
        {!isCurrentUserGuest && (
          <RoutesSidebar
            isOpen={isSidebarOpen}
            onToggle={() => setIsSidebarOpen(!isSidebarOpen)}
          />
        )}
        <Header isSidebarOpen={isSidebarOpen} />

        <main
          className={clsx(
            "relative min-h-full w-[100vw] flex-1 flex-shrink overflow-x-hidden bg-[#F7F6F6] px-8 pb-8 transition-all duration-300",
            isRightPanelOpen ? "lg:mr-[425px]" : "mr-0",
            isAlertVisible || isCurrentUserReadOnly ? "pt-32" : "pt-24",
            isCurrentUserGuest ? "ml-0" : isSidebarOpen ? "lg:ml-72" : "ml-20",
          )}
        >
          {isCurrentUserReadOnly ? (
            <Alert
              className="absolute left-0 right-0 top-16"
              status={NotificationTypes.INFO}
            >
              <span>{t("common.readOnlyMessage")}</span>
            </Alert>
          ) : (
            <PendingComputerUpdateAlert
              onVisibilityChange={setIsAlertVisible}
            />
          )}
          {isSettingsRoute ? <SettingsMainLayout /> : <BaseMainLayout />}
        </main>

        <RightPanel
          isOpen={isRightPanelOpen}
          onClose={closeRightPanel}
          hasCloseButton={hasRightPanelCloseButton}
          checkDirtyOnClose={isRightPanelCheckDirtyOnClose}
        >
          {rightPanelContent}
        </RightPanel>
      </div>
    </>
  );
}

export default MainLayout;
