import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Item } from "react-stately";

import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import { Icon } from "../components/Icon";
import SegmentedInput from "../components/SegmentedInput";
import { Select } from "../components/Select";
import TextField from "../components/TextField";
import useLogin, { LoginFieldError, Tenant } from "../hooks/useLogin";
import { LoginPayload } from "../interfaces/AuthService";

interface CredentialStepProps {
  form: LoginPayload;
  setForm: React.Dispatch<React.SetStateAction<LoginPayload>>;
  error?: string;
  isLoading?: boolean;
  tenants: Tenant[];
  fieldsError: LoginFieldError[];
}

interface AuthenticatorStepProps {
  form: LoginPayload;
  setForm: React.Dispatch<React.SetStateAction<LoginPayload>>;
  error?: string;
  isLoading?: boolean;
  onBack: () => void;
}

const CredentialStep: React.FC<CredentialStepProps> = ({
  form,
  setForm,
  error,
  isLoading,
  tenants,
  fieldsError,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col gap-4">
      <TextField
        id="username"
        invalid={!!error}
        aria-disabled={isLoading}
        aria-label={t("common.username")}
        isDisabled={isLoading}
        name="username"
        type="text"
        placeholder={t("common.username")}
        value={form.username}
        isRequired
        required
        variant="secondary"
        onChange={(e) => setForm({ ...form, username: e })}
      />
      <TextField
        id="password"
        invalid={!!error}
        aria-disabled={isLoading}
        isDisabled={isLoading}
        aria-label={t("common.password")}
        name="password"
        type="password"
        autoComplete="password"
        placeholder={t("common.password")}
        isRequired
        required
        variant="secondary"
        value={form.password}
        onChange={(e) => setForm({ ...form, password: e })}
      />

      {tenants.length > 0 && (
        <Select
          isInvalid={fieldsError.some((e) => e.field === "license")}
          isDisabled={isLoading}
          isRequired
          variant="secondary"
          selectedKey={String(form.license)}
          aria-label={t("common.selectLicense")}
          placeholder={t("common.selectLicense")}
          onSelectionChange={(value) => {
            setForm({ ...form, license: Number(value) });
          }}
        >
          {tenants.map((tenant) => (
            <Item key={tenant.id} aria-label={String(tenant.license)}>
              {tenant.license}
            </Item>
          ))}
        </Select>
      )}

      <div className="mt-4 flex flex-row items-center justify-between">
        <Checkbox
          isDisabled={isLoading}
          size="large"
          onChange={(e) => setForm({ ...form, rememberMe: e })}
        >
          <span>{t("auth.rememberMe")}</span>
        </Checkbox>
        <Link
          aria-disabled={isLoading}
          to="/forgot-password"
          className={clsx(
            "text-lg text-dark-blue underline",
            isLoading && "pointer-events-none opacity-50",
          )}
        >
          {t("auth.forgotPassword")}
        </Link>
      </div>
      <div className="mt-4 flex h-[24px] flex-row items-center justify-start text-red">
        <Icon
          name="ErrorIcon"
          className={clsx("h-5 w-5", error ? "visible" : "invisible")}
        />
        <span className="ml-2 text-red">{error ? error : ""}</span>
      </div>
      <div className="mt-4 flex w-full flex-row">
        <Button size="large" type="submit" isLoading={isLoading}>
          {t("common.next")}
        </Button>
      </div>
      <span className="w-full text-center text-xs text-extra-dark-gray">
        {t("auth.agreements")}{" "}
        <a
          target="_blank"
          href="https://www.cybergrant.net/terms-of-service"
          rel="noreferrer"
        >
          {t("auth.termsAndConditions")}
        </a>
      </span>
    </div>
  );
};

const AuthenticatorStep: React.FC<AuthenticatorStepProps> = ({
  form,
  setForm,
  error,
  isLoading,
  onBack,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-col gap-4">
      <SegmentedInput
        onCompleted={(value) => setForm({ ...form, otp: value })}
      />
      <span className="text-center text-lg text-dark-gray">
        {t("auth.enterOTPCode")}
      </span>
      <div className="mt-20 flex h-[24px] flex-row items-center justify-start text-red">
        <Icon
          name="ErrorIcon"
          className={clsx("h-5 w-5", error ? "visible" : "invisible")}
        />
        <span className="ml-2 text-red">{error ? error : ""}</span>
      </div>
      <div className="mt-4 flex w-full flex-row">
        <Button
          size="large"
          onPress={onBack}
          variant="text"
          type="button"
          isDisabled={isLoading}
        >
          {t("common.back")}
        </Button>
        <Button
          size="large"
          type="submit"
          isDisabled={!form.otp}
          isLoading={isLoading}
        >
          {t("common.next")}
        </Button>
      </div>
    </div>
  );
};

export const Login: React.FC = () => {
  const {
    form,
    isLoading,
    handleSubmit,
    setForm,
    step,
    setStep,
    apiError,
    formRef,
    tenants,
    fieldsError,
  } = useLogin();

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit}
      className="mt-20 flex h-full w-full flex-col items-start justify-start"
    >
      {step === 1 && (
        <CredentialStep
          error={apiError}
          form={form}
          setForm={setForm}
          isLoading={isLoading}
          tenants={tenants}
          fieldsError={fieldsError}
        />
      )}

      {step === 2 && (
        <AuthenticatorStep
          error={apiError}
          form={form}
          setForm={setForm}
          isLoading={isLoading}
          onBack={() => setStep(1)}
        />
      )}
    </form>
  );
};
