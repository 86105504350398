import { FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";
import { LoginPayload } from "../interfaces/AuthService";
import { UserAdminEnum } from "../interfaces/User";
import { AuthService } from "../services/authService";
import { clearStorage } from "../utils/storage";

const authService = new AuthService();

export interface Tenant {
  id: number;
  license: string;
}

export type LoginFieldError = {
  field: keyof LoginPayload;
  message: string;
};

function useLogin() {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<LoginPayload>({
    license: undefined,
    password: "",
    rememberMe: false,
    username: "",
  });
  const [apiError, setApiError] = useState("");
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [fieldsError, setFieldsError] = useState<LoginFieldError[]>([]);
  //bc7ec1cac58fb3d3c11072ba43b6416332f1261bea96e101179d40dbbf90a980
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslation();
  const { login } = useAuth();

  /**
   * Handle form submission
   * @param e
   */
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setApiError("");

    if (form.password === "" || form.username === "") {
      return;
    }

    // CHeck if there are tenants available and is not selected
    if (tenants.length > 0 && form.license === undefined) {
      setFieldsError([
        {
          field: "license",
          message: t("errors.selectTenant"),
        },
      ]);
      return;
    }

    setFieldsError([]);

    setIsLoading(true);
    authService
      .login(form)
      .then((res) => {
        if (
          res.currentUser.isAdmin === UserAdminEnum.user &&
          !window.location.href.includes(
            import.meta.env.VITE_APP_GUEST_PUBLIC_DOMAIN as string,
          )
        ) {
          clearStorage();
          window.location.href =
            (import.meta.env.VITE_APP_GUEST_PUBLIC_DOMAIN as string) + "/login";
          return;
        }

        clearStorage();
        login(
          form.rememberMe ? "localStorage" : "sessionStorage",
          res.currentUser,
          res.token,
          res.tokenSR,
          res.currentComputer,
          res.currentRole,
          res.currentLicense,
        );

        if (
          window.location.href.includes(
            import.meta.env.VITE_APP_GUEST_PUBLIC_DOMAIN as string,
          )
        ) {
          navigate("/guest-computers");
        } else {
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        // Means that thisa is user is in multiple companies
        if (error.status === 409 && Array.isArray(error.response)) {
          setTenants(error.response);
          return;
        }

        if (error?.response?.errorCode === 12) {
          setForm({ ...form, otp: "" });
          setStep(2);
        }
        if (step === 1) {
          switch (error?.response?.errorCode) {
            case 5:
              setApiError(t("errors.invalidCredentials"));
              break;
            case 10:
              setApiError(t("errors.accountSuspended"));
              break;
            case 11:
              setApiError(t("errors.accountExpired"));
              break;
            case 18:
              setApiError(t("errors.invalidCredentials"));
              break;
            default:
              setApiError(t("errors.licenseExpired"));
              break;
          }
        } else {
          setApiError(t("errors.authenticatorCodeInvalid"));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * @desc Reset error message when step changes
   */
  useEffect(() => {
    setApiError("");
  }, [step]);

  useEffect(() => {
    setApiError("");
  }, [form]);

  return {
    apiError,
    fieldsError,
    form,
    formRef,
    handleSubmit,
    isLoading,
    setForm,
    setStep,
    step,
    tenants,
  };
}

export default useLogin;
