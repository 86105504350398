import qs from "qs";

import { APIService, RequestBody } from "../api/apiService";
import { GetComputerGroupsResponse } from "../interfaces/ComputerGroupService";

export class ComputerGroupService extends APIService {
  /**
   * @description Fetch Computer group by id
   */
  getComputerGroup(id: string | number) {
    return this.request(`/Admin/ComputerGroup/${id}`, "GET");
  }

  /**
   * @description Fetches all Computers groups
   * @param query
   */
  getComputerGroups(query?: string | null): Promise<GetComputerGroupsResponse> {
    return this.request(`/Admin/ComputerGroup?${query}`, "GET");
  }

  /**
   * @description Updates a Computer group
   */
  updateComputerGroup(id: string | number, payload: RequestBody) {
    return this.request(`/Admin/ComputerGroup/${id}`, "PUT", payload);
  }

  /**
   * @description Update computer agent version
   */
  updateAgentVersion(ids: number[], version: string) {
    const queryString = qs.stringify(
      { id: ids, version },
      { arrayFormat: "repeat" },
    );

    return this.request(
      `/Admin/ComputerGroup/CallUpdate?${queryString}`,
      "PUT",
    );
  }

  /**
   * @description Create a Computer group
   */
  createComputerGroup(payload: RequestBody) {
    return this.request(`/Admin/ComputerGroup`, "POST", payload);
  }

  /**
   * @description Delete a Computer group
   */
  deleteComputerGroup(query: string) {
    return this.request(`/Admin/ComputerGroup?${query}`, "DELETE");
  }
}
