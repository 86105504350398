import * as Sentry from "@sentry/react";
import React from "react";
import { I18nProvider } from "react-aria";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import { AuthProvider } from "./contexts/AuthContext";
import { DialogProvider } from "./contexts/DialogContext";
import { NavigationDirtyProvider } from "./contexts/NavigationDirtyContext";
import NotificationProvider from "./contexts/NotificationContext";
import { PendingComputerUpdateProvider } from "./contexts/PendingComputerUpdateContext";
import { RightPanelProvider } from "./contexts/RightPanelContext";
import { SignalRProvider } from "./contexts/SignalRContext";
import { TooltipProvider } from "./contexts/TooltipContext";
import AuthLayout from "./layouts/AuthLayout";
import "./styles/main.css";
import MainLayout from "./layouts/MainLayout";
import { Activities } from "./routes/Activities";
import { Computers } from "./routes/Computers";
import { ConfigurationSettings } from "./routes/ConfigurationSettings";
import { Dashboard } from "./routes/Dashboard";
import { ForgotPassword } from "./routes/ForgotPassword";
import { GuestComputers } from "./routes/GuestComputers";
import { Login } from "./routes/Login";
import { NotificationSettings } from "./routes/NotificationSettings";
import { Policies } from "./routes/Policies";
import { Policy } from "./routes/Policy";
import { ProfileSettings } from "./routes/ProfileSettings";
import { Register } from "./routes/Register";
import { ResetPassword } from "./routes/ResetPassword";
import { SecuritySettings } from "./routes/SecuritySettings";
import { Users } from "./routes/Users";
import "./utils/i18n";

const isLocalhost = window.location.hostname === "localhost";

if (!isLocalhost) {
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        blockAllMedia: false,
        maskAllText: false,
      }),
    ],

    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,

    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,

    //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1,

    // Tracing
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <BrowserRouter>
    <SignalRProvider>
      <I18nProvider locale="en-US">
        <DialogProvider>
          <NavigationDirtyProvider>
            <NotificationProvider>
              <AuthProvider>
                <TooltipProvider>
                  <PendingComputerUpdateProvider>
                    <Routes>
                      <Route element={<AuthLayout />}>
                        <Route path="login" element={<Login />} />
                        <Route
                          path="forgot-password"
                          element={<ForgotPassword />}
                        />
                        <Route
                          path="reset-password"
                          element={<ResetPassword />}
                        />
                        <Route path="register" element={<Register />} />
                      </Route>
                      <Route
                        element={
                          <RightPanelProvider>
                            <MainLayout />
                          </RightPanelProvider>
                        }
                      >
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="computers" element={<Computers />} />
                        <Route
                          path="guest-computers"
                          element={<GuestComputers />}
                        />
                        <Route path="users" element={<Users />} />
                        <Route path="policies" element={<Outlet />}>
                          <Route index element={<Policies />} />
                          <Route path=":id" element={<Policy />} />
                        </Route>
                        <Route path="activities" element={<Activities />} />
                        <Route path="settings" element={<Outlet />}>
                          <Route index element={<ProfileSettings />} />
                          <Route
                            path="security"
                            element={<SecuritySettings />}
                          />
                          <Route
                            path="notifications"
                            element={<NotificationSettings />}
                          />
                          <Route
                            path="configuration"
                            element={<ConfigurationSettings />}
                          />
                        </Route>
                      </Route>
                      <Route
                        path="*"
                        element={<Navigate to="/login" replace />}
                      />
                    </Routes>
                  </PendingComputerUpdateProvider>
                </TooltipProvider>
              </AuthProvider>
            </NotificationProvider>
          </NavigationDirtyProvider>
        </DialogProvider>
      </I18nProvider>
    </SignalRProvider>
    ,
  </BrowserRouter>,
);
