import { getLocalTimeZone, today } from "@internationalized/date";
import clsx from "clsx";
import React, { useRef } from "react";
import type { AriaDatePickerProps } from "react-aria";
import { useDatePicker } from "react-aria";
import { useDatePickerState } from "react-stately";

import Button from "./Button";
import { Calendar } from "./calendar/Calendar";
import { DateField } from "./DateField";
import { Icon } from "./Icon";
import Popover from "./Popover";

interface DatePickerProps extends AriaDatePickerProps<any> {
  label: string;
  isClearable?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const state = useDatePickerState(props);
  const ref = useRef<HTMLDivElement>(null);
  const { groupProps, labelProps, fieldProps, buttonProps, calendarProps } =
    useDatePicker(props, state, ref);

  return (
    <div
      className={clsx(
        "relative inline-flex h-[40px] w-full rounded-md border border-gray bg-white text-left",
        props.isReadOnly && "bg-light-gray",
      )}
    >
      <label
        {...labelProps}
        htmlFor={props.id}
        className="peer-focus:text-blue-600 peer-focus:dark:text-blue-500 absolute start-3 top-3 z-10 origin-[0] -translate-y-2.5 scale-75 transform text-sm text-dark-gray duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-2.5 peer-focus:scale-75 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4"
      >
        {props.label}
      </label>
      <div
        {...groupProps}
        ref={ref}
        aria-disabled={props.isDisabled}
        className={clsx(
          "group flex w-full items-center justify-between",
          props.isDisabled && "cursor-not-allowed bg-light-gray opacity-50",
        )}
      >
        <div className="relative flex w-full items-center pl-1 pt-3">
          <DateField shouldForceLeadingZeros={true} {...fieldProps} />
          {state.validationState === "invalid" && <p>{"!"}</p>}
        </div>
        <div className="flex flex-row items-center gap-2 px-2">
          {props.isClearable && state.value && (
            <Button
              noPadding
              variant="text"
              onPress={() => {
                if (props?.onChange) {
                  props?.onChange(null);
                }
              }}
            >
              <Icon name="CloseIcon" />
            </Button>
          )}
          <Button noPadding variant="text" {...buttonProps}>
            <Icon name="CalendarIcon" />
          </Button>
        </div>
      </div>
      {state.isOpen && (
        <Popover triggerRef={ref} state={state} placement="bottom start">
          <>
            <Calendar
              {...calendarProps}
              minValue={props.minValue || today(getLocalTimeZone())}
            />
          </>
        </Popover>
      )}
    </div>
  );
};

export default DatePicker;
