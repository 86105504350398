import { APIService } from "../api/apiService";
import {
  GetAgentReleasesResponse,
  GetSettingsResponse,
} from "../interfaces/InfoService";

export class InfoService extends APIService {
  /**
   * @description Get settings
   */
  getSettings(): Promise<GetSettingsResponse> {
    return this.request(`/Admin/Info/Settings`, "GET");
  }

  /**
   * @description Get wizard
   */
  getWizard(query: string) {
    return this.request(`/Admin/Info/Wizard?${query}`, "GET");
  }

  /**
   * Fetches the agent release history from the admin information endpoint.
   *
   * @return {Promise} A promise resolving to the response data of the agent release history.
   */
  getAgentReleases(): Promise<GetAgentReleasesResponse> {
    return this.request(`/Admin/Info/AgentHistory`, "GET");
  }
}
