import clsx from "clsx";
import React, { ReactNode } from "react";
import { Trans } from "react-i18next";
import {
  Cell,
  Column,
  Item,
  Row,
  Section,
  TableBody,
  TableHeader,
} from "react-stately";

import { PolicySeverityEnum } from "../../enum/PolicySeverityEnum";
import { UserActivitiesGroupingCriteriaEnum } from "../../enum/UserActivitiesGroupingCriteriaEnum";
import { UserActivityVirusCheckedEnum } from "../../enum/UserActivityVirusCheckedEnum";
import useUserActivitiesTab from "../../hooks/useUserActivitiesTab";
import Computer from "../../interfaces/Computer";
import PolicySeverity from "../../interfaces/PolicySeverity";
import User from "../../interfaces/User";
import UserActivity from "../../interfaces/UserActivity";
import UserActivityType from "../../interfaces/UserActivityType";
import {
  convertDateToTicks,
  convertTicksToCalendarDateTime,
  findPolicyByType,
  findPolicySeverityByType,
  findUserActivityByType,
} from "../../utils/utils";
import Button from "../Button";
import { ComputerMultiSelect } from "../common/ComputerMultiSelect";
import { PolicySeverityMultiselect } from "../common/PolicySeverityMultiSelect";
import { UserActivityTypeMultiselect } from "../common/UserActivityTypeMultiSelect";
import { UserMultiSelect } from "../common/UserMultiSelect";
import DirtyLink from "../DirtyLink";
import { Icon } from "../Icon";
import { MenuButton } from "../Menu";
import { Modal } from "../Modal";
import Pagination from "../Pagination";
import ResizablePanel from "../ResizablePanel";
import { Select } from "../Select";
import Switch from "../Switch";
import { Table } from "../table/Table";
import { TableActionsBar } from "../TableActionsBar";
import TextField from "../TextField";
import { TimespanDropdown } from "../TimespanDropdown";
import Tree from "../Tree";

function UserActivitiesTab() {
  const {
    activities,
    isLoading,
    setFilters,
    filters,
    t,
    dateFormatter,
    total,
    showTooltip,
    hideTooltip,
    detailModalState,
    handleDetailClick,
    selectedActivity,
    appliedFiltersCount,
    handleResetAdvancedFilters,
    tableActions,
    notificationsToRead,
    treeItems,
    handleTreeItemToggle,
    resizablePanelWidth,
    setResizablePanelWidth,
    groupingCriteria,
    isLoadingTree,
    groupedTreeItemsTableFields,
    isLoadingTreeItem,
    groupedTotal,
    groupedFilters,
    setGroupedFilters,
    groupedActionModalState,
    handleGroupedItemMenuAction,
    selectedGroupedItem,
    handleGroupingCriteriaChange,
    handleGroupedModalActionConfirm,
    handleGroupedTableRowAction,
    currentOpenNode,
    isCurrentUserReadOnly,
  } = useUserActivitiesTab();

  return (
    <div className="flex h-full w-full flex-col overflow-x-hidden transition-all duration-200 ease-in-out">
      <TableActionsBar
        filtersCount={appliedFiltersCount}
        hasMoreFilters
        actions={tableActions}
        searchValue={filters.search}
        leftContent={
          <span className={clsx("pl-2", notificationsToRead === 0 && "hidden")}>
            {t("common.unreadUserActivitiesNotifications", {
              count: notificationsToRead,
            })}
            :
          </span>
        }
        isAdvancedSearchInitialOpen={appliedFiltersCount > 0}
        onSearchChange={(value) => {
          setFilters({ ...filters, offset: 0, search: value });
        }}
        onAdvancedFilterOpenChange={(isOpen) => {
          if (!isOpen) {
            handleResetAdvancedFilters();
          }
        }}
        additionalFilters={
          <div className="grid w-full grid-cols-1 gap-4 px-2 py-4 md:grid-cols-2 lg:grid-cols-4">
            <ComputerMultiSelect
              maxSelectableItems={5}
              id="computers"
              hasList={false}
              label={t("common.computers")}
              placeholder={t("common.computers")}
              includeGroups={false}
              selectedComputers={filters.computerID as Computer[]}
              onComputerChange={(e) => {
                setFilters({ ...filters, computerID: e, offset: 0 });
              }}
            />

            <UserMultiSelect
              maxSelectableItems={5}
              id="users"
              label={t("common.users")}
              placeholder={t("common.users")}
              hasList={false}
              selectedObjects={filters.userID as User[]}
              onChange={(e) => {
                setFilters({ ...filters, offset: 0, userID: e });
              }}
            />
            <PolicySeverityMultiselect
              id="severities"
              label={t("common.severity")}
              placeholder={t("common.severity")}
              selectedObjects={filters.severity as PolicySeverity[]}
              onChange={(e) => {
                setFilters({ ...filters, offset: 0, severity: e });
              }}
            />
            <UserActivityTypeMultiselect
              maxSelectableItems={5}
              id="activityType"
              selectedObjects={filters.activityType as UserActivityType[]}
              onChange={(e) => {
                setFilters({ ...filters, activityType: e, offset: 0 });
              }}
            />
          </div>
        }
        customFilters={[
          <Switch
            key="notRead"
            prependChildren
            aria-label={t("common.onlyNotRead")}
            id="notRead"
            name={t("common.onlyNotRead")}
            isSelected={filters.notRead}
            onChange={(e) => {
              setFilters({ ...filters, notRead: e, offset: 0 });
            }}
          >
            {t("common.onlyNotRead")}
          </Switch>,
          <TimespanDropdown
            key="timespan"
            initialOption={filters.timespanOption}
            initialCustomFrom={convertTicksToCalendarDateTime(
              filters.dateStart,
            )}
            initialCustomTo={convertTicksToCalendarDateTime(filters.dateEnd)}
            onChange={(from, to, option) => {
              setFilters({
                ...filters,
                dateEnd: convertDateToTicks(to),
                dateStart: convertDateToTicks(from),
                offset: 0,
                timespanOption: option,
              });
            }}
          />,
        ]}
      />
      <div className="flex flex-1 flex-col gap-4">
        <div className="flex flex-1 flex-row">
          <ResizablePanel
            className="flex flex-col gap-4 border-r border-gray"
            minWidth={50}
            maxWidth={500}
            onWidthChange={(width) => setResizablePanelWidth(width)}
            width={resizablePanelWidth}
          >
            <>
              <div className="flex h-[57px] flex-row items-center justify-center gap-4 border-b border-gray px-2">
                <div
                  className={clsx(
                    "w-full",
                    resizablePanelWidth < 150 && "hidden",
                  )}
                >
                  <Select
                    labelClassName="truncate flex-shrink"
                    selectedKey={groupingCriteria}
                    aria-label={t("userActivities.logGrouping")}
                    placeholder={t("userActivities.logGrouping")}
                    onSelectionChange={(value) => {
                      handleGroupingCriteriaChange(
                        value as UserActivitiesGroupingCriteriaEnum,
                      );
                    }}
                  >
                    {[
                      UserActivitiesGroupingCriteriaEnum.policy,
                      UserActivitiesGroupingCriteriaEnum.hiddenPolicy,
                    ].map((item) => (
                      <Item key={item} aria-label={String(item)}>
                        {t(
                          `userActivities.grouping${item.charAt(0).toUpperCase()}${item.slice(1)}`,
                        )}
                      </Item>
                    ))}
                  </Select>
                </div>
                <div className="flex flex-shrink-0 items-center justify-center">
                  <Button
                    noPadding
                    variant="text"
                    onPress={() => {
                      setResizablePanelWidth(
                        resizablePanelWidth < 200 ? 200 : 50,
                      );
                    }}
                  >
                    <Icon name="GroupByIcon" className="h-6 w-6" />
                  </Button>
                </div>
              </div>
              {groupingCriteria === UserActivitiesGroupingCriteriaEnum.none ? (
                <span
                  className={clsx(
                    "w-full px-1 text-center",
                    resizablePanelWidth < 100 && "hidden",
                  )}
                >
                  {t("userActivities.selectLogGrouping")}
                </span>
              ) : (
                <>
                  {resizablePanelWidth > 150 ? (
                    <Tree
                      items={treeItems}
                      isLoading={isLoadingTree}
                      onNodeClick={handleTreeItemToggle}
                    />
                  ) : (
                    <div className="flex w-full">
                      <div
                        className={clsx(
                          "m-auto flex h-6 w-6 items-center justify-center rounded-full bg-dark-blue font-medium text-white",
                          currentOpenNode.depth === -1 && "hidden",
                        )}
                      >
                        {currentOpenNode.depth + 1}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          </ResizablePanel>
          <div className="flex flex-1 flex-col gap-4 overflow-x-auto">
            {groupingCriteria === UserActivitiesGroupingCriteriaEnum.none ? (
              <>
                <Table
                  aria-labelledby={t("common.computerActivities")}
                  isLoading={isLoading}
                  aria-label={t("common.computerActivities")}
                  sortDescriptor={filters.sort}
                  onSortChange={(e) =>
                    setFilters({ ...filters, offset: 0, sort: e })
                  }
                >
                  <TableHeader>
                    <Column allowsResizing allowsSorting key="computer">
                      {t("common.computer")}
                    </Column>
                    <Column
                      allowsResizing
                      allowsSorting
                      maxWidth={20}
                      defaultWidth={20}
                      key="user"
                    >
                      {t("common.username")}
                    </Column>
                    <Column allowsResizing allowsSorting key="policyType">
                      {t("common.policyType")}
                    </Column>
                    <Column allowsResizing allowsSorting key="policy">
                      {t("common.policyName")}
                    </Column>
                    <Column allowsResizing allowsSorting key="severity">
                      {t("common.severity")}
                    </Column>
                    <Column allowsResizing allowsSorting key="type">
                      {t("common.activityType")}
                    </Column>
                    <Column allowsResizing allowsSorting key="createdOn">
                      {t("common.time")}
                    </Column>
                  </TableHeader>

                  <TableBody items={activities}>
                    {(item: UserActivity) => (
                      <Row>
                        {(columnKey) => {
                          switch (columnKey) {
                            case "computer":
                              return (
                                <Cell
                                  textValue={item?.computer?.fullname || "N/D"}
                                >
                                  <span
                                    className={clsx(
                                      "transition-all duration-200 ease-in-out",
                                      !item.read && "font-bold",
                                    )}
                                  >
                                    {item?.computer?.fullname || "N/D"}
                                  </span>
                                </Cell>
                              );
                            case "user":
                              return (
                                <Cell textValue={item?.user?.fullname || "N/D"}>
                                  <span
                                    className={clsx(
                                      "transition-all duration-200 ease-in-out",
                                      !item.read && "font-bold",
                                    )}
                                  >
                                    {item?.user?.fullname || "N/D"}
                                  </span>
                                </Cell>
                              );
                            case "policyType":
                              return (
                                <Cell
                                  textValue={
                                    findPolicyByType(item?.policy?.type)
                                      ?.name || "N/D"
                                  }
                                >
                                  <div className="flex flex-row items-center justify-start gap-2">
                                    <Icon
                                      name={
                                        findPolicyByType(item?.policy?.type)
                                          ?.icon || "PoliciesMenuIcon"
                                      }
                                      className="h-6 w-6 text-medium-pale-blue"
                                    />
                                    <span
                                      className={clsx(
                                        "transition-all duration-200 ease-in-out",
                                        !item.read && "font-bold",
                                      )}
                                    >
                                      {findPolicyByType(item?.policy?.type)
                                        ?.name || "N/D"}
                                    </span>
                                  </div>
                                </Cell>
                              );
                            case "policy":
                              return (
                                <Cell>
                                  <DirtyLink
                                    className={clsx(
                                      "text-dark-blue underline transition-all duration-200 ease-in-out",
                                      !item.read && "font-bold",
                                    )}
                                    to={`/policies/${item?.policy?.type}?id=${item?.policy?.id}`}
                                  >
                                    {item?.policy?.name || "N/D"}
                                  </DirtyLink>
                                </Cell>
                              );
                            case "severity":
                              return (
                                <Cell
                                  textValue={
                                    item?.policy?.severity
                                      ? findPolicySeverityByType(
                                          item.policy.severity,
                                        )?.name
                                      : "N/D"
                                  }
                                >
                                  <span
                                    className={clsx(
                                      "transition-all duration-200 ease-in-out",
                                      item?.policy?.severity
                                        ? findPolicySeverityByType(
                                            item.policy.severity,
                                          )?.color
                                        : "N/D",
                                      !item.read && "font-bold",
                                    )}
                                  >
                                    {item?.policy?.severity
                                      ? findPolicySeverityByType(
                                          item.policy.severity,
                                        )?.name
                                      : "N/D"}
                                  </span>
                                </Cell>
                              );
                            case "type":
                              return (
                                <Cell>
                                  <div
                                    onMouseEnter={(e) =>
                                      showTooltip(
                                        <pre className="max-w-[200px] grow-0 cursor-pointer overflow-scroll whitespace-pre-wrap rounded-md bg-extra-dark-gray p-2 text-white duration-200 ease-in-out hover:bg-dark-blue">
                                          {JSON.stringify(item.entity, null, 2)}
                                        </pre>,
                                        e,
                                        true,
                                      )
                                    }
                                    onMouseLeave={hideTooltip}
                                    className={clsx(
                                      "flex w-fit flex-row items-start justify-start transition-all duration-200 ease-in-out",
                                      !item.entity && "pointer-events-none",
                                      !item.read && "font-bold",
                                    )}
                                  >
                                    <Button
                                      onPress={() => {
                                        handleDetailClick(item);
                                      }}
                                      type="button"
                                      variant="text"
                                      noPadding
                                    >
                                      <span
                                        className={clsx(
                                          item.entity && "underline",
                                        )}
                                      >
                                        {findUserActivityByType(item?.type)
                                          ?.name || "N/D"}
                                      </span>
                                    </Button>
                                  </div>
                                </Cell>
                              );
                            case "createdOn":
                              return (
                                <Cell
                                  textValue={
                                    item.createdOn
                                      ? dateFormatter.format(
                                          new Date(item.createdOn),
                                        )
                                      : "N/D"
                                  }
                                >
                                  <span
                                    className={clsx(
                                      "transition-all duration-200 ease-in-out",
                                      !item.read && "font-bold",
                                    )}
                                  >
                                    {item.createdOn
                                      ? dateFormatter.format(
                                          new Date(item.createdOn),
                                        )
                                      : "N/D"}
                                  </span>
                                </Cell>
                              );
                            default:
                              return (
                                <Cell>
                                  {(item as any)[String(columnKey)] || "N/D"}
                                </Cell>
                              );
                          }
                        }}
                      </Row>
                    )}
                  </TableBody>
                </Table>
                <Pagination
                  offset={Number(filters.offset)}
                  total={total}
                  count={Number(filters.count)}
                  onCountChange={(e) =>
                    setFilters((prevState) => ({
                      ...prevState,
                      count: e,
                      offset: 0,
                    }))
                  }
                  onOffsetChange={(e) =>
                    setFilters((prevState) => ({ ...prevState, offset: e }))
                  }
                />
              </>
            ) : (
              <>
                {groupedTreeItemsTableFields?.fields?.length ? (
                  <>
                    <Table
                      aria-label={t("userActivities.logGrouping")}
                      isLoading={isLoadingTreeItem}
                      onRowAction={handleGroupedTableRowAction}
                      hasRowsClickable={currentOpenNode.depth !== 2}
                    >
                      <TableHeader>
                        {groupedTreeItemsTableFields.fields.map((field) => (
                          <Column allowsResizing key={field.column}>
                            {field.header}
                          </Column>
                        ))}
                      </TableHeader>

                      <TableBody items={groupedTreeItemsTableFields.items}>
                        {(item) => (
                          <Row>
                            {groupedTreeItemsTableFields.fields.map((field) => {
                              const pathParts = field.column.split(".");

                              let value = pathParts.reduce(
                                (acc, part) => acc && acc[part as any],
                                item?.data?.value,
                              );

                              if (field.column === "entity.filenameOnly") {
                                const entity =
                                  (item?.data?.value as any).entity || null;
                                if (
                                  entity &&
                                  typeof entity.filename === "string"
                                ) {
                                  const parts = entity.filename.split("\\");
                                  value = parts[parts.length - 1];
                                } else {
                                  value = "N/D";
                                }
                              }

                              if (field.column === "application") {
                                if (typeof value === "string" && value) {
                                  const parts = value?.split("\\");
                                  value = parts[parts.length - 1];
                                }
                              }

                              // Group actions by section if present
                              const groupedActions: Record<
                                string,
                                {
                                  key: string;
                                  label: string;
                                  section: string;
                                }[]
                              > = {};

                              field?.actions?.forEach(
                                (action: {
                                  key: any;
                                  section: any;
                                  label: string;
                                }) => {
                                  if (
                                    action.key === "hide" &&
                                    groupingCriteria ===
                                      UserActivitiesGroupingCriteriaEnum.hiddenPolicy
                                  ) {
                                    return;
                                  }

                                  if (
                                    action.key === "restore" &&
                                    groupingCriteria !==
                                      UserActivitiesGroupingCriteriaEnum.hiddenPolicy
                                  ) {
                                    return;
                                  }

                                  const itemValue = item?.data?.value as {
                                    applicationDetail?: {
                                      hash?: string;
                                      certificate?: string;
                                      systemFiles?: boolean;
                                    };
                                  };

                                  if (
                                    action.key === "authorize-hash" &&
                                    !itemValue?.applicationDetail?.hash
                                  ) {
                                    return;
                                  }

                                  if (
                                    action.key === "authorize-certificate" &&
                                    !itemValue?.applicationDetail?.certificate
                                  ) {
                                    return;
                                  }

                                  // Antivirus check action
                                  if (action.key === "antivirus-check") {
                                    if (
                                      !itemValue?.applicationDetail?.systemFiles
                                    ) {
                                      return;
                                    }
                                  }

                                  const section = action.section;
                                  if (!groupedActions[section]) {
                                    groupedActions[section] = [];
                                  }
                                  groupedActions[section].push(action);
                                },
                              );

                              let virusCheckedText: string;
                              if (field.column === "computer.fullname") {
                                switch (item?.data?.value?.virusChecked) {
                                  case UserActivityVirusCheckedEnum.WorkingProgress:
                                    virusCheckedText = t(
                                      "common.virusScanning",
                                    );
                                    break;
                                  case UserActivityVirusCheckedEnum.VirusFound:
                                    virusCheckedText = t("common.virusFound");
                                    break;
                                  default:
                                    virusCheckedText = "N/D";
                                    break;
                                }
                              }

                              switch (field.column) {
                                case "computer.fullname":
                                  return (
                                    <Cell key={field.column} textValue={value}>
                                      <div className="flex flex-row items-center justify-center gap-2">
                                        {[
                                          UserActivityVirusCheckedEnum.WorkingProgress,
                                          UserActivityVirusCheckedEnum.VirusFound,
                                        ].includes(
                                          item?.data?.value?.virusChecked,
                                        ) && (
                                          <div
                                            onMouseEnter={(e) => {
                                              e.preventDefault();
                                              showTooltip(
                                                virusCheckedText,
                                                e,
                                                true,
                                              );
                                            }}
                                            onMouseLeave={hideTooltip}
                                          >
                                            {item?.data?.value?.virusChecked ===
                                              UserActivityVirusCheckedEnum.WorkingProgress && (
                                              <Icon
                                                name="LensBugIcon"
                                                className="h-4 w-4 text-yellow"
                                              />
                                            )}
                                            {item?.data?.value?.virusChecked ===
                                              UserActivityVirusCheckedEnum.VirusFound && (
                                              <Icon
                                                name="BugIcon"
                                                className="h-4 w-4 text-dark-red"
                                              />
                                            )}
                                          </div>
                                        )}
                                        <span>{value}</span>
                                      </div>
                                    </Cell>
                                  );

                                case "createdOn":
                                  return (
                                    <Cell
                                      key={field.column}
                                      textValue={
                                        value !== undefined
                                          ? dateFormatter.format(
                                              new Date(value),
                                            )
                                          : "N/D"
                                      }
                                    >
                                      {value !== undefined
                                        ? dateFormatter.format(new Date(value))
                                        : "N/D"}
                                    </Cell>
                                  );

                                case "policy.severity":
                                  return (
                                    <Cell key={field.column} textValue={value}>
                                      <span
                                        className={clsx(
                                          "transition-all duration-200 ease-in-out",
                                          findPolicySeverityByType(
                                            value as unknown as PolicySeverityEnum,
                                          )?.color,
                                        )}
                                      >
                                        {
                                          findPolicySeverityByType(
                                            value as unknown as PolicySeverityEnum,
                                          )?.name
                                        }
                                      </span>
                                    </Cell>
                                  );

                                case "_actions":
                                  if (!item?.data?.hasActionsEnabled) {
                                    return <Cell> </Cell>;
                                  } else {
                                    return (
                                      <Cell>
                                        <MenuButton
                                          label={
                                            <span className="underline">
                                              {t("common.manage")}
                                            </span>
                                          }
                                          onAction={(key) => {
                                            handleGroupedItemMenuAction(
                                              item,
                                              key,
                                            );
                                          }}
                                        >
                                          {Object.values(groupedActions).map(
                                            (sectionActions, index) => (
                                              <Section key={index}>
                                                {sectionActions.map(
                                                  (action) => (
                                                    <Item key={action.key}>
                                                      {action.label}
                                                    </Item>
                                                  ),
                                                )}
                                              </Section>
                                            ),
                                          )}
                                        </MenuButton>
                                      </Cell>
                                    );
                                  }

                                default:
                                  return (
                                    <Cell key={field.column} textValue={value}>
                                      <span
                                        className={clsx(
                                          item?.data?.isNew && "font-black",
                                        )}
                                      >
                                        {value}
                                      </span>
                                    </Cell>
                                  );
                              }
                            })}
                          </Row>
                        )}
                      </TableBody>
                    </Table>
                    {groupedTotal > 0 && (
                      <Pagination
                        offset={Number(groupedFilters.offset)}
                        total={groupedTotal}
                        count={Number(groupedFilters.count)}
                        onCountChange={(e) =>
                          setGroupedFilters((prevState) => ({
                            ...prevState,
                            count: e,
                            offset: 0,
                          }))
                        }
                        onOffsetChange={(e) =>
                          setGroupedFilters((prevState) => ({
                            ...prevState,
                            offset: e,
                          }))
                        }
                      />
                    )}
                  </>
                ) : (
                  <div className="flex flex-1 items-center justify-center">
                    <span className="text-dark-blue">
                      {t("userActivities.selectTreeItem")}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        state={detailModalState}
        className="min-w-[50vh] max-w-[80vh] transition-all duration-200 ease-in-out"
      >
        <div className="flex w-full flex-1 grow flex-col gap-2">
          <h3 className="pb-2 text-xl text-dark-blue">
            {t("common.activityDescription")}
          </h3>
          <pre className="max-w-[80vh] grow-0 cursor-pointer overflow-scroll whitespace-pre-wrap rounded-md bg-extra-dark-gray p-2 text-white duration-200 ease-in-out">
            {JSON.stringify(selectedActivity?.entity, null, 2)}
          </pre>
        </div>
      </Modal>

      <Modal
        state={groupedActionModalState}
        className="min-w-[40vh] max-w-[60vh] transition-all duration-200 ease-in-out"
      >
        <div className="flex w-full flex-1 grow flex-col gap-2">
          <div className="flex flex-row items-center gap-4 pb-2 text-dark-blue">
            {((): React.ReactNode => {
              switch (selectedGroupedItem?.key) {
                case "authorize-certificate":
                  return <Icon name="CertificateIcon" className="h-6 w-6" />;
                case "authorize-hash":
                  return <Icon name="BarcodeBarIcon" className="h-6 w-6" />;
                case "antivirus-check":
                  return <Icon name="LensBugIcon" className="h-6 w-6" />;
                case "hide":
                  return <Icon name="DeniedIcon" className="h-6 w-6" />;
                case "restore":
                  return <Icon name="DeniedIcon" className="h-6 w-6" />;
                default:
                  return "";
              }
            })()}
            <h3 className="text-xl">
              {((): string => {
                switch (selectedGroupedItem?.key) {
                  case "authorize-certificate":
                    return t("common.authorizeCertificate");
                  case "authorize-hash":
                    return t("common.authorizeHash");
                  case "antivirus-check":
                    return t("common.antivirusCheck");
                  case "hide":
                    return t("common.hideFromList");
                  case "restore":
                    return t("common.restoreFromList");
                  default:
                    return "N/D";
                }
              })()}
            </h3>
          </div>
          <div className="pb-2">
            <Trans
              components={{
                li: <li />,
                ul: (
                  <ul className="list-inside list-disc pb-4 pt-2 leading-loose" />
                ),
              }}
            >
              {((): string => {
                switch (selectedGroupedItem?.key) {
                  case "authorize-certificate":
                    return t("common.authorizeCertificateDescription");
                  case "authorize-hash":
                    return t("common.authorizeHashDescription");
                  case "antivirus-check":
                    return t("common.antivirusCheckDescription", {
                      resource:
                        selectedGroupedItem?.element?.data?.value?.application,
                    });
                  case "hide":
                    return t("common.hideFromListDescription");
                  case "restore":
                    return t("common.restoreFromListDescription");
                  default:
                    return "N/D";
                }
              })()}
            </Trans>
          </div>
          {/*<pre>{JSON.stringify(selectedGroupedItem, null, 2)}</pre>*/}
          {selectedGroupedItem?.key !== "antivirus-check" && (
            <div className="flex w-full flex-col gap-4 pt-4">
              <TextField
                value={
                  findUserActivityByType(
                    selectedGroupedItem?.element?.data?.userActivityType,
                  )?.policy?.name || "N/D"
                }
                aria-label="Type of policy"
                label="Type of policy"
                isReadOnly
              />

              <TextField
                value={selectedGroupedItem?.element?.data?.parentTitle || "N/D"}
                label="Policy name"
                aria-label="Policy name"
                isReadOnly
              />
              <div className="col-span-2 border-b border-gray" />
              {((): ReactNode => {
                return (
                  <TextField
                    value={
                      selectedGroupedItem?.element?.data?.value?.application ||
                      "N/D"
                    }
                    aria-label="Application name"
                    label="Application name"
                    isReadOnly
                  />
                );
              })()}
              <TextField
                value={
                  selectedGroupedItem?.element?.data?.value?.applicationPath ||
                  "N/D"
                }
                aria-label="Application path"
                label="Application path"
                isReadOnly
              />
              {["hide", "authorize-hash"].includes(selectedGroupedItem?.key) &&
                selectedGroupedItem?.element?.data?.value?.applicationDetail
                  ?.hash && (
                  <TextField
                    value={
                      selectedGroupedItem?.element?.data?.value
                        ?.applicationDetail?.hash || "N/D"
                    }
                    aria-label="Hash"
                    label="Hash"
                    isReadOnly
                  />
                )}
              {["hide", "authorize-certificate"].includes(
                selectedGroupedItem?.key,
              ) &&
                selectedGroupedItem?.element?.data?.value?.applicationDetail
                  ?.certificate?.subject && (
                  <TextField
                    value={
                      selectedGroupedItem?.element?.data?.value
                        ?.applicationDetail?.certificate?.subject || "N/D"
                    }
                    aria-label="Certificate"
                    label="Certificate"
                    isReadOnly
                  />
                )}
            </div>
          )}
          <div className="grid w-full grid-cols-3 gap-4 pt-8">
            <div />
            <Button variant="text" onPress={groupedActionModalState.close}>
              {t("common.cancel")}
            </Button>
            <Button
              autoFocus
              variant="contained"
              isLoading={isLoadingTree}
              isDisabled={isCurrentUserReadOnly}
              onPress={() => {
                handleGroupedModalActionConfirm(
                  selectedGroupedItem.element,
                  selectedGroupedItem.key,
                );
              }}
            >
              {t("common.next")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default UserActivitiesTab;
