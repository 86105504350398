import React, { Component, ReactNode } from "react";

import ServerIllustration from "../assets/images/server-illustration.svg";
import { translate } from "../utils/translation";

import Button from "./Button";

interface ErrorBoundaryState {
  hasError: boolean;
  errorInfo: React.ErrorInfo | null;
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { errorInfo: null, hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    console.error(error);
    return { errorInfo: null, hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    /*const formattedErrorInfo =
      "\\#Remotegrant webapp error  " +
      "```\n" +
      JSON.stringify(errorInfo, null, 2) +
      "\n```";

    fetch(
      `https://api.telegram.org/${import.meta.env.VITE_APP_TELEGRAM_BOT_ID}/sendMessage?chat_id=${import.meta.env.VITE_APP_TELEGRAM_CHAT_ID}&text=${encodeURIComponent(formattedErrorInfo)}&message_thread_id=${import.meta.env.VITE_APP_TELEGRAM_MESSAGE_THREAD_ID}&disable_notification=true&parse_mode=MarkdownV2`,
    ).then((r) => console.error(errorInfo));*/
    this.setState({ errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex h-full w-full flex-col items-center justify-center gap-8 rounded-md bg-white text-center md:gap-10">
          <ServerIllustration className="w-full max-w-lg" />
          <h1>{translate("errorBoundary.title")}</h1>
          <p className="max-w-lg leading-8">
            {translate("errorBoundary.description")}
            <br />
            {translate("errorBoundary.detail")}
          </p>
          <div>
            <Button
              onPress={() => {
                window.location.reload();
              }}
            >
              {translate("errorBoundary.button")}
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
